import { colorPalette, yellow } from '@/chartTheme';
import { AppSearch } from '@/components/AppSearch';
import { AGE_BRACKET, CHILD, DEVICE, GENDER, HOME_INCOME, JOB, MARRIAGE, PREFECTURE } from '@/constants';
import { parseYm } from '@/utils/parseYm';
import { CloseCircleFilled, FilterOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import React from 'react';
import { useExtractParam } from '../Context';

const { RangePicker } = DatePicker;
const { Option } = Select;

function convDateRange(dateRange?: [moment.Moment, moment.Moment]) {
    if (!dateRange) {
        return undefined;
    }

    const [start, end] = dateRange;
    return {
        start: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD')
    };
}

export const Filter: React.FunctionComponent<{
    cluster?: boolean;
}> = ({ cluster = true }) => {
    const [visible, setVisible] = React.useState(false);
    const form = React.useRef<FormInstance>(null);
    const {
        // extractid,
        extract: { startYm, endYm },
        filter,
        setFilter,
        clusterid,
        setClusterid,
        users,
        clusters
    } = useExtractParam();

    React.useEffect(() => {
        if (!visible || !filter) {
            return;
        }

        const {
            dateRange,
            device,
            gender,
            ageBracket,
            marriage,
            child,
            prefecture,
            homeIncome,
            job,
            keyword,
            title,
            url,
            app,
            siteCategory,
            appCategory
        } = filter;

        form.current?.setFieldsValue({
            dateRange: dateRange ? [moment(dateRange.start), moment(dateRange.end)] : undefined,
            device,
            gender,
            ageBracket,
            marriage,
            child,
            prefecture,
            homeIncome,
            job,
            keyword,
            title,
            url,
            app,
            siteCategory,
            appCategory
        });
    }, [visible, filter]);

    const disabledDate = React.useCallback(
        (date: moment.Moment) => {
            const start = parseYm(startYm);
            const end = parseYm(endYm);
            return date < start || date >= end.clone().add(1, 'months');
        },
        [startYm, endYm]
    );

    return (
        <>
            <Button
                onClick={() => {
                    setVisible(true);
                }}
                shape="round"
                type={filter ? 'primary' : 'default'}
            >
                <FilterOutlined />
                <span style={{ marginLeft: 8 }}>絞り込み</span>
                <span style={{ marginLeft: 8, fontSize: 10 }}>({users?.length ?? '-'}人)</span>
                {filter && (
                    <CloseCircleFilled
                        onClick={(e) => {
                            e.stopPropagation();
                            form.current?.resetFields();
                            setFilter(null);
                        }}
                    />
                )}
            </Button>
            {cluster && (
                <Select
                    loading={clusters == null}
                    style={{
                        marginLeft: 12,
                        width: 360
                    }}
                    value={clusterid}
                    onChange={(value) => {
                        setClusterid(value || null);
                    }}
                    allowClear
                >
                    <Option value={null}>クラスタ指定なし</Option>
                    {clusters?.map(({ clusterid, name, users }, i) => {
                        return (
                            <Option key={clusterid} value={clusterid}>
                                <span style={{ color: colorPalette[i] }}>
                                    {name}({users})
                                </span>
                            </Option>
                        );
                    })}
                </Select>
            )}
            <Modal
                title="絞り込み"
                width={800}
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                }}
                footer={
                    <div>
                        <Button
                            onClick={() => {
                                setVisible(false);
                            }}
                        >
                            キャンセル
                        </Button>
                        <Button
                            type="primary"
                            style={{ background: yellow, borderColor: yellow }}
                            onClick={() => {
                                form.current?.resetFields();
                                setFilter(null);
                                setVisible(false);
                            }}
                        >
                            リセット
                        </Button>
                        <Button
                            type="primary"
                            onClick={async () => {
                                const {
                                    dateRange,
                                    device,
                                    gender,
                                    ageBracket,
                                    marriage,
                                    child,
                                    prefecture,
                                    homeIncome,
                                    job,
                                    keyword,
                                    title,
                                    url,
                                    app,
                                    siteCategory,
                                    appCategory
                                } = await form.current?.validateFields();

                                const filter: any = {};
                                if (dateRange) {
                                    filter.dateRange = convDateRange(dateRange);
                                }
                                if (device && device.length > 0) {
                                    filter.device = device;
                                }
                                if (gender && gender.length > 0) {
                                    filter.gender = gender;
                                }
                                if (ageBracket && ageBracket.length > 0) {
                                    filter.ageBracket = ageBracket;
                                }
                                if (marriage && marriage.length > 0) {
                                    filter.marriage = marriage;
                                }
                                if (child && child.length > 0) {
                                    filter.child = child;
                                }
                                if (prefecture && prefecture.length > 0) {
                                    filter.prefecture = prefecture;
                                }
                                if (homeIncome && homeIncome.length > 0) {
                                    filter.homeIncome = homeIncome;
                                }
                                if (job && job.length > 0) {
                                    filter.job = job;
                                }
                                if (keyword) {
                                    filter.keyword = keyword;
                                }
                                if (title) {
                                    filter.title = title;
                                }
                                if (url) {
                                    filter.url = url;
                                }
                                if (app) {
                                    filter.app = app;
                                }

                                if (siteCategory) {
                                    filter.siteCategory = siteCategory;
                                }
                                if (appCategory) {
                                    filter.appCategory = appCategory;
                                }

                                if (Object.keys(filter).length > 0) {
                                    setFilter(filter);
                                } else {
                                    setFilter(null);
                                }

                                setVisible(false);
                            }}
                        >
                            絞り込む
                        </Button>
                    </div>
                }
            >
                <Form ref={form} layout="vertical" autoComplete="off">
                    <Form.Item label="期間" name="dateRange">
                        <RangePicker
                            separator="-"
                            // defaultPickerValue={[parseYm(startYm), parseYm(startYm)]}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                    <Form.Item label="デバイス" name="device">
                        <Checkbox.Group>
                            {Object.keys(DEVICE).map((n) => {
                                return (
                                    <Checkbox key={n} value={n}>
                                        {DEVICE[n]}
                                    </Checkbox>
                                );
                            })}
                        </Checkbox.Group>
                    </Form.Item>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="性別" name="gender">
                                <Checkbox.Group>
                                    {Object.keys(GENDER).map((n) => {
                                        return (
                                            <Checkbox key={n} value={n}>
                                                {GENDER[n]}
                                            </Checkbox>
                                        );
                                    })}
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item label="年代" name="ageBracket">
                                <Checkbox.Group>
                                    {Object.keys(AGE_BRACKET).map((n) => {
                                        return (
                                            <Checkbox key={n} value={n}>
                                                {AGE_BRACKET[n]}
                                            </Checkbox>
                                        );
                                    })}
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Form.Item label="未既婚" name="marriage">
                                <Checkbox.Group>
                                    {Object.keys(MARRIAGE).map((n) => {
                                        return (
                                            <Checkbox key={n} value={n}>
                                                {MARRIAGE[n]}
                                            </Checkbox>
                                        );
                                    })}
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item label="子供有無" name="child">
                                <Checkbox.Group>
                                    {Object.keys(CHILD).map((n) => {
                                        return (
                                            <Checkbox key={n} value={n}>
                                                {CHILD[n]}
                                            </Checkbox>
                                        );
                                    })}
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="居住都道府県" name="prefecture">
                        <Checkbox.Group>
                            {Object.keys(PREFECTURE).map((n) => {
                                return (
                                    <Checkbox key={n} value={n}>
                                        {PREFECTURE[n]}
                                    </Checkbox>
                                );
                            })}
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item label="世帯年収" name="homeIncome">
                        <Checkbox.Group>
                            {Object.keys(HOME_INCOME).map((n) => {
                                return (
                                    <Checkbox key={n} value={n}>
                                        {HOME_INCOME[n]}
                                    </Checkbox>
                                );
                            })}
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item label="職業" name="job">
                        <Checkbox.Group>
                            {Object.keys(JOB).map((n) => {
                                return (
                                    <Checkbox key={n} value={n}>
                                        {JOB[n]}
                                    </Checkbox>
                                );
                            })}
                        </Checkbox.Group>
                    </Form.Item>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item label="検索キーワード" name="keyword">
                                <Input allowClear placeholder="検索キーワード" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="ページタイトル" name="title">
                                <Input allowClear placeholder="ページタイトル" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="URL" name="url">
                                <Input allowClear placeholder="URL" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="アプリ" name="app">
                                <AppSearch />
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item label="サイトカテゴリ" name="siteCategory">
                                <SiteCategorySelect />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="アプリカテゴリ" name="appCategory">
                                <AppCategorySelect />
                            </Form.Item>
                        </Col> */}
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
