export { }

declare global {
    interface String {
        toNumber: () => number
    }

    interface Number {
        toFixedNumber: (digits: number) => number
        toPercent: () => number
        round: (base: number) => number
        roundSigDig: (sigDig?: number, leastDig?: number) => number
        toRate: (denominator: number | undefined) => number
        nan2undefined: () => number | undefined
        neg2undefined: () => number | undefined
    }
}

String.prototype.toNumber = function (): number {
    return Number(this);
}

Number.prototype.toFixedNumber = function (digits: number): number {
    if (isNaN(this as number)) {
        return NaN;
    }
    return Number(this.toFixed(digits));
}

Number.prototype.toPercent = function (): number {
    if (isNaN(this as number)) {
        return NaN;
    }
    return this as number * 100;
}

Number.prototype.round = function (base: number): number {
    if (isNaN(this as number)) {
        return NaN;
    }
    return Math.round(this as number / base) * base;
}

Number.prototype.roundSigDig = function (sigDig = 3, leastDig = 2) {
    if (isNaN(this as number)) {
        return NaN;
    }

    const str = this.toFixed();
    const len = str.length;
    let digits = 1;
    if (len > sigDig) {
        digits = len - sigDig;
    } else if (len <= sigDig) {
        digits = 0
    }

    if (digits < leastDig) {
        digits = leastDig;
    }

    return this.round(10 ** digits);
}

Number.prototype.toRate = function (denominator) {
    if (denominator === undefined) {
        return NaN;
    }

    if (denominator === 0) {
        return NaN;
    }

    return this as number / denominator;
}

Number.prototype.nan2undefined = function () {
    if (isNaN(this as number)) {
        return undefined;
    }

    return this as number;
}

Number.prototype.neg2undefined = function () {
    if (this < 0) {
        return undefined;
    }

    return this as number;
}