export const BODY_TROUBLE: {
    [s: string]: string;
} = {
    1: '肥満・メタボ',
    2: '体のゆがみ',
    3: '関節痛',
    4: '肩こり',
    5: '腰痛',
    6: '頭痛',
    7: '貧血',
    8: '食欲不振',
    9: '飲酒量',
    10: '喫煙量',
    11: '睡眠不足',
    12: '更年期障害',
    13: '便秘',
    14: 'むくみ',
    15: '冷え症',
    16: '目の疲れ',
    17: '耳鳴り',
    18: '動悸・息切れ',
    19: '肌荒れ',
    20: '肌のシミ・しわ',
    21: '日焼け（紫外線、UV）',
    22: '乾燥肌',
    23: '脂性肌',
    24: '敏感肌',
    25: 'アトピー',
    26: '髪のダメージ',
    27: '薄毛・抜け毛',
    28: 'フケ・頭皮のかゆみ',
    29: '体臭',
    30: '口臭',
    31: 'アレルギー',
    32: '胃痛・胸やけ',
    33: '肺の痛み・呼吸困難・喘息',
    34: '水虫',
    35: '花粉症',
    36: '生理痛',
    37: '高血圧',
    38: '慢性疲労',
    39: '血糖値',
    40: '歯の悩み（虫歯、歯周病など）',
};