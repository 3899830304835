import { colorPalette } from '@/chartTheme';
import { useAuth } from '@/contexts/Auth';
import {
    useClusterSitesQuery,
    useClustersQuery,
    useClusterStateQuery,
    useClusterWordsQuery,
    useExecuteClusterMutation,
    useStopwordsQuery,
    useUpdateClusterNameMutation,
    useUpdateStopwordsMutation
} from '@/graphql/generated';
import { Alert, Button, Card, Col, Drawer, Input, InputNumber, message, Modal, Radio, Row, Spin } from 'antd';
import React from 'react';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';
import { SiteCard } from './SiteCard';
import { WordCloudCard } from './WordCloudCard';

const ClusterCard: React.FC<{
    index: number;
    clusterid: number;
    name: string;
    users?: number;
    loading?: boolean;
    children: React.ReactNode;
}> = ({ index, clusterid, name, users, loading, children }) => {
    const { extractid } = useExtractParam();
    const [visible, setVisible] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string>(name);
    const [editValue, setEditValue] = React.useState<string>('');
    const [updateClusterName, updateClusterResult] = useUpdateClusterNameMutation({
        onCompleted() {
            message.success('保存しました。');
        },
        onError() {
            message.error('保存に失敗しました。');
        }
    });

    return (
        <>
            <Modal
                title="クラスタ名変更"
                centered
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                }}
                okText="保存"
                onOk={() => {
                    updateClusterName({
                        variables: {
                            extractid,
                            clusterid,
                            name: editValue
                        }
                    });

                    setValue(editValue);
                    setVisible(false);
                }}
            >
                <Input
                    maxLength={100}
                    value={editValue}
                    onChange={(e) => {
                        setEditValue(e.target.value);
                    }}
                />
            </Modal>
            <Card
                size="small"
                headStyle={{
                    borderTop: `2px solid ${colorPalette[index]}`
                }}
                loading={loading}
                title={`${value}(${users})`}
                extra={
                    <Button
                        onClick={() => {
                            setVisible(true);
                            setEditValue(value);
                        }}
                        size="small"
                    >
                        変更
                    </Button>
                }
            >
                {children}
            </Card>
        </>
    );
};

const ClusterWords: React.FC<{
    stopwords: string[];
    toggleStopword: (word: string) => void;
}> = ({ stopwords, toggleStopword }) => {
    const { extractid } = useExtractParam();
    const { data, loading, error } = useClusterWordsQuery({
        variables: {
            extractid
        }
    });

    if (loading) {
        return (
            <div style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
                <Spin />
            </div>
        );
    }

    return (
        <div className="slim-scroll" style={{ overflowX: 'hidden' }}>
            <Row gutter={12}>
                {data?.extract?.cluster?.clusters.map(({ name, clusterid, words, users }, i) => {
                    return (
                        <Col key={i} span={12} style={{ marginBottom: 12 }}>
                            <ClusterCard clusterid={clusterid} index={i} loading={loading} name={name} users={users}>
                                <WordCloudCard
                                    stopwords={stopwords}
                                    toggleStopword={toggleStopword}
                                    title={name}
                                    words={words}
                                    color={colorPalette[i]}
                                />
                            </ClusterCard>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

const ClusterSites: React.FC<{}> = () => {
    const { extractid } = useExtractParam();
    const { data, loading, error } = useClusterSitesQuery({
        variables: {
            extractid
        }
    });

    if (loading) {
        return (
            <div style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
                <Spin />
            </div>
        );
    }

    return (
        <div className="slim-scroll" style={{ overflowX: 'hidden' }}>
            <Row gutter={12}>
                {data?.extract?.cluster?.clusters.map(({ name, clusterid, users, sites }, i) => {
                    return (
                        <Col key={i} span={12} style={{ marginBottom: 12 }}>
                            <ClusterCard clusterid={clusterid} index={i} loading={loading} name={name} users={users}>
                                <SiteCard title={name} sites={sites} color={colorPalette[i]} />
                            </ClusterCard>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

const options = [
    { label: '検索キーワード', value: 'word' },
    { label: '閲覧サイト', value: 'site' }
];

const ClusterContent: React.FunctionComponent<{}> = () => {
    const auth = useAuth();

    const { extractid, extract, refetch, users, fetchClusters } = useExtractParam();
    const [drawer, setDrawer] = React.useState<boolean>(false);
    const [stopwords, setStopwords] = React.useState<string[]>();
    const [clusterNum, setClusterNum] = React.useState<number>(6);
    const [type, setType] = React.useState<'word' | 'site'>('word');

    useStopwordsQuery({
        variables: { extractid },
        onCompleted(data) {
            setStopwords(data.extract?.cluster.stopwords || []);
        }
    });

    const [updateStopwords] = useUpdateStopwordsMutation();

    React.useEffect(() => {
        if (!stopwords) {
            return;
        }

        updateStopwords({
            variables: {
                extractid,
                stopwords
            }
        });
    }, [extractid, stopwords, updateStopwords]);

    useClustersQuery({
        variables: { extractid },
        onCompleted(data) {
            setClusterNum(data?.extract?.cluster.clusters.length || 6);
        }
    });

    const [execute] = useExecuteClusterMutation();

    const {
        loading: clusterStateLoading,
        data: clusterStateData,
        refetch: clusterStateRefetch,
        stopPolling: clusterStateStopPolling,
        startPolling: clusterStateStartPolling
    } = useClusterStateQuery({
        fetchPolicy: 'no-cache',
        variables: {
            extractid
        },
        notifyOnNetworkStatusChange: true
    });

    React.useEffect(() => {
        clusterStateStartPolling(10000);
    }, [clusterStateStartPolling]);

    React.useEffect(() => {
        if (!clusterStateData) {
            return;
        }

        if (['PENDING', 'PROCESSING'].includes(clusterStateData.extract?.cluster?.state || '')) {
            clusterStateStartPolling(10000);
        } else {
            clusterStateStopPolling();
            refetch();
            fetchClusters();
        }
    }, [refetch, clusterStateData, clusterStateStopPolling, clusterStateStartPolling, fetchClusters]);

    const toggleStopword = React.useCallback(
        (word: string) => {
            if (!stopwords) {
                return;
            }

            if (auth.userid !== extract.userid) {
                return;
            }

            const index = stopwords.indexOf(word);
            if (index > -1) {
                const words = [...stopwords];
                words.splice(index, 1);
                setStopwords(words);
            } else {
                setStopwords([...stopwords, word]);
            }
        },
        [stopwords, auth, extract]
    );

    const state = clusterStateData?.extract?.cluster?.state;

    if (clusterStateLoading || !state) {
        return (
            <div style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
                <Spin />
            </div>
        );
    }

    if (['PENDING', 'PROCESSING'].includes(state)) {
        return (
            <div style={{ width: '100%', margin: 'auto', textAlign: 'center' }}>
                <Spin />
            </div>
        );
    }

    return (
        <div style={{ width: '100%', padding: 12, display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', marginBottom: 12 }}>
                <Filter cluster={false} />
                <Button
                    style={{ marginLeft: 12 }}
                    onClick={() => {
                        Modal.confirm({
                            centered: true,
                            content: `${users?.length || 0}人を対象にクラスタリングを実行します。`,
                            onOk: async () => {
                                clusterStateStopPolling();
                                execute({
                                    variables: {
                                        extractid,
                                        clusterNum,
                                        userids: users
                                    }
                                }).then(() => {
                                    clusterStateRefetch();
                                    clusterStateStartPolling(10000);
                                });
                            }
                        });
                    }}
                    disabled={auth.userid !== extract.userid || users == null || users.length < 100}
                    type="primary"
                    color="success"
                    shape="round"
                >
                    クラスタリング実行
                </Button>

                <span style={{ margin: 'auto 0 auto 12px' }}>
                    クラスタ数:
                    <InputNumber
                        min={4}
                        max={10}
                        style={{ marginLeft: 4 }}
                        value={clusterNum}
                        onChange={(value) => {
                            setClusterNum(value as number);
                        }}
                    />
                </span>
                <Button
                    style={{ marginLeft: 12 }}
                    onClick={() => {
                        setDrawer(true);
                    }}
                >
                    除外ワード({stopwords?.length})
                </Button>
                <div style={{ flex: 1 }} />
                <Radio.Group
                    options={options}
                    onChange={(e) => {
                        setType(e.target.value as 'word' | 'site');
                    }}
                    value={type}
                    optionType="button"
                />
            </div>
            <Drawer
                size="default"
                title="除外ワード"
                placement="right"
                closable={false}
                onClose={() => {
                    setDrawer(false);
                }}
                visible={drawer}
                width={300}
            >
                {stopwords?.map((word, index) => {
                    return (
                        <div key={index} style={{ display: 'flex', fontSize: 12, margin: '4px 0' }}>
                            <div style={{ flex: 1, margin: 'auto 0' }}>{word}</div>
                            <Button
                                danger
                                size="small"
                                type="link"
                                disabled={auth.userid !== extract.userid}
                                onClick={() => {
                                    toggleStopword(word);
                                }}
                            >
                                削除
                            </Button>
                        </div>
                    );
                })}
            </Drawer>
            <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                {type === 'word' ? (
                    <ClusterWords stopwords={stopwords || []} toggleStopword={toggleStopword} />
                ) : (
                    <ClusterSites />
                )}
            </div>
        </div>
    );
};

export const ClusterPage: React.FunctionComponent<{}> = () => {
    const {
        extract: { userCount }
    } = useExtractParam();

    return (
        <div className="slim-scroll">
            <div style={{ display: 'flex', width: '100%', height: '100%' }}>
                {userCount < 100 ? (
                    <div style={{ width: '100%', padding: 12 }}>
                        <Alert type="info" message="※ 対象人数に満たないため、クラスタリング機能が使えません。" />
                    </div>
                ) : (
                    <ClusterContent />
                )}
            </div>
        </div>
    );
};

export default ClusterPage;
