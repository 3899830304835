// import dockpitLogo from '@/assets/dockpit-logo.png';
import dockpitLogoMini from '@/assets/dockpit-logo-mini.png';
import logo from '@/assets/logo.png';
import { useAuth } from '@/contexts/Auth';
import { bgColor, layoutHeaderBackground, textColorSecondary } from '@/theme';
import { HomeOutlined, QuestionCircleFilled, ReloadOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Layout, Popover } from 'antd';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from './index.module.less';

const { Header } = Layout;

const CONTRACT_TYPE: { [s: string]: string } = {
    fixed: '定額制',
    ticket: 'チケット制'
};

const BasicLayout: React.FunctionComponent<{
    children: React.ReactNode;
}> = ({ children }) => {
    const history = useHistory();
    const auth = useAuth();

    const hasDockpit = React.useMemo(() => {
        return auth.contract_exservice.some((ce) => ce.exservice === 'dockpit');
    }, [auth.contract_exservice]);

    return (
        <Layout style={{ height: '100vh', overflow: 'hidden' }}>
            <Header style={{ display: 'flex' }}>
                <Link to="/">
                    <img alt="story bank" src={logo} className={styles.logo} />
                </Link>
                <div style={{ width: 20 }}></div>
                <div
                    className={styles.headerMenu}
                    onClick={() => {
                        history.push('/');
                    }}
                >
                    {/* <img src={dockpitLogo} alt="dockpit" style={{ height: 24, fontSize: 20, margin: 'auto 0' }} /> */}
                    <HomeOutlined style={{ fontSize: 20, margin: 'auto 0' }} />
                    <span style={{ margin: 'auto 0 auto 8px', lineHeight: 1 }}>ホーム</span>
                </div>
                <div
                    className={styles.headerMenu}
                    onClick={() => {
                        window.open(
                            hasDockpit ? 'https://data.dockpit.net' : 'https://www.valuesccg.com/dockpit/',
                            '_blank'
                        );
                    }}
                >
                    <img src={dockpitLogoMini} alt="dockpit" style={{ height: 20, margin: 'auto 0' }} />
                    <span style={{ margin: 'auto 0 auto 8px', lineHeight: 1 }}>Dockpit</span>
                </div>
                <div style={{ flex: 1 }} />
                <Popover
                    placement="bottomRight"
                    content={
                        <div style={{ width: 300 }}>
                            <div style={{ fontWeight: 900 }}>{auth.companyname}</div>
                            <div>{auth.username}</div>
                            <div style={{ textAlign: 'right' }}>
                                <ReloadOutlined
                                    onClick={() => {
                                        auth.fetchUserInfo();
                                    }}
                                />
                            </div>
                            <div style={{ padding: 4, color: textColorSecondary }}>
                                <div style={{ display: 'flex' }}>
                                    期間:
                                    <span style={{ flex: 1, overflow: 'hidden' }} />
                                    <span style={{ fontWeight: 900 }}>
                                        {auth.menu?.termStart} - {auth.menu?.termEnd}
                                    </span>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    契約:
                                    <span style={{ flex: 1, overflow: 'hidden' }} />
                                    <span style={{ fontWeight: 900 }}>
                                        {CONTRACT_TYPE[auth.menu?.contractType || '']}
                                    </span>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    回数:
                                    <span style={{ flex: 1, overflow: 'hidden' }} />
                                    <span style={{ fontWeight: 900 }}>
                                        {auth.menu?.count} / {auth.menu?.maxCount}
                                    </span>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    保存上限件数:
                                    <span style={{ flex: 1, overflow: 'hidden' }} />
                                    <span style={{ fontWeight: 900 }}>{auth.menu?.maxExtractSaves}</span>
                                </div>
                            </div>
                            <Button
                                onClick={() => {
                                    window.location.href = '/auth/logout';
                                }}
                                type="link"
                                block
                            >
                                ログアウト
                            </Button>
                        </div>
                    }
                    trigger="click"
                >
                    <div className={styles.account}>
                        <div
                            style={{
                                margin: 'auto 8px auto 0',
                                display: 'flex',
                                border: '2px solid #fff',
                                borderRadius: '50%',
                                width: 20,
                                height: 20,
                                fontSize: 12,
                                background: '#fff',
                                textAlign: 'center'
                            }}
                        >
                            <UserOutlined style={{ margin: 'auto', color: layoutHeaderBackground }} />
                        </div>
                        <span style={{ margin: 'auto 0', lineHeight: 1, color: '#fff' }}>{auth.username}</span>
                    </div>
                </Popover>
                <div
                    className={styles.headerMenu}
                    onClick={() => {
                        window.open(
                            `${process.env.REACT_APP_ACCOUNT_BASE_URL}/accountapi/auth/try_to_get_token?exservice=sbsupport`,
                            '_blank'
                        );
                    }}
                >
                    <QuestionCircleFilled style={{ fontSize: 20, margin: 'auto 8px auto 0' }} />
                    <span style={{ margin: 'auto 0', lineHeight: 1 }}>サポートサイト</span>
                </div>
            </Header>
            <Layout style={{ background: bgColor }}>{children}</Layout>
        </Layout>
    );
};

export default BasicLayout;
