import { cyan, geekblue, greenPalette, red } from '@/chartTheme';
import { RaccoonChart } from '@/components/RaccoonChart';
import { AGE_BRACKET, GENDER } from '@/constants';
import { useGetAgeQuery, useGetGenderQuery, useTrendQuery, useUserCountQuery } from '@/graphql/generated';
import parseYm from '@/server/utils/parseYm';
import { textColorSecondary } from '@/theme';
import { makeYms } from '@/utils/parseYm';
import { LaptopOutlined, MobileOutlined, StarFilled } from '@ant-design/icons';
import { Alert, Card, Divider, Statistic } from 'antd';
import React from 'react';
import { useExtractParam } from '../Context';

const HEIGHT = 48;

export const UserCountScoreCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();

    const { data, loading, error } = useUserCountQuery({
        variables: {
            extractid,
            filter,
            clusterid
        }
    });

    return (
        <Card size="small" loading={loading}>
            {error && <Alert type="error" message="エラーが発生しました。" />}
            {data && (
                <>
                    <Statistic title="ユーザー数" prefix="n=" value={data?.extract?.userCount} suffix="人" />
                    <div
                        style={{
                            height: HEIGHT,
                            display: 'flex',
                            fontSize: 14,
                            color: textColorSecondary
                        }}
                    >
                        <div style={{ margin: 'auto 0', lineHeight: 1.5 }}>
                            <LaptopOutlined style={{ marginRight: 4 }} />
                            <span>{data?.extract?.pcUserCount} 人</span>
                            <Divider type="vertical" />
                            <MobileOutlined style={{ marginRight: 4 }} />
                            <span>{data?.extract?.spUserCount} 人</span>
                            <Divider type="vertical" />
                            <StarFilled style={{ marginRight: 4 }} />
                            <span>{data?.extract?.likedUserCount} 人</span>
                        </div>
                    </div>
                </>
            )}
        </Card>
    );
};

export const TrendScoreCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useTrendQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart = React.useMemo(() => {
        if (!data?.extract) {
            return <div style={{ height: HEIGHT }} />;
        }

        const { startYm: _startYm, endYm: _endYm } = data?.extract;
        const startYm = parseYm(_startYm);
        const endYm = parseYm(_endYm);

        const yms = makeYms(startYm, endYm);

        return (
            <RaccoonChart
                height={HEIGHT}
                data={data.extract.trend}
                chartOption={{
                    data: [],
                    type: 'bar',
                    color: { colors: [geekblue, cyan] },
                    stack: true,
                    xColumn: {
                        key: 'date',
                        title: '年月',
                        domain: yms.map((ym) => ym.format('YYYY-MM'))
                    },
                    yColumn: {
                        key: 'sessionsRate',
                        title: 'セッション数比率',
                        formatter: (value) => {
                            if (value == null) return '-';
                            return value.toPercent().toFixed(1) + ' %';
                        },
                        axisFormatter: (value) => {
                            if (value == null) return '-';
                            return value.toPercent().toFixed(0) + ' %';
                        },
                        min: 0
                    },
                    seriesColumn: {
                        key: 'type',
                        title: 'ログタイプ',
                        domain: ['browser', 'app'],
                        formatter: (type: string) => {
                            return (
                                {
                                    browser: 'ブラウザ',
                                    app: 'アプリ'
                                }[type] || ''
                            );
                        }
                    },
                    legend: { show: false, position: 'bottom' },
                    xAxis: { show: false },
                    yAxis: { show: false }
                }}
            />
        );
    }, [data]);

    const Stat = React.useMemo(() => {
        if (!data?.extract) {
            return <></>;
        }

        const { startYm: _startYm, endYm: _endYm } = data?.extract;
        const startYm = parseYm(_startYm);
        const endYm = parseYm(_endYm);

        return <Statistic title="期間" value={`${startYm.format('YYYY-MM')} - ${endYm.format('YYYY-MM')}`} />;
    }, [data]);

    return (
        <Card size="small" loading={loading}>
            {error && <Alert type="error" message="エラーが発生しました。" />}
            {data && (
                <>
                    {Stat}
                    <div>{chart}</div>
                </>
            )}
        </Card>
    );
};

export const GenderScoreCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useGetGenderQuery({
        variables: { extractid, filter, clusterid }
    });

    const genderRatio = React.useMemo(() => {
        if (!data?.extract) {
            return '';
        }

        const { avgGender } = data?.extract;

        if (!avgGender) {
            return '';
        }

        const maleRate = 1 - avgGender;
        const femaleRate = avgGender;
        return `${maleRate.toPercent().toFixed(1)} : ${femaleRate.toPercent().toFixed(1)}`;
    }, [data]);

    const chart = React.useMemo(() => {
        if (!data?.extract) {
            return <div style={{ height: HEIGHT }} />;
        }

        const { avgGender } = data?.extract;
        if (avgGender == null) {
            return <div style={{ height: HEIGHT }} />;
        }

        return (
            <RaccoonChart
                height={HEIGHT}
                data={[
                    {
                        column: '性別',
                        gender: '1',
                        usersRate: (1 - avgGender).toFixedNumber(3)
                    },
                    {
                        column: '性別',
                        gender: '2',
                        usersRate: avgGender
                    }
                ]}
                chartOption={{
                    data: [],
                    type: 'bar',
                    color: { colors: [geekblue, red] },
                    layout: 'horizontal',
                    stack: true,
                    percent: true,
                    xColumn: {
                        key: 'column',
                        title: ''
                    },
                    yColumn: {
                        key: 'usersRate',
                        title: 'リーチ率',
                        formatter: (value) => {
                            if (value == null) return '-';
                            return value.toPercent().toFixed(1) + ' %';
                        },
                        min: 0
                    },
                    seriesColumn: {
                        key: 'gender',
                        title: '性別',
                        domain: Object.keys(GENDER),
                        formatter: (gender: string) => {
                            return GENDER[gender];
                        }
                    },
                    legend: { show: false, position: 'bottom' },
                    xAxis: { show: false },
                    yAxis: { show: false }
                }}
            />
        );
    }, [data]);

    return (
        <Card size="small" loading={loading}>
            {error && <Alert type="error" message="エラーが発生しました。" />}
            {data && (
                <>
                    <Statistic title="男女比" value={genderRatio} />
                    <div>{chart}</div>
                </>
            )}
        </Card>
    );
};

export const AgeScoreCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useGetAgeQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart = React.useMemo(() => {
        if (!data?.extract) {
            return <div style={{ height: HEIGHT }} />;
        }

        const { ageBrackets } = data?.extract;
        const rows = ageBrackets.map(({ ageBracket, usersRate }) => {
            return {
                column: '年代',
                ageBracket,
                usersRate
            };
        });

        return (
            <RaccoonChart
                height={HEIGHT}
                data={rows}
                chartOption={{
                    data: [],
                    type: 'bar',
                    color: {
                        colors: [...greenPalette]
                    },
                    layout: 'horizontal',
                    stack: true,
                    percent: true,
                    xColumn: {
                        key: 'column',
                        title: ''
                    },
                    yColumn: {
                        key: 'usersRate',
                        title: 'リーチ率',
                        formatter: (value) => {
                            if (value == null) return '-';
                            return value.toPercent().toFixed(1) + ' %';
                        },
                        min: 0
                    },
                    seriesColumn: {
                        key: 'ageBracket',
                        title: '年代',
                        domain: Object.keys(AGE_BRACKET),
                        formatter: (ageBracket: string) => {
                            return AGE_BRACKET[ageBracket];
                        }
                    },
                    legend: { show: false, position: 'bottom' },
                    xAxis: { show: false },
                    yAxis: { show: false }
                }}
            />
        );
    }, [data]);

    return (
        <Card size="small" loading={loading}>
            {error && <Alert type="error" message="エラーが発生しました。" />}
            {data && (
                <>
                    <Statistic title="平均年齢" value={data?.extract?.avgAge || 0} suffix="歳" />
                    <div>{chart}</div>
                </>
            )}
        </Card>
    );
};
