import { tryToGetToken } from '@/utils/auth';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ServerError } from '@apollo/client/link/utils';
import { message } from 'antd';

const cache = new InMemoryCache({
    resultCaching: false
});

const httpLink = new HttpLink({
    uri: '/graphql',
    fetchOptions: {
        credentials: 'same-origin'
    }
});

function isServerError(arg: any): arg is ServerError {
    return arg.statusCode !== undefined;
}

const errMessages: {
    [errcode: string]: string;
} = {
    TOO_MANY_EXTRACT_SAVES: '同時に保存できる件数の上限に到達しています。抽出を削除して再度実行してください。',
    EXCEED_MAX_EXTRACT_COUNT: '今月の抽出上限に達しました。これ以上抽出できません。',
    NO_MORE_TICKET: '残チケットがありません。これ以上抽出できません。',
    NO_MENU_AUTH: 'メニューの利用権限がありません。',
    EXPIRED_EXTRACT: '登録から1ヵ月以上経過したものは集計実行できません。'
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );

        graphQLErrors.forEach((gqlError) => {
            console.log(gqlError);
            const code = gqlError.extensions.code as string;

            if (code && errMessages[code]) {
                const errMessage = errMessages[code];
                message.error(errMessage);
            }
        });
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        if (isServerError(networkError)) {
            const statusCode = networkError.statusCode;
            if (statusCode === 401) {
                tryToGetToken();
                return;
            }
        }
    }
});

// class RequestIdLink extends ApolloLink {
//     constructor() {
//         super();
//     }

//     request(operation: Operation, forward: NextLink) {
//         console.log(operation);

//         operation.setContext({
//             headers: {
//                 test: 'test'
//             }
//         });

//         console.log(operation.getContext());

//         return forward(operation);
//     }
// }

// const ocLink = new RequestIdLink();

const link = ApolloLink.from([errorLink, httpLink]);

const client = new ApolloClient({
    cache,
    link,
    defaultOptions: {
        watchQuery: {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache'
        },
        query: {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache'
        }
    }
});

export default client;
