export const GENDER: {
    [s: string]: string
} = {
    1: '男性',
    2: '女性'
}

export const AGE_BRACKET: {
    [s: string]: string
} = {
    2: '20代',
    3: '30代',
    4: '40代',
    5: '50代',
    6: '60代以上'
};

export const GENDER_AGE_BRACKET: {
    [s: string]: string;
} = {
    '1-2': '男性20代',
    '1-3': '男性30代',
    '1-4': '男性40代',
    '1-5': '男性50代',
    '1-6': '男性60代以上',
    '2-2': '女性20代',
    '2-3': '女性30代',
    '2-4': '女性40代',
    '2-5': '女性50代',
    '2-6': '女性60代以上',
};