import Favicon from '@/components/Favicon';
import { ExtractSite, useGetSitesQuery } from '@/graphql/generated';
import { Alert, Card, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { useExtractParam } from '../../Context';
import styles from './index.module.less';

export const SiteCard: React.FC<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useGetSitesQuery({
        variables: { extractid, filter, clusterid }
    });

    const table = React.useMemo(() => {
        if (!data?.extract?.sites) {
            return <></>;
        }

        const columns: ColumnProps<ExtractSite>[] = [
            {
                title: '',
                dataIndex: 'favicon',
                width: 40,
                render(_, record) {
                    return <Favicon site={record.site} style={{ margin: 'auto' }} />;
                }
            },
            {
                title: 'サイト',
                dataIndex: 'site',
                render(_, record) {
                    return (
                        <div style={{ lineHeight: 1 }}>
                            <div className={styles.title}>{record.title}</div>
                            <div className={styles.site}>
                                <a rel="noreferrer" target="_blank" href={`https://${record.site}`}>
                                    {record.site}
                                </a>
                            </div>
                        </div>
                    );
                }
            },
            {
                title: 'リーチ率',
                dataIndex: 'usersRate',
                align: 'right',
                width: 120,
                render(_, record) {
                    return <span>{record.usersRate.toFixed(2)} %</span>;
                }
            },
            {
                title: '特徴値',
                dataIndex: 'score',
                align: 'right',
                width: 120,
                render(_, record) {
                    return <span>{record.score.toFixed(2)} pt</span>;
                }
            }
        ];

        return (
            <Table
                size="small"
                tableLayout="fixed"
                columns={columns}
                dataSource={data?.extract?.sites}
                pagination={false}
                rowKey="site"
            />
        );
    }, [data]);

    return (
        <Card size="small" loading={loading} title="特徴的なサイト">
            {error && <Alert type="error" message="エラーが発生しました。" />}
            {table}
        </Card>
    );
};
