import { red } from '@/chartTheme';
import { DashboardCard } from '@/components/DashboardCard';
import { SPORTS_PLAY, SPORTS_WATCH } from '@/constants';
import { useSportsQuery, ValidState } from '@/graphql/generated';
import { Alert } from 'antd';
import React from 'react';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';
import { makeScatterChartOption } from '../Interest';

function getFields(name: string) {
    const fields = name === 'watch' ? SPORTS_WATCH : SPORTS_PLAY;
    return fields;
}

export const SportsCard: React.FunctionComponent<{
    loading: boolean;
    error: any;
    data?: {
        sports: string;
        usersRate: number;
        score: number;
        name: string;
    }[];
    validState?: ValidState;
}> = ({ error, loading, data, validState }) => {
    const chart = React.useMemo(() => {
        if (!data) {
            return;
        }

        if (validState === 'INVALID') {
            return;
        }

        return makeScatterChartOption({
            data: data.map(({ sports, usersRate, score, name }) => {
                return {
                    id: Number(sports),
                    text: getFields(name)[sports],
                    sizeValue: usersRate,
                    xValue: score,
                    yValue: usersRate,
                    color: red,
                    score,
                    usersRate,
                    name
                };
            }),
            domain: ['watch', 'play'],
            formatter: (s) => {
                return (
                    {
                        watch: '観戦するスポーツ',
                        play: '実践するスポーツ'
                    }[s] || ''
                );
            }
        });
    }, [validState, data]);

    return (
        <DashboardCard
            height="100%"
            size="small"
            title="スポーツ"
            data={chart?.data}
            chartOption={chart}
            loading={loading}
            error={error}
            warn={
                <>
                    {validState === 'WARNING' && (
                        <Alert type="warning" message="サンプル数が少ないため表示されている値は参考値になります。" />
                    )}
                    {validState === 'INVALID' && (
                        <Alert type="error" message="サンプル数が少ないため、表示できません。" />
                    )}
                </>
            }
        />
    );
};

export const SportsPage: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useSportsQuery({
        variables: {
            extractid,
            filter,
            clusterid
        }
    });

    const bodyData = React.useMemo(() => {
        return [
            ...(data?.extract?.sportsWatch?.data.map((n) => ({
                ...n,
                name: 'watch'
            })) ?? []),
            ...(data?.extract?.sportsPlay?.data.map((n) => ({
                ...n,
                name: 'play'
            })) ?? [])
        ];
    }, [data]);

    const validState = React.useMemo(() => {
        return data?.extract?.sportsWatch?.validState;
    }, [data]);

    return (
        <div style={{ padding: 12, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: 12 }}>
                <Filter />
            </div>
            <div style={{ flex: 1, overflow: 'hidden' }}>
                <SportsCard loading={loading} error={error} data={bodyData} validState={validState} />
            </div>
        </div>
    );
};
