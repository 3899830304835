import { useExtractParam } from '@/pages/Extract/Context';
import { Spin } from 'antd';
import React from 'react';

export const InfinityScrollbars: React.FunctionComponent<{
    loading?: boolean;
    scrollRef?: any;
    rowKey: string;
    items: any[];
    Row: React.MemoExoticComponent<
        React.FunctionComponent<{
            item: any;
            extractid: number;
            prevItem: any;
            index: number;
            active: boolean;
            setActive: (b: boolean) => void;
        }>
    >;
    activeIndex?: number | undefined;
    setActiveIndex?: (index: number | undefined) => void;
    onChange?: (end: number) => void;
}> = ({ loading, scrollRef, rowKey, items, Row, activeIndex, setActiveIndex = () => {}, onChange = () => {} }) => {
    const { extractid } = useExtractParam();
    const [size, setSize] = React.useState(100);
    const [showIndex, setShowIndex] = React.useState(0);
    const scroll = React.useRef<HTMLDivElement>(null);
    const [moveToTrigger, setGotoTrigger] = React.useState<number>(0);
    const moveToIndex = React.useRef<number | null>(null);
    const refFunc = React.useRef<Function>(() => {});

    const moveTo = React.useCallback(
        (index: number) => {
            let showIndex = 0;
            for (let i = 0; i < items.length; i += size) {
                if (index >= i && index < i + size) {
                    showIndex = i;
                    break;
                }
            }
            moveToIndex.current = index;
            setShowIndex(showIndex);
            setGotoTrigger(moveToTrigger + 1);
        },
        [moveToTrigger, size, items]
    );

    React.useEffect(() => {
        if (scrollRef) {
            scrollRef.current = { moveTo };
        }
    }, [scrollRef, moveTo]);

    React.useEffect(() => {
        setShowIndex(0);
    }, [items]);

    React.useEffect(() => {
        let end = showIndex + size;
        if (end > items.length) {
            end = items.length;
        }
        onChange(end);
    }, [showIndex, items, onChange, size]);

    React.useLayoutEffect(() => {
        if (!scroll.current) {
            return;
        }

        if (moveToIndex.current === null) {
            return;
        }

        const els = scroll.current.getElementsByClassName(`row-${moveToIndex.current}`);
        if (els.length > 0) {
            els[0].scrollIntoView();
        }
        moveToIndex.current = null;
    }, [showIndex, moveToTrigger]);

    refFunc.current = React.useCallback(() => {
        if (!scroll.current) {
            return;
        }

        const height = scroll.current.scrollHeight - scroll.current.clientHeight;
        const top = scroll.current.scrollTop;

        if (top <= 2) {
            moveToIndex.current = showIndex;
            const index = showIndex - size / 2;
            setShowIndex(index < 0 ? 0 : index);
        } else if (top >= height - 2) {
            if (items.length > showIndex + size) {
                setShowIndex(showIndex + size / 2);
            }
        }
    }, [size, showIndex, items]);

    React.useLayoutEffect(() => {
        if (!scroll.current) {
            return;
        }

        if (items.length === 0) {
            return;
        }

        if (size < 0) {
            setSize(0);
            return;
        }

        if (scroll.current.scrollHeight < scroll.current.clientHeight && size < items.length) {
            setSize(size + 100);
            return;
        }
    }, [items, size]);

    React.useEffect(() => {
        let timeoutId: NodeJS.Timeout;

        const handler = () => {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(async function () {
                refFunc.current();
            }, 10);
        };

        const div = scroll.current;
        div?.addEventListener('scroll', handler);

        return () => {
            div?.removeEventListener('scroll', handler);
        };
    }, []);

    return (
        <div className="slim-scroll" style={{ width: '100%', height: '100%', background: 'transparent' }} ref={scroll}>
            {[...items].splice(showIndex, size).map((item, i) => {
                const index = showIndex + i;

                return (
                    <div className={`row-${index}`} key={item[rowKey]}>
                        <Row
                            {...{ prevItem: items[index - 1], item, extractid, index }}
                            active={activeIndex === item[rowKey]}
                            setActive={(active) => {
                                if (active) {
                                    setActiveIndex(item[rowKey]);
                                } else {
                                    setActiveIndex(undefined);
                                }
                            }}
                        />
                    </div>
                );
            })}
            {loading && (
                <div style={{ textAlign: 'center', padding: 8 }}>
                    <Spin />
                </div>
            )}
        </div>
    );
};
