import { ChartOptionUnion, RaccoonChart as RChart } from 'raccoon-chart';
import 'raccoon-chart/dist/style.css';
import React from 'react';

export const RaccoonChart: React.FC<{
    loading?: boolean;
    data?: any[];
    error?: any;
    chartOption?: ChartOptionUnion;
    height?: string | number;
    background?: string;
    chart?: React.MutableRefObject<RChart | null>;
    showType?: 'chart' | 'text';
}> = ({ loading = false, data, error, chartOption, height = '100%', background, chart, showType = 'chart' }) => {
    const el = React.useRef<HTMLDivElement | null>(null);
    const ins = React.useRef<RChart | null>(null);

    React.useEffect(() => {
        if (ins.current) {
            ins.current.showType.set(showType);
        }
    }, [ins, showType]);

    React.useEffect(() => {
        const co: ChartOptionUnion = {
            focusable: false,
            devicePixelRatio: 1,
            toolbox: {
                show: false
            },
            ...chartOption,
            font: {
                size: 12,
                family: `'Emoji', 'Roboto', 'Noto Sans JP', sans-serif`
            },
            loading,
            data: data || [],
            error
        };

        if (ins.current) {
            ins.current.option.set(co);
            return;
        }

        if (el && el.current) {
            ins.current = new RChart(el.current, co);
            if (chart) {
                chart.current = ins.current;
            }
        }
    }, [ins, loading, chartOption, data, error, chart]);

    React.useEffect(() => {
        return () => {
            if (ins.current) {
                ins.current.destroy();
                ins.current = null;
                if (chart) {
                    chart.current = null;
                }
            }
        };
    }, []);

    return <div style={{ width: '100%', height, background }} ref={el} />;
};

// import React from "react";
// import { RaccoonChart as RChart, ChartOptionUnion } from "raccoon-chart";
// import { Spin } from "antd";
// import styles from "./index.module.less";

// export const RaccoonChart: React.FC<{
//     loading?: boolean;
//     data?: any[];
//     chartOption: ChartOptionUnion;
//     height: string | number;
// }> = ({ loading = false, data, chartOption, height }) => {
//     const el = React.useRef<HTMLDivElement | null>(null);
//     const ins = React.useRef<RChart | null>(null);

//     React.useEffect(() => {
//         if (!data) {
//             return;
//         }

//         if (loading) {
//             return;
//         }

//         if (!el.current) {
//             return;
//         }

//         if (ins.current) {
//             ins.current.option.set({
//                 ...chartOption,
//                 data,
//             });
//             return;
//         }

//         if (chartOption) {
//             ins.current = new RChart(el.current, {
//                 ...chartOption,
//                 data,
//             });
//         }
//     }, [ins, el, chartOption, data, loading]);

//     React.useEffect(() => {
//         return () => {
//             if (ins.current) {
//                 ins.current.destroy();
//             }
//         };
//     }, []);

//     return (
//         <div style={{ width: "100%", height }} className={styles.chart}>
//             <Spin spinning={loading}>
//                 <div
//                     style={{
//                         width: "100%",
//                         height,
//                     }}
//                     ref={el}
//                 />
//             </Spin>
//         </div>
//     );
// };
