import React from 'react';
import { useBasicInfoQuery } from '@/graphql/generated';
import { parseYm } from '@/utils/parseYm';

export const GlobalContext = React.createContext<{
    availableMonth: {
        startYm: moment.Moment;
        endYm: moment.Moment;
    }
}>(undefined!);

export const useGlobal = () => {
    return React.useContext(GlobalContext);
};

export const GlobalProvider: React.FunctionComponent<{
    children: React.ReactNode;
}> = ({
    children
}) => {
        const { data } = useBasicInfoQuery();

        if (!data) {
            return <></>;
        }

        const endYm = parseYm(data.availableMonth.yyyymm);
        const startYm = endYm.clone().add(-23, 'months');

        return (
            <GlobalContext.Provider
                value={{
                    availableMonth: {
                        startYm,
                        endYm
                    }
                }}
            >
                {children}
            </GlobalContext.Provider>
        );
    };
