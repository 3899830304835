import { bluePalette2, colorPalette, colorPalette20, cyan, geekblue, red, yellow } from '@/chartTheme';
import { AppCategorySelect } from '@/components/AppCategorySelect';
import Appicon from '@/components/Appicon';
import Favicon from '@/components/Favicon';
import { InfinityScrollbars } from '@/components/InfinityScrollbars';
import { RaccoonChart } from '@/components/RaccoonChart';
import { SiteCategorySelect } from '@/components/SiteCategorySelect';
import { AGE_BRACKET, CHILD, GENDER, HOME_INCOME, JOB, MARRIAGE, PREFECTURE } from '@/constants';
import { useAuth } from '@/contexts/Auth';
import {
    ExtractUserRow,
    ExtractUserSessionRow,
    useNoteLazyQuery,
    useUpdateCommentMutation,
    useUpdateLikedMutation,
    useUpdateNoteMutation,
    useUserAppCategoriesQuery,
    useUserListQuery,
    useUserSessionsLazyQuery,
    useUserSiteCategoriesQuery,
    useUserWordsQuery
} from '@/graphql/generated';
import { primaryColor } from '@/theme';
import { downloadFile } from '@/utils/downloadFile';
import { makeYws, parseYm } from '@/utils/parseYm';
import {
    FileTextFilled,
    FileTextOutlined,
    LaptopOutlined,
    MessageOutlined,
    MobileOutlined,
    ProjectOutlined,
    StarFilled,
    StarOutlined
} from '@ant-design/icons';
import { Button, Col, Input, Radio, Row, Spin, Switch, message } from 'antd';
import chroma from 'chroma-js';
import moment from 'moment';
import { ChartOptionUnion } from 'raccoon-chart';
import React from 'react';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';
import styles from './index.module.less';

const { TextArea } = Input;

const UserRow = React.memo(
    ({
        item,
        extractid,
        index,
        active,
        setActive
    }: {
        index: number;
        extractid: number;
        item: ExtractUserRow;
        active: boolean;
        setActive: (b: boolean) => void;
    }) => {
        const [note, setNote] = React.useState<string>('');
        const [hasNote, setHasNote] = React.useState<boolean>(Boolean(item.note));
        const [liked, setLiked] = React.useState<boolean>(item.liked === 1);
        const [updateNote, updateNoteInfo] = useUpdateNoteMutation({
            onCompleted() {
                setHasNote(Boolean(note));
                message.success('保存しました。');
            },
            onError() {
                message.error('保存に失敗しました。');
            }
        });

        const [updateLiked] = useUpdateLikedMutation({
            onCompleted() {
                // message.success('保存しました。');
            },
            onError() {
                // message.error('保存に失敗しました。');
            }
        });
        const { extractuserid } = item;
        const [fetchNote, { loading }] = useNoteLazyQuery({
            onCompleted(data) {
                if (data?.extract?.userList) {
                    setNote(data?.extract?.userList.note || '');
                }
            }
        });

        React.useEffect(() => {
            if (!active) {
                return;
            }

            fetchNote({
                variables: {
                    extractid,
                    extractuserid
                }
            });
        }, [extractid, extractuserid, fetchNote, active]);

        const clusterColor = item.clusterid ? colorPalette[item.clusterid - 1] : 'transparent';

        return (
            <>
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            width: 4,
                            background: clusterColor
                        }}
                    />
                    <div
                        className={[styles.userItem, ...(active ? [styles.active] : [])].join(' ')}
                        onClick={() => {
                            if (active) {
                                setActive(false);
                            } else {
                                setActive(true);
                            }
                        }}
                    >
                        <div
                            className={styles.device}
                            style={{
                                color: item.gender === '1' ? geekblue : red
                            }}
                        >
                            {item.dbname === 'aqfp' || item.dbname === 'aquv' ? <LaptopOutlined /> : <MobileOutlined />}
                        </div>
                        <div className={styles.middleContent}>
                            <div className={styles.top}>#{String(item.extractuserid).padStart(5, '0')}</div>
                            <div className={styles.bottom}>
                                <span style={{ marginRight: 4 }}>
                                    <StarOutlined style={{ marginRight: 2 }} />
                                    {item.likes}
                                </span>
                                <span style={{ marginRight: 4 }}>
                                    <ProjectOutlined style={{ marginRight: 2 }} />
                                    {item.blocks}
                                </span>
                            </div>
                        </div>
                        <div
                            className={styles.star}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setLiked(!liked);
                                updateLiked({
                                    variables: {
                                        extractid,
                                        extractuserid,
                                        liked: !liked
                                    }
                                });
                            }}
                        >
                            {liked ? <StarFilled style={{ color: primaryColor }} /> : <StarOutlined />}
                        </div>
                        <div className={styles.note}>
                            {hasNote ? <FileTextFilled style={{ color: primaryColor }} /> : <FileTextOutlined />}
                        </div>
                    </div>
                </div>
                {active && (
                    <div className={styles.detail}>
                        <div className={styles.info}>
                            {/* <Row>
                                <Col span={12}>
                                    <div>
                                        VID: <span className={styles.attrValue}>{item.vid}</span>
                                    </div>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col span={12}>
                                    <div>
                                        性別: <span className={styles.attrValue}>{GENDER[item.gender]}</span>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div>
                                        年代: <span className={styles.attrValue}>{AGE_BRACKET[item.ageBracket]}</span>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div>
                                        未既婚: <span className={styles.attrValue}>{MARRIAGE[item.marriage]}</span>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div>
                                        子供有無: <span className={styles.attrValue}>{CHILD[item.child]}</span>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div>
                                        居住都道府県:{' '}
                                        <span className={styles.attrValue}>{PREFECTURE[item.prefecture]}</span>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div>
                                        世帯年収:{' '}
                                        <span className={styles.attrValue}>
                                            {HOME_INCOME[item.homeIncome || ''] || '未回答'}
                                        </span>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div>
                                        職業:{' '}
                                        <span className={styles.attrValue}>{JOB[item.job || ''] || '未回答'}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Spin spinning={loading}>
                            <TextArea
                                value={note}
                                onChange={(e) => {
                                    setNote(e.target.value);
                                }}
                                size="small"
                                rows={10}
                            />
                        </Spin>
                        <Button
                            type="primary"
                            block
                            onClick={() => {
                                updateNote({
                                    variables: {
                                        extractid,
                                        extractuserid,
                                        note
                                    }
                                });
                            }}
                            loading={updateNoteInfo.loading}
                        >
                            保存
                        </Button>
                    </div>
                )}
            </>
        );
    },
    (prev, next) => prev.active === next.active
);

const SessionRow = React.memo(
    ({
        item,
        extractid,
        prevItem,
        index,
        active,
        setActive
    }: {
        index: number;
        extractid: number;
        prevItem: ExtractUserSessionRow;
        item: ExtractUserSessionRow;
        active: boolean;
        setActive: (b: boolean) => void;
    }) => {
        // const [liked, setLiked] = React.useState<boolean>(false);
        const [comment, setComment] = React.useState<string>(item.comment || '');
        const [editComment, setEditComment] = React.useState<string>('');
        const [opened, setOpened] = React.useState<boolean>(false);

        const [updateComment, updateCommentInfo] = useUpdateCommentMutation({
            onCompleted() {
                setOpened(false);
                message.success('保存しました。');
            },
            onError() {
                message.error('保存に失敗しました。');
            }
        });

        React.useEffect(() => {
            if (!opened) {
                return;
            }

            setEditComment(comment);
        }, [opened, comment]);

        const { extractuserid, extractsessionid } = item;

        return (
            <>
                {item.blockid !== prevItem?.blockid && (
                    <div>
                        <div
                            style={{
                                background: '#e2e8f0',
                                fontSize: 16,
                                fontWeight: 900,
                                margin: 8,
                                padding: '4px 8px'
                            }}
                        >
                            {moment(item.accessTime).format('YYYY-MM-DD(ddd) HH時')}
                        </div>
                    </div>
                )}
                {item.type === 'browser' && (
                    <div
                        className={[
                            styles.sessionItem,
                            item.base ? styles.base : '',
                            item.filter ? styles.filter : ''
                        ].join(' ')}
                    >
                        <div className={[styles.faviconWrap, styles[item.type]].join(' ')}>
                            <Favicon style={{ margin: 'auto' }} site={item.site || undefined} />
                        </div>
                        <div className={styles.mainContent}>
                            <div className={styles.time}>
                                {index + 1}. ブラウザ
                                <div style={{ flex: 1, overflow: 'hidden' }} />
                                {moment(item.accessTime).format('YYYY-MM-DD(ddd) HH時')}
                                (PV {item.pv})
                            </div>
                            <div className={styles.title}>{item.title}</div>
                            <div className={styles.url}>
                                <a target="_blank" rel="noreferrer" href={`https://${item.url}`}>
                                    {item.url}
                                </a>
                            </div>
                        </div>
                        <div className={styles.refContainer}>
                            <div className={styles.refType}>流入経路: {item.refCatName}</div>
                            <div className={styles.refContent}>
                                <span style={{ fontWeight: 900 }}>{item.refKeyword}</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <MessageOutlined
                                style={{
                                    margin: 'auto',
                                    padding: '0 8px',
                                    fontSize: 14,
                                    color: primaryColor,
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setOpened(!opened);
                                }}
                            />
                        </div>
                    </div>
                )}
                {item.type === 'item' && (
                    <div
                        className={[
                            styles.sessionItem,
                            item.base ? styles.base : '',
                            item.filter ? styles.filter : ''
                        ].join(' ')}
                    >
                        <div className={[styles.faviconWrap, styles[item.type]].join(' ')}>
                            <Favicon style={{ margin: 'auto' }} site="www.amazon.co.jp" />
                        </div>
                        <div className={styles.mainContent}>
                            <div className={styles.time}>
                                {index + 1}. Amazon商品
                                <div style={{ flex: 1, overflow: 'hidden' }} />
                                {moment(item.accessTime).format('YYYY-MM-DD(ddd) HH時')}
                            </div>
                            <div className={styles.title}>{item.title}</div>
                            <div className={styles.url}>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://www.amazon.co.jp/dp/${item.site}`}
                                >{`https://www.amazon.co.jp/dp/${item.site}`}</a>
                            </div>
                        </div>
                        <div className={styles.refContainer}></div>
                        <div style={{ display: 'flex' }}>
                            <MessageOutlined
                                style={{
                                    margin: 'auto',
                                    padding: '0 8px',
                                    fontSize: 14,
                                    color: primaryColor,
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setOpened(!opened);
                                }}
                            />
                        </div>
                    </div>
                )}
                {item.type === 'app' && (
                    <div
                        className={[
                            styles.sessionItem,
                            item.base ? styles.base : '',
                            item.filter ? styles.filter : ''
                        ].join(' ')}
                    >
                        <div className={[styles.faviconWrap, styles[item.type]].join(' ')}>
                            <Appicon style={{ margin: 'auto' }} app={item.site || undefined} />
                        </div>
                        <div className={styles.mainContent}>
                            <div className={styles.time}>
                                {index + 1}. アプリ
                                <div style={{ flex: 1, overflow: 'hidden' }} />
                                {moment(item.accessTime).format('YYYY-MM-DD(ddd) HH時')}
                            </div>
                            <div className={styles.title}>{item.title}</div>
                            <div className={styles.url}>
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={`https://play.google.com/store/apps/details?id=${item.url}&hl=ja`}
                                >
                                    {item.url}
                                </a>
                            </div>
                        </div>
                        <div className={styles.refContainer}></div>
                        <div style={{ display: 'flex' }}>
                            <MessageOutlined
                                style={{
                                    margin: 'auto',
                                    padding: '0 8px',
                                    fontSize: 14,
                                    color: primaryColor,
                                    cursor: 'pointer'
                                }}
                                onClick={() => {
                                    setOpened(!opened);
                                }}
                            />
                        </div>
                    </div>
                )}
                {!opened && comment && (
                    <div style={{ padding: 8 }}>
                        <div
                            style={{
                                padding: 4,
                                border: '1px solid #d9d9d9',
                                borderRadius: 4,
                                whiteSpace: 'pre',
                                background: chroma(primaryColor).alpha(0.1).hex()
                            }}
                        >
                            {comment}
                        </div>
                    </div>
                )}
                {opened && (
                    <div className={styles.sessionCommentContainer} style={{ padding: 8 }}>
                        <Spin
                            // spinning={loading}
                            spinning={false}
                        >
                            <TextArea
                                value={editComment}
                                onChange={(e) => {
                                    setEditComment(e.target.value);
                                }}
                                size="small"
                                rows={4}
                            />
                        </Spin>
                        <div style={{ textAlign: 'right', marginTop: 8 }}>
                            <Button
                                type="primary"
                                style={{ marginRight: 8, width: 160 }}
                                onClick={() => {
                                    setComment(editComment);
                                    updateComment({
                                        variables: {
                                            extractid,
                                            extractuserid,
                                            extractsessionid,
                                            comment: editComment
                                        }
                                    });
                                }}
                                loading={updateCommentInfo.loading}
                            >
                                保存
                            </Button>
                            <Button
                                style={{ marginRight: 8, width: 160 }}
                                onClick={() => {
                                    setOpened(false);
                                }}
                            >
                                キャンセル
                            </Button>
                        </div>
                    </div>
                )}
            </>
        );
    },
    (prev, next) => prev.active === next.active && prev.prevItem === next.prevItem
);

const options = [
    { label: 'ワードクラウド', value: 'word' },
    { label: '推移', value: 'trend' },
    { label: 'サイトカテゴリ', value: 'siteCategory' },
    { label: 'アプリカテゴリ', value: 'appCategory' }
];

const titleMap = new Map<string, string>();
options.forEach(({ label, value }) => {
    titleMap.set(value, label);
});

const SiteCategories: React.FC<{
    extractuserid: number;
}> = ({ extractuserid }) => {
    const { extractid } = useExtractParam();
    const { data, loading } = useUserSiteCategoriesQuery({
        variables: {
            extractid,
            extractuserid
        }
    });

    const chartOption: ChartOptionUnion = React.useMemo(() => {
        return {
            data: [],
            type: 'treeMap',
            seriesColumn: {
                key: 'siteCategory',
                title: 'カテゴリ'
            },
            colorColumn: {
                type: 'category',
                key: 'siteCategory'
            },
            valueColumn: {
                key: 'sessions',
                title: 'セッション数'
            },
            color: { colors: colorPalette20 },
            legend: { show: false, position: 'bottom' }
        };
    }, []);

    return (
        <RaccoonChart
            height={'100%'}
            loading={loading}
            data={data?.extract?.userList.siteCategories}
            chartOption={chartOption}
        />
    );
};

const AppCategories: React.FC<{
    extractuserid: number;
}> = ({ extractuserid }) => {
    const { extractid } = useExtractParam();
    const { data, loading } = useUserAppCategoriesQuery({
        variables: {
            extractid,
            extractuserid
        }
    });

    const chartOption: ChartOptionUnion = React.useMemo(() => {
        return {
            data: [],
            type: 'treeMap',
            seriesColumn: {
                key: 'category',
                title: 'カテゴリ'
            },
            colorColumn: {
                type: 'category',
                key: 'category'
            },
            valueColumn: {
                key: 'sessions',
                title: 'セッション数'
            },
            color: { colors: colorPalette20 },
            legend: { show: false, position: 'bottom' }
        };
    }, []);

    return (
        <RaccoonChart
            height={'100%'}
            loading={loading}
            data={data?.extract?.userList.appCategories}
            chartOption={chartOption}
        />
    );
};

const WordCloud: React.FC<{
    extractuserid: number;
    siteCategory?: string[];
    sessionsRef: React.MutableRefObject<any[] | null>;
    scrollRef: React.MutableRefObject<any>;
}> = ({ extractuserid, siteCategory, sessionsRef, scrollRef }) => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading } = useUserWordsQuery({
        variables: {
            extractid,
            extractuserid,
            siteCategory,
            filter,
            clusterid
        }
    });

    const words = React.useMemo(
        () =>
            data?.extract?.userList.userWords?.map(({ word, cnt }, index) => ({
                index,
                name: word,
                value: cnt,
                cnt
            })),
        [data]
    );

    const chartOption: ChartOptionUnion = React.useMemo(() => {
        return {
            data: [],
            type: 'wordCloud',
            color: {
                colors: [...bluePalette2].reverse()
            },
            textColumn: { key: 'name', title: 'ワード' },
            sizeColumn: {
                key: 'value',
                title: 'セッション数'
            },
            colorColumn: {
                type: 'value',
                key: 'index'
            },
            legend: { show: false, position: 'bottom' },
            sizeRange: [12, 32],
            onClick(shape: any) {
                const sessions = sessionsRef.current;
                if (!sessions) {
                    return;
                }

                if (shape && shape.data) {
                    const value = shape.data.text;
                    const index = sessions.findIndex((n: any) => {
                        if (n.refKeyword) {
                            return n.refKeyword.includes(value);
                        }
                        return false;
                    });
                    if (index > -1) {
                        scrollRef.current.moveTo(index);
                    }
                }
            }
        };
    }, [scrollRef, sessionsRef]);

    return <RaccoonChart height={'100%'} loading={loading} data={words} chartOption={chartOption} />;
};

type SessionType = 'browser' | 'app' | 'item';

export const StoryPage: React.FunctionComponent<{}> = () => {
    const {
        extractid,
        filter,
        clusterid,
        extract: { title, startYm, endYm }
    } = useExtractParam();
    const auth = useAuth();
    const scrollRef = React.useRef<any>(null);
    const [tab, setTab] = React.useState<'word' | 'trend' | 'siteCategory' | 'appCategory'>('word');
    const [liked, setLiked] = React.useState<boolean>(false);
    // const [users, setUsers] = React.useState<ExtractUserRow[]>([]);
    // const [sessions, setSessions] = React.useState<ExtractUserSessionRow[]>([]);
    const [activeUserid, setActiveUserid] = React.useState<number>();
    const [currentUserIndex, setCurrentUserIndex] = React.useState<number>(0);
    const [currentSessionIndex, setCurrentSessionIndex] = React.useState<number>(0);
    const [withApp, setWithApp] = React.useState<boolean>(true);
    const [withItem, setWithItem] = React.useState<boolean>(true);
    const [siteCategories, setSiteCategories] = React.useState<string[]>([]);
    const [validSiteCategories, setValidSiteCategories] = React.useState<string[]>([]);
    const [appCategories, setAppCategories] = React.useState<string[]>([]);
    const [validAppCategories, setValidAppCategories] = React.useState<string[]>([]);

    const { data, loading } = useUserListQuery({
        variables: {
            extractid,
            filter,
            clusterid,
            limit: 10000,
            offset: 0,
            liked
        }
    });
    const [fetchSessions, { data: sessionsData, loading: sessionsLoading }] = useUserSessionsLazyQuery();

    const users = React.useMemo(() => data?.extract?.userList.users, [data]);
    const sessions = React.useMemo(() => sessionsData?.extract?.userList.sessions, [sessionsData]);

    const sessionsRef = React.useRef<any[] | null>([]);

    React.useEffect(() => {
        sessionsRef.current = sessions || null;
    }, [sessions]);

    React.useEffect(() => {
        if (activeUserid != null) {
            fetchSessions({
                variables: {
                    extractid,
                    extractuserid: activeUserid,
                    siteCategory: validSiteCategories,
                    appCategory: validAppCategories,
                    offset: 0,
                    limit: 10000,
                    filter,
                    clusterid,
                    withApp,
                    withItem
                }
            });
        }
    }, [
        activeUserid,
        clusterid,
        extractid,
        fetchSessions,
        filter,
        validAppCategories,
        validSiteCategories,
        withApp,
        withItem
    ]);

    const onDownload = React.useCallback(() => {
        if (!activeUserid) {
            return;
        }

        if (!sessions) {
            return;
        }

        downloadFile({
            headers: ['アクセス日時', 'URL', 'タイトル', '検索KWD', '流入経路'],
            data:
                sessions.map((n) => {
                    return [n.accessTime, n.url, n.title, n.refKeyword, n.refCatName];
                }) || [],
            fileName: `${title}-${activeUserid}.txt`
        });
    }, [activeUserid, sessions, title]);

    const chartOption2: ChartOptionUnion = React.useMemo(() => {
        const ymds = makeYws(parseYm(startYm), parseYm(endYm));

        const map = new Map<string, string>();

        ymds.forEach((yw) => {
            map.set(yw.format('GGGG-WW'), yw.format('YYYY-MM-DD'));
        });

        return {
            focusable: false,
            data: [],
            type: 'bar',
            color: { colors: [geekblue, cyan, yellow] },
            stack: true,
            xColumn: {
                key: 'ymd',
                title: '日付',
                domain: ymds.map((ymd) => ymd.format('GGGG-WW')),
                formatter: (date: string) => {
                    return map.get(date) || '';
                }
            },
            yColumn: {
                key: 'sessions',
                title: 'セッション数',
                min: 0
            },
            seriesColumn: {
                key: 'type',
                title: 'ログタイプ',
                domain: ['browser', 'app', 'item'],
                formatter: (type: string) => {
                    return (
                        {
                            browser: 'ブラウザ',
                            app: 'アプリ',
                            item: 'Amazon商品'
                        }[type] || ''
                    );
                }
            },
            onClick: (shape: any) => {
                if (!sessions) {
                    return;
                }

                if (shape && shape.data) {
                    const date = moment(map.get(shape.data.x));
                    const index = sessions.findIndex((n) => {
                        return moment(n.accessTime) > date;
                    });
                    if (index > -1) {
                        scrollRef.current.moveTo(index);
                    }
                }
            }
        };
    }, [endYm, sessions, startYm]);

    const chart2 = React.useMemo(() => {
        if (!activeUserid) {
            return <></>;
        }

        if (!sessions) {
            return <></>;
        }

        const data: any[] = [];

        const dailyData: {
            [ymd: string]: {
                browser: number;
                app: number;
                item: number;
            };
        } = {};

        sessions.forEach((n) => {
            const ymd = moment(n.accessTime).format('GGGG-WW');
            if (!dailyData[ymd]) {
                dailyData[ymd] = { browser: 0, app: 0, item: 0 };
            }
            dailyData[ymd][n.type as SessionType] += 1;
        });

        Object.keys(dailyData).forEach((ymd) => {
            data.push({
                ymd,
                type: 'browser',
                sessions: dailyData[ymd].browser
            });
            data.push({ ymd, type: 'app', sessions: dailyData[ymd].app });
            data.push({ ymd, type: 'item', sessions: dailyData[ymd].item });
        });

        return <RaccoonChart height={'100%'} data={data} chartOption={chartOption2} />;
    }, [activeUserid, sessions, chartOption2]);

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                padding: 12,
                flexDirection: 'column'
            }}
        >
            <div style={{ marginBottom: 12 }}>
                <Filter />
                <Button
                    style={{ marginLeft: 12 }}
                    onClick={() => {
                        setLiked(!liked);
                    }}
                >
                    {liked ? <StarFilled style={{ color: geekblue }} /> : <StarOutlined />}
                </Button>
                {auth.menu?.canDownload && (
                    <Button style={{ marginLeft: 12 }} onClick={onDownload}>
                        ダウンロード
                    </Button>
                )}
            </div>
            <div style={{ marginBottom: 12 }}>
                <span style={{ fontSize: 12, marginRight: 4 }}>サイトカテゴリ: </span>
                <SiteCategorySelect
                    style={{ width: 240 }}
                    value={siteCategories}
                    onChange={(v) => {
                        setSiteCategories(v);
                    }}
                />
                <span style={{ fontSize: 12, marginLeft: 12, marginRight: 4 }}>アプリカテゴリ: </span>
                <AppCategorySelect
                    style={{ width: 240 }}
                    value={appCategories}
                    onChange={(v) => {
                        setAppCategories(v);
                    }}
                />
                <Button
                    type="primary"
                    style={{ marginLeft: 12 }}
                    onClick={() => {
                        setValidSiteCategories(siteCategories);
                        setValidAppCategories(appCategories);
                    }}
                >
                    適用
                </Button>
                <Button
                    style={{ marginLeft: 12 }}
                    onClick={() => {
                        setValidSiteCategories([]);
                        setSiteCategories([]);
                        setValidAppCategories([]);
                        setAppCategories([]);
                    }}
                >
                    クリア
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    flex: 1,
                    overflow: 'hidden'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: 300,
                        height: '100%',
                        marginRight: 12
                    }}
                >
                    <div className={styles.card}>
                        <div className={styles.cardHead}>
                            <div className={styles.inner}>
                                <span style={{ flex: 1, margin: 'auto 0' }}>
                                    ユーザー
                                    {
                                        <span style={{ fontSize: 12 }}>
                                            {users && (
                                                <>
                                                    ({currentUserIndex} / {users.length})
                                                </>
                                            )}
                                        </span>
                                    }
                                    <Spin style={{ marginLeft: 8 }} spinning={loading} />
                                </span>
                            </div>
                        </div>
                        <div className={[styles.cardBody].join(' ')}>
                            {users && (
                                <InfinityScrollbars
                                    loading={loading}
                                    rowKey="extractuserid"
                                    items={users}
                                    activeIndex={activeUserid}
                                    setActiveIndex={setActiveUserid}
                                    Row={UserRow}
                                    onChange={setCurrentUserIndex}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        flex: 1,
                        overflow: 'hidden',
                        height: '100%',
                        flexDirection: 'column',
                        display: 'flex'
                    }}
                >
                    <div style={{ width: '100%', height: 240, marginBottom: 12 }}>
                        <div className={styles.card}>
                            <div className={styles.cardHead}>
                                <div className={styles.inner}>
                                    <span style={{ flex: 1, margin: 'auto 0' }}>{titleMap.get(tab)}</span>
                                    <Radio.Group
                                        size="small"
                                        options={options}
                                        onChange={(e) => {
                                            setTab(e.target.value as 'word' | 'trend');
                                        }}
                                        value={tab}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </div>
                            </div>
                            <div className={styles.cardBody}>
                                {tab === 'word' && activeUserid && (
                                    <WordCloud
                                        extractuserid={activeUserid}
                                        siteCategory={validSiteCategories}
                                        sessionsRef={sessionsRef}
                                        scrollRef={scrollRef}
                                    />
                                )}
                                {tab === 'trend' && (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: '100%'
                                        }}
                                    >
                                        {chart2}
                                    </div>
                                )}
                                {tab === 'siteCategory' && activeUserid && (
                                    <SiteCategories extractuserid={activeUserid} />
                                )}
                                {tab === 'appCategory' && activeUserid && (
                                    <AppCategories extractuserid={activeUserid} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', flex: 1, overflow: 'hidden' }}>
                        <div className={styles.card}>
                            <div className={styles.cardHead}>
                                <div className={styles.inner}>
                                    <span style={{ flex: 1, margin: 'auto 0' }}>
                                        ストーリー
                                        {
                                            <span style={{ fontSize: 12 }}>
                                                {sessions && (
                                                    <>
                                                        ({currentSessionIndex} / {sessions.length})
                                                    </>
                                                )}
                                            </span>
                                        }
                                    </span>
                                    <div
                                        style={{
                                            display: 'flex',
                                            fontSize: 12,
                                            marginRight: 8,
                                            lineHeight: 1.5
                                        }}
                                    >
                                        <Switch
                                            size="small"
                                            style={{
                                                margin: 'auto 4px auto 0'
                                            }}
                                            checked={withApp}
                                            onChange={(value) => {
                                                setWithApp(value);
                                            }}
                                        />
                                        <div style={{ margin: 'auto 0', lineHeight: 1 }}>アプリを含む</div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            fontSize: 12,
                                            lineHeight: 1.5
                                        }}
                                    >
                                        <Switch
                                            size="small"
                                            style={{
                                                margin: 'auto 4px auto 0',
                                                lineHeight: 1
                                            }}
                                            checked={withItem}
                                            onChange={(value) => {
                                                setWithItem(value);
                                            }}
                                        />
                                        <div style={{ margin: 'auto 0' }}>Amazon商品を含む</div>
                                    </div>
                                </div>
                            </div>
                            <div className={[styles.cardBody, styles.confidential].join(' ')}>
                                {sessionsLoading && (
                                    <div style={{ width: '100%', textAlign: 'center' }}>
                                        <Spin />
                                    </div>
                                )}
                                {activeUserid && sessions && (
                                    <InfinityScrollbars
                                        scrollRef={scrollRef}
                                        loading={sessionsLoading}
                                        rowKey="row"
                                        items={sessions}
                                        Row={SessionRow}
                                        onChange={setCurrentSessionIndex}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
