import { greenPalette } from '@/chartTheme';
import { DashboardCard } from '@/components/DashboardCard';
import { useAppUsageQuery, ValidState } from '@/graphql/generated';
import { Alert, Col, Row } from 'antd';
import React from 'react';
import { makeStackChartOption } from '../Attribute/Cards';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';

const VALUES: {
    [s: string]: string;
} = {
    1: '利用していない',
    2: '30分未満',
    3: '30分以上-1時間未満',
    4: '1時間以上'
};

const HEIGHT = 200;

export const AppCard: React.FunctionComponent<{
    loading: boolean;
    title: string;
    error: any;
    data?: {
        validState: ValidState;
        monitor: {
            value: string;
            usersRate: number;
        }[];
        target: {
            value: string;
            usersRate: number;
        }[];
    };
}> = ({ error, loading, title, data }) => {
    const chart = React.useMemo(() => {
        if (!data) {
            return;
        }

        if (data?.validState === 'INVALID') {
            return undefined;
        }

        const chartData: { [s: string]: number[] } = {};
        Object.keys(VALUES).forEach((value) => {
            chartData[value] = [
                data.target.find((n) => n.value === value)?.usersRate || 0,
                data.monitor.find((n) => n.value === value)?.usersRate || 0
            ];
        });

        return makeStackChartOption({
            color: [...greenPalette].slice(1, 5),
            stacks: Object.keys(VALUES),
            formatter: (s) => {
                return VALUES[s];
            },
            data: chartData
        });
    }, [data]);

    return (
        <DashboardCard
            size="small"
            title={title}
            data={chart?.data}
            chartOption={chart}
            chartHeight={HEIGHT}
            loading={loading}
            error={error}
            warn={
                <>
                    {data?.validState === 'WARNING' && (
                        <Alert type="warning" message="サンプル数が少ないため表示されている値は参考値になります。" />
                    )}
                    {data?.validState === 'INVALID' && (
                        <Alert type="error" message="サンプル数が少ないため、表示できません。" />
                    )}
                </>
            }
        />
    );
};

export const AppUsagePage: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useAppUsageQuery({
        variables: { extractid, filter, clusterid }
    });

    return (
        <div className="slim-scroll" style={{ height: '100%' }}>
            <div style={{ padding: 12 }}>
                <div style={{ marginBottom: 12 }}>
                    <Filter />
                </div>
                <Row gutter={12}>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <AppCard title="LINE利用時間" loading={loading} error={error} data={data?.extract?.lineData} />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <AppCard
                            title="X（旧Twitter）利用時間"
                            loading={loading}
                            error={error}
                            data={data?.extract?.twitterData}
                        />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <AppCard
                            title="Facebook利用時間"
                            loading={loading}
                            error={error}
                            data={data?.extract?.facebookData}
                        />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <AppCard
                            title="Instagram利用時間"
                            loading={loading}
                            error={error}
                            data={data?.extract?.instagramData}
                        />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <AppCard
                            title="ヤフーアプリ利用時間"
                            loading={loading}
                            error={error}
                            data={data?.extract?.yahooData}
                        />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <AppCard
                            title="ニュースアプリ利用時間"
                            loading={loading}
                            error={error}
                            data={data?.extract?.newsData}
                        />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <AppCard
                            title="ゲームアプリ利用時間"
                            loading={loading}
                            error={error}
                            data={data?.extract?.gameData}
                        />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <AppCard
                            title="動画アプリ利用時間"
                            loading={loading}
                            error={error}
                            data={data?.extract?.videoData}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};
