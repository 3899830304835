export const FAMILY: {
    [s: string]: string;
} = {
    1: '単身者（40代以下）',
    2: '単身者（50代以上）',
    3: '独身・親と同居世帯',
    4: '夫婦のみ世帯（40代以下）',
    5: '夫婦のみ世帯（50代以上）',
    6: '子どもあり世帯（末子未就学児）',
    7: '子どもあり世帯（末子小学生）',
    8: '子どもあり世帯（末子中・高校生）',
    9: '子どもあり世帯（末子大学生・社会人・その他）',
    10: 'その他'
}