import client from '@/apollo';
import '@/chartTheme';
import '@/extensions';
import { ApolloProvider } from '@apollo/client';
import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';
import 'moment/locale/ja';
import 'raccoon-chart/dist/style.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.less';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider locale={jaJP}>
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
