import { cyan, geekblue } from '@/chartTheme';
import { DashboardCard } from '@/components/DashboardCard';
import { INTEREST } from '@/constants/interest';
import { ValidState, useInterestQuery } from '@/graphql/generated';
import { percentFormatters } from '@/server/utils/chartUtils';
import { Alert } from 'antd';
import { CategoryColumnWithType, ChartOptionUnion, ValueColumnWithType } from 'raccoon-chart';
import React from 'react';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';

export const makeScatterChartOption = (args: {
    data?: any[];
    domain: string[];
    formatter: (s: string) => string;
}): ChartOptionUnion => {
    const scoreColumn: ValueColumnWithType = {
        type: 'value',
        key: 'score',
        title: '特徴値',
        formatter: (value) => {
            if (value == null) return '-';
            return `${value.toFixed(2)} pt`;
        },
        axisFormatter: (value) => {
            if (value == null) return '-';
            return `${value.toFixed(1)} pt`;
        }
    };

    const usersColumn: ValueColumnWithType = {
        type: 'value',
        key: 'usersRate',
        title: 'リーチ率',
        ...percentFormatters
    };

    const textColumn: CategoryColumnWithType = { type: 'category', key: 'text', title: '項目' };

    const seriesColumn: CategoryColumnWithType = {
        type: 'category',
        key: 'name',
        title: '系列',
        domain: args.domain,
        formatter: args.formatter
    };

    return {
        data: args.data ?? [],
        type: 'scatter',
        color: { colors: [geekblue, cyan] },
        sizeRange: [4, 16],
        xColumn: scoreColumn,
        yColumn: usersColumn,
        xAxis: {
            showTitle: false
        },
        yAxis: {
            showTitle: false
        },
        sizeColumn: usersColumn,
        colorColumn: seriesColumn,
        textColumn: textColumn,
        guideLines: [{ data: { xAxis: 0 } }],
        dataColumns: [seriesColumn, textColumn, usersColumn, scoreColumn]
    };
};

export const InterestCard: React.FunctionComponent<{
    loading: boolean;
    error: any;
    data?: {
        interest: string;
        usersRate: number;
        score: number;
        name: string;
    }[];
    validState?: ValidState;
}> = ({
    error,
    loading,
    data,
    validState
    // fields
}) => {
    const { enqversion } = useExtractParam();

    const chart = React.useMemo(() => {
        if (!data) {
            return;
        }

        if (validState === 'INVALID') {
            return;
        }

        return makeScatterChartOption({
            data: data.map(({ interest, usersRate, score, name }) => {
                return {
                    id: Number(interest),
                    text: INTEREST[enqversion][interest],
                    sizeValue: usersRate,
                    xValue: score,
                    yValue: usersRate,
                    color: geekblue,
                    interest,
                    usersRate,
                    score,
                    name
                };
            }),
            domain: ['interest'],
            formatter: (s) => {
                return (
                    {
                        interest: '興味・関心'
                    }[s] || ''
                );
            }
        });
    }, [enqversion, validState, data]);

    return (
        <DashboardCard
            height="100%"
            size="small"
            title="興味/関心"
            data={chart?.data}
            chartOption={chart}
            loading={loading}
            error={error}
            warn={
                <>
                    {validState === 'WARNING' && (
                        <Alert type="warning" message="サンプル数が少ないため表示されている値は参考値になります。" />
                    )}
                    {validState === 'INVALID' && (
                        <Alert type="error" message="サンプル数が少ないため、表示できません。" />
                    )}
                </>
            }
        />
    );
};

export const InterestPage: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useInterestQuery({
        variables: {
            extractid,
            filter,
            clusterid
        }
    });

    return (
        <div style={{ padding: 12, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: 12 }}>
                <Filter />
            </div>
            <div style={{ flex: 1, overflow: 'hidden' }}>
                <InterestCard
                    loading={loading}
                    error={error}
                    data={data?.extract?.interest?.interests.map((n) => {
                        return { ...n, name: 'interest' };
                    })}
                    validState={data?.extract?.interest?.validState}
                />
            </div>
        </div>
    );
};
