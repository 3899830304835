import { useAuth } from '@/contexts/Auth';
import { bgColor, textColorSecondaryDark } from '@/theme';
import { logger } from '@/utils/logger';
import {
    DashboardOutlined,
    IdcardOutlined,
    InfoCircleOutlined,
    NodeIndexOutlined,
    OrderedListOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Alert, Layout, Menu } from 'antd';
import React from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ExtractForm } from '../Home';
import { AppRankingPage } from './AppRanking';
import { AppUsagePage } from './AppUsage';
import { AssetsPage } from './Assets';
import Attribute from './Attribute';
import { BeforeAfterPage, BeforeAfterWordPage } from './BeforeAfter';
import { BodyTroublePage } from './BodyTrouble';
import ClusterPage from './Cluster';
import { Provider, useExtractParam } from './Context';
import styles from './index.module.less';
import { InterestPage } from './Interest';
import { MediaContactPage } from './MediaContact';
import { ShoppingPage } from './Shopping';
import { SiteRankingPage } from './SiteRanking';
import { SportsPage } from './Sports';
import { StoryPage } from './Story';
import Summary from './Summary';
import { WordRankingPage } from './WordRanking';

const { Sider, Content } = Layout;

const Extract: React.FunctionComponent<{}> = () => {
    const history = useHistory();
    const location = useLocation();
    const routeMatch = useRouteMatch();
    const { extractid, filter, clusterid, extract } = useExtractParam();
    const auth = useAuth();
    const [modal, setModal] = React.useState<boolean>(false);

    const redirect = React.useCallback(
        ({ location }: any) => {
            const { search } = location;
            return <Redirect to={{ pathname: `${routeMatch.url}/summary`, search }} />;
        },
        [routeMatch]
    );

    const match = location.pathname.match(`${routeMatch.url}/(.*)`);
    const functionid = match?.[1] || '';

    // page executed
    React.useEffect(() => {
        logger({
            url: location.pathname,
            action: 'PAGE_EXECUTED',
            extractid,
            functionid,
            actionParam: {
                filter,
                clusterid
            }
        });
    }, [location, routeMatch, extractid, filter, clusterid, functionid]);

    React.useEffect(() => {
        if (filter) {
            logger({
                url: window.location.pathname,
                action: 'FILTER',
                extractid,
                functionid,
                actionParam: {
                    filter
                }
            });
        }
    }, [functionid, extractid, filter]);

    if (extract.state !== 'COMPLETE') {
        return (
            <div style={{ padding: 12 }}>
                <Alert message="抽出が完了していません。" />
            </div>
        );
    }

    return (
        <Layout>
            <Sider
                theme="dark"
                style={{
                    height: 'calc(100vh - 40px)'
                }}
            >
                <ExtractForm
                    extractid={extractid}
                    mode="read"
                    refetchExtract={() => {}}
                    visible={modal}
                    setVisible={setModal}
                />
                <div className="slim-scroll dark">
                    <div
                        style={{
                            cursor: 'pointer',
                            padding: '0 16px',
                            height: 40,
                            lineHeight: 1.5,
                            display: 'flex',
                            color: textColorSecondaryDark
                        }}
                        onClick={() => {
                            setModal(true);
                        }}
                    >
                        <span style={{ margin: 'auto 0' }}>
                            <InfoCircleOutlined style={{ marginRight: 8 }} />
                            抽出条件
                        </span>
                    </div>
                    <Menu
                        className={styles.menu}
                        style={{ borderRight: '1px solid transparent' }}
                        mode="inline"
                        theme="dark"
                        openKeys={['attribute', 'ranking', 'beforeAfter', 'user', 'cluster']}
                        selectedKeys={[location.pathname]}
                        multiple={false}
                        onSelect={({ selectedKeys }) => {
                            if (selectedKeys && selectedKeys[0]) {
                                const key = selectedKeys[0];
                                history.push({
                                    pathname: key as string,
                                    search: location.search
                                });
                            }
                        }}
                        inlineIndent={16}
                    >
                        <Menu.Item key={`${routeMatch.url}/summary`} icon={<DashboardOutlined />}>
                            サマリ
                        </Menu.Item>
                        <Menu.SubMenu key="attribute" icon={<IdcardOutlined />} title="属性">
                            <Menu.Item key={`${routeMatch.url}/attribute`}>ユーザー属性</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/mediaContact`}>メディア接触</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/appUsage`}>SNS/アプリ利用</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/shopping`}>買い物行動</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/assets`}>保有資産/平均支出</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/interest`}>興味/関心</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/bodyTrouble`}>身体の悩み</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/sports`}>スポーツ</Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu key="ranking" icon={<OrderedListOutlined />} title="ランキング">
                            <Menu.Item key={`${routeMatch.url}/siteRanking`}>閲覧サイト</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/wordRanking`}>検索キーワード</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/appRanking`}>利用アプリ</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/itemRanking`}>Amazon商品</Menu.Item>
                        </Menu.SubMenu>
                        {extract.enableBefAft && (
                            <Menu.SubMenu key="beforeAfter" icon={<NodeIndexOutlined />} title="前後分析">
                                <Menu.Item key={`${routeMatch.url}/beforeAfter`}>閲覧サイト</Menu.Item>
                                <Menu.Item key={`${routeMatch.url}/beforeAfterWord`}>検索キーワード</Menu.Item>
                                {/* <Menu.Item key={`${routeMatch.url}/beforeAfterApp`}>利用アプリ</Menu.Item> */}
                            </Menu.SubMenu>
                        )}
                        {auth.menu?.canStory && (
                            <Menu.SubMenu key="user" icon={<UserOutlined />} title="ユーザー">
                                <Menu.Item key={`${routeMatch.url}/story`}>ストーリー</Menu.Item>
                            </Menu.SubMenu>
                        )}
                        <Menu.SubMenu key="cluster" icon={<TeamOutlined />} title="クラスタ分析">
                            <Menu.Item key={`${routeMatch.url}/cluster`}>クラスタ作成</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/clusterAttribute`}>ユーザー属性</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/clusterMediaContact`}>メディア接触</Menu.Item>
                            <Menu.Item key={`${routeMatch.url}/clusterAppUsage`}>SNS/アプリ利用</Menu.Item>
                        </Menu.SubMenu>
                    </Menu>
                </div>
            </Sider>
            <Layout>
                <Content style={{ background: bgColor }}>
                    <React.Suspense fallback={<div />}>
                        <Switch>
                            <Route exact path={`${routeMatch.url}/summary`} component={Summary} />
                            <Route exact path={`${routeMatch.url}/attribute`} component={Attribute} />
                            <Route exact path={`${routeMatch.url}/mediaContact`} component={MediaContactPage} />
                            <Route exact path={`${routeMatch.url}/appUsage`} component={AppUsagePage} />
                            <Route exact path={`${routeMatch.url}/shopping`} component={ShoppingPage} />
                            <Route exact path={`${routeMatch.url}/assets`} component={AssetsPage} />
                            <Route exact path={`${routeMatch.url}/interest`} component={InterestPage} />
                            <Route exact path={`${routeMatch.url}/bodyTrouble`} component={BodyTroublePage} />
                            <Route exact path={`${routeMatch.url}/sports`} component={SportsPage} />
                            <Route exact path={`${routeMatch.url}/siteRanking`} component={SiteRankingPage} />
                            <Route exact path={`${routeMatch.url}/appRanking`} component={AppRankingPage} />
                            <Route exact path={`${routeMatch.url}/wordRanking`} component={WordRankingPage} />
                            <Route
                                exact
                                path={`${routeMatch.url}/itemRanking`}
                                component={React.lazy(() => import('./ItemRanking'))}
                            />
                            {auth.menu?.canStory && (
                                <Route exact path={`${routeMatch.url}/story`} component={StoryPage} />
                            )}
                            {extract.enableBefAft && (
                                <Route exact path={`${routeMatch.url}/beforeAfter`} component={BeforeAfterPage} />
                            )}
                            {extract.enableBefAft && (
                                <Route
                                    exact
                                    path={`${routeMatch.url}/beforeAfterWord`}
                                    component={BeforeAfterWordPage}
                                />
                            )}
                            <Route exact path={`${routeMatch.url}/cluster`} component={ClusterPage} />
                            <Route
                                exact
                                path={`${routeMatch.url}/clusterAttribute`}
                                component={React.lazy(() => import('./ClusterAttribute'))}
                            />
                            <Route
                                exact
                                path={`${routeMatch.url}/clusterMediaContact`}
                                component={React.lazy(() => import('./ClusterMediaContact'))}
                            />
                            <Route
                                exact
                                path={`${routeMatch.url}/clusterAppUsage`}
                                component={React.lazy(() => import('./ClusterAppUsage'))}
                            />
                            {/* <Route
                                exact
                                path={`${routeMatch.url}/dashboard`}
                                component={React.lazy(
                                    () => import("./Dashboard")
                                )}
                            /> */}
                            <Route component={redirect} />
                        </Switch>
                    </React.Suspense>
                </Content>
            </Layout>
        </Layout>
    );
};

const ExtractPage = () => {
    return (
        <Provider>
            <Extract />
        </Provider>
    );
};

export default ExtractPage;
