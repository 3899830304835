import { useUserInfoLazyQuery } from '@/graphql/generated';
import { tryToGetToken } from '@/utils/auth';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface Auth {
    fetchUserInfo: () => void;
    isLoggedIn: boolean;
    userid: string;
    contractid: string;
    username: string;
    companyname: string;
    menu?: {
        menuid: string;
        canDownload: boolean;
        canStory: boolean;
        isTrial: boolean;
        contractType: string;
        termStart: string;
        termEnd: string;
        maxExtractSaves: number;
        count: number;
        maxCount: number;
    };
    menus: {
        menuid: string;
        canDownload: boolean;
        isTrial: boolean;
        contractType: string;
        termStart: string;
        termEnd: string;
        maxExtractSaves: number;
        count: number;
        maxCount: number;
    }[];
    contract_exservice: { exservice: string }[];
}

export const AuthContext = React.createContext<Auth>(undefined!);
export const AuthProvider: React.FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const location = useLocation();
    const [value, setValue] = useState<Auth>();

    const [fetchUserInfo] = useUserInfoLazyQuery({
        onCompleted(data) {
            if (!data.user) {
                tryToGetToken();
                return;
            }

            const menu = data.user.menus.find((n) => n.menuid === 'browsingstory');

            setValue({
                fetchUserInfo,
                isLoggedIn: true,
                userid: data.user.userid,
                contractid: data.user.contractid,
                username: data.user.username,
                companyname: data.user.companyname,
                menu,
                menus: data.user.menus,
                contract_exservice: data.user.contract_exservice
            });
        },
        onError() {
            tryToGetToken();
        }
    });

    useEffect(() => {
        fetchUserInfo();
    }, [location, fetchUserInfo]);

    if (!value) {
        return <></>;
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
