export const JOB: {
    [s: string]: string;
} = {
    '1': '公務員・教職員･団体職員', '2': '会社勤務（一般社員）',
    '3': '会社勤務（管理職）', '4': '会社経営（経営者・役員）',
    '5': '派遣社員・契約社員', '6': 'パート・アルバイト',
    '7': 'フリーランス・SOHO', '8': '自営業（商工・サービス）',
    '9': '専門職（弁護士・税理士等・医療関連）', '10': '農林漁業',
    '11': '専業主婦（主夫）', '12': '学生',
    '13': '無職・定年退職・家事手伝い', '14': 'その他の職業'
};