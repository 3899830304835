import request from "./request";

export function logger({
    url,
    action,
    extractid,
    functionid,
    actionParam
}: {
    url: string;
    action: string;
    extractid?: number | string;
    functionid?: string;
    actionParam?: any;
}) {
    request.post('/log', {
        url,
        action,
        extractid,
        functionid,
        actionParam
    });
}