import client from '@/apollo';
import { bluePalette2, geekblue, red } from '@/chartTheme';
import { DashboardCard } from '@/components/DashboardCard';
import { SearchWordsDocument, SearchWordsQueryResult, useWordsQuery } from '@/graphql/generated';
import { CategoryColumnWithType, ChartOptionUnion, RaccoonChart as RCClass, ValueColumnWithType } from 'raccoon-chart';
import React from 'react';
import { useExtractParam } from '../../Context';

const wordColumn: CategoryColumnWithType = {
    type: 'category',
    key: 'word',
    title: 'ワード'
};

const femaleRateColumn: ValueColumnWithType = {
    type: 'value',
    key: 'femaleRate',
    title: '性別値',
    min: 0,
    max: 1,
    decimal: true,
    formatter: (n) => (n == null ? '-' : n.toFixed(3)),
    axisFormatter: (n) => (n == null ? '-' : n.toFixed(2))
};

const ageColumn: ValueColumnWithType = {
    type: 'value',
    key: 'age',
    title: '年齢',
    formatter: (n) => {
        if (n == null) return '-';
        return n.toFixed(1);
    },
    axisFormatter: (n) => {
        if (n == null) return '-';
        return n.toFixed(0);
    }
};

const usersColumn: ValueColumnWithType = {
    type: 'value',
    key: 'value',
    title: 'リーチ率',
    formatter: (value) => {
        if (value == null) return '-';
        return `${Math.exp(value).toFixed(1)} %`;
    },
    axisFormatter: (value) => {
        if (value == null) return '-';
        return `${Math.exp(value).toFixed(0)} %`;
    }
};

export const WordCloudCard: React.FC<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useWordsQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart: ChartOptionUnion = React.useMemo(() => {
        return {
            data: [],
            focusable: false,
            type: 'wordCloud',
            dataColumns: [wordColumn, femaleRateColumn, ageColumn, usersColumn],
            textColumn: wordColumn,
            sizeColumn: usersColumn,
            sizeRange: [12, 32],
            colorColumn: {
                type: 'value',
                key: 'index'
            },
            legend: { show: false, position: 'bottom' },
            color: {
                colors: [...bluePalette2].reverse()
            },
            tooltip: {
                trigger: 'focus',
                async html(instance: RCClass) {
                    const { get, chartStore } = instance;
                    if (chartStore?.type !== 'wordCloud') {
                        return;
                    }

                    const { data, activeDataIndex } = chartStore;
                    const activeData = data[get<any>(activeDataIndex)];

                    if (activeData) {
                        return client
                            .query<SearchWordsQueryResult['data']>({
                                query: SearchWordsDocument,
                                variables: {
                                    extractid,
                                    word: activeData.text
                                }
                            })
                            .then(
                                ({ data }) => `
                                    <div class="vls-tooltip">
                                        <div class="word">${activeData.text}: ${activeData.usersRate.toFixed(1)} %</div>
                                        ${data?.extract?.searchWords
                                            .slice(0, 5)
                                            .map(
                                                ({ searchWord, users }) =>
                                                    `<div class="row">${searchWord} (${users})</div>`
                                            )
                                            .join('\n')}
                                    </div>
                                `
                            );
                    }
                }
            }
        };
    }, [extractid]);

    const _data = React.useMemo(() => {
        if (!data?.extract?.words) {
            return [];
        }

        const words = [...data.extract.words].sort((a, b) => b.usersRate - a.usersRate).splice(0, 100);

        return words.map(({ word, usersRate, femaleRate, age }, i) => ({
            index: i,
            word,
            value: Math.log(usersRate),
            usersRate,
            femaleRate,
            age
        }));
    }, [data]);

    return (
        <DashboardCard
            data={_data}
            error={error}
            chartOption={chart}
            chartHeight={400}
            size="small"
            loading={loading}
            title="検索キーワード"
        />
    );
};

export const DemographicMapCard: React.FC<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useWordsQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart: ChartOptionUnion = React.useMemo(() => {
        return {
            data: [],
            focusable: false,
            type: 'scatter',
            sizeRange: [4, 16],
            textColumn: wordColumn,
            xColumn: femaleRateColumn,
            yColumn: ageColumn,
            sizeColumn: usersColumn,
            colorColumn: femaleRateColumn,
            dataColumns: [wordColumn, femaleRateColumn, ageColumn, usersColumn],
            xAxis: { showTitle: true },
            yAxis: { showTitle: true },
            legend: { show: true, position: 'bottom' },
            color: {
                colors: [geekblue, red]
            }
        };
    }, []);

    const _data = React.useMemo(() => {
        if (!data?.extract?.words) {
            return [];
        }

        const words = [...data.extract.words].sort((a, b) => b.usersRate - a.usersRate).splice(0, 100);

        return words.map(({ word, usersRate, femaleRate, age }, i) => ({
            index: i,
            word,
            value: Math.log(usersRate),
            usersRate,
            femaleRate,
            age
        }));
    }, [data]);

    return (
        <DashboardCard
            data={_data}
            error={error}
            chartOption={chart}
            chartHeight={400}
            size="small"
            loading={loading}
            title="属性別マップ"
        />
    );
};
