function formatter(value: number | null | undefined) {
    if (value == null) {
        return '-';
    }

    return `${value.toFixed(1)} %`;
}

function axisFormatter(value: number | null | undefined) {
    if (value == null) {
        return '-';
    }

    return `${value.toFixed(0)} %`;
}

export const percentFormatters = { formatter, axisFormatter };
