import axios from 'axios';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const request = axios.create({
    baseURL: '/',
    headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
    }
});

export const useRequest = function <T>(url: string) {
    const requestId = React.useRef<string | null>(null);
    const abortController = React.useRef<AbortController | null>(null);

    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<T>();
    const [error, setError] = React.useState<any>();

    React.useEffect(() => {
        return () => {
            if (abortController.current) {
                abortController.current.abort();
                request.post('/api/cancel', { requestId });
            }
        };
    }, []);

    const api = React.useCallback(
        async (params: Record<string, any>) => {
            if (abortController.current) {
                abortController.current.abort();
                request.post('/api/cancel', { requestId: requestId.current });
            }

            const currentId = (requestId.current = uuidv4());

            abortController.current = new AbortController();
            setLoading(true);

            try {
                const { data } = await request.post<T>(
                    url,
                    {
                        ...params,
                        requestId: currentId
                    },
                    {
                        signal: abortController.current.signal
                    }
                );

                if (currentId === requestId.current) {
                    abortController.current = null;
                    setData(data);
                    setLoading(false);
                    setError(undefined);
                }

                return data;
            } catch (error) {
                if (currentId === requestId.current) {
                    abortController.current = null;
                    setData(undefined);
                    setLoading(false);
                    setError(error);
                }

                throw error;
            }
        },
        [url]
    );

    return { loading, data, error, api };
};

export default useRequest;
