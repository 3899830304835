import { Col, Row } from 'antd';
import React from 'react';
import { Filter } from '../FilterModal';
import { AppCard } from './AppCard';
import { AgeScoreCard, GenderScoreCard, TrendScoreCard, UserCountScoreCard } from './ScoreCards';
import { SiteCard } from './SiteCard';
import { TimeCard } from './TimeCard';
import { TrendCard } from './TrendCard';
import { DemographicMapCard, WordCloudCard } from './WordCloudCard';

export const SummaryPage: React.FunctionComponent<{}> = () => {
    return (
        <div className="slim-scroll" style={{ height: '100%' }}>
            <div style={{ padding: 12 }}>
                <div style={{ marginBottom: 12 }}>
                    <Filter />
                </div>
                <Row gutter={12} style={{ marginBottom: 12 }}>
                    <Col span={6}>
                        <UserCountScoreCard />
                    </Col>
                    <Col span={6}>
                        <TrendScoreCard />
                    </Col>
                    <Col span={6}>
                        <GenderScoreCard />
                    </Col>
                    <Col span={6}>
                        <AgeScoreCard />
                    </Col>
                </Row>
                <Row gutter={12} style={{ marginBottom: 12 }}>
                    <Col span={18}>
                        <TrendCard />
                    </Col>
                    <Col span={6}>
                        <TimeCard />
                    </Col>
                </Row>
                <Row gutter={12} style={{ marginBottom: 12 }}>
                    <Col span={12}>
                        <WordCloudCard />
                    </Col>
                    <Col span={12}>
                        <DemographicMapCard />
                    </Col>
                </Row>
                {/* <Row gutter={12} style={{ marginBottom: 12 }}>
                    <Col span={24}>
                        <HitCountCard />
                    </Col>
                </Row> */}

                <Row gutter={12} style={{ marginBottom: 12 }}>
                    <Col span={12}>
                        <SiteCard />
                    </Col>
                    <Col span={12}>
                        <AppCard />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default SummaryPage;
