import { ItemSearch } from '@/components/AmazonItemSearch';
import { ItemSearchModal } from '@/components/AmazonItemSearchModal';
import { textColorSecondary } from '@/theme';
import useRequest from '@/utils/useRequest';
import { MinusOutlined } from '@ant-design/icons';
import { Alert, AutoComplete, Button, Col, Divider, Form, Modal, Row, Select, Table, TreeSelect } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import { format } from 'd3-format';
import React from 'react';
import type { Mode } from './index';

const { Option } = Select;

interface Props {
    disabled?: boolean;
    value?: string;
    onChange?: (value: string) => void;
}

export const ItemAutoComplete: React.FC<Props> = ({ disabled, value, onChange }) => {
    const { data, loading, api } = useRequest<any[]>('/api/itemNames');
    const counter = React.useRef<number>(0);

    React.useEffect(() => {
        if (!value) {
            return;
        }

        const id = ++counter.current;
        setTimeout(() => {
            if (id !== counter.current) {
                return;
            }

            api({
                pattern: value
            });
        }, 500);
    }, [value, api]);

    return (
        <AutoComplete value={value} disabled={disabled} onChange={onChange}>
            {loading && (
                <AutoComplete.Option value={''} disabled>
                    Loading...
                </AutoComplete.Option>
            )}
            {data?.map((item) => {
                return <AutoComplete.Option value={item.itemname ?? ''}>{item.itemname}</AutoComplete.Option>;
            })}
        </AutoComplete>
    );
};

export const BrandAutoComplete: React.FC<Props> = ({ disabled, value, onChange }) => {
    const { data, loading, api } = useRequest<any[]>('/api/brands');

    const counter = React.useRef<number>(0);

    React.useEffect(() => {
        if (!value) {
            return;
        }

        const id = ++counter.current;
        setTimeout(() => {
            if (id !== counter.current) {
                return;
            }

            api({
                pattern: value
            });
        }, 500);
    }, [value, api]);

    return (
        <AutoComplete value={value} disabled={disabled} onChange={onChange}>
            {loading && (
                <AutoComplete.Option value={''} disabled>
                    Loading...
                </AutoComplete.Option>
            )}
            {data?.map((n) => {
                return (
                    <AutoComplete.Option value={n.brand ?? ''} disabled>
                        {n.brand}
                    </AutoComplete.Option>
                );
            })}
        </AutoComplete>
    );
};

export const CategoryAutoComplete: React.FC<
    Props & {
        matchType: 'LEVEL' | 'PARTIAL';
    }
> = ({ matchType, disabled, value, onChange }) => {
    const [editValue, setEditValue] = React.useState('');
    const { data, loading, api } = useRequest<any[]>('/api/categories');
    const counter = React.useRef<number>(0);

    React.useEffect(() => {
        setEditValue(value || '');
    }, []);

    React.useEffect(() => {
        if (matchType === 'LEVEL') {
            if (!editValue) {
                return;
            }

            const id = ++counter.current;
            setTimeout(() => {
                if (id !== counter.current) {
                    return;
                }

                api({
                    matchType,
                    pattern: editValue
                });
            }, 500);
        }
    }, [editValue, matchType, api]);

    React.useEffect(() => {
        if (matchType !== 'PARTIAL') {
            return;
        }

        if (!value) {
            return;
        }

        const id = ++counter.current;
        setTimeout(() => {
            if (id !== counter.current) {
                return;
            }

            api({
                matchType,
                pattern: value
            });
        }, 500);
    }, [value, matchType, api]);

    const treeData = React.useMemo(() => {
        if (!data) {
            return [];
        }

        const obj: any = {};

        data.forEach((n) => {
            let a = obj;
            if (n.cat1) {
                a = a[n.cat1] = a[n.cat1] ?? {};
            }

            if (n.cat2) {
                a = a[n.cat2] = a[n.cat2] ?? {};
            }

            if (n.cat3) {
                a = a[n.cat3] = a[n.cat3] ?? {};
            }

            if (n.cat4) {
                a = a[n.cat4] = a[n.cat4] ?? {};
            }

            if (n.cat5) {
                a = a[n.cat5] = a[n.cat5] ?? {};
            }

            if (n.cat6) {
                a = a[n.cat6] = a[n.cat6] ?? {};
            }

            if (n.cat7) {
                a = a[n.cat7] = a[n.cat7] ?? {};
            }
        });

        function nest(keys: string[], obj: any): any[] {
            if (obj) {
                return Object.keys(obj).map((key) => {
                    return {
                        key: [...keys, key].join(' > '),
                        value: [...keys, key].join(' > '),
                        title: key,
                        children: nest([...keys, key], obj[key])
                    };
                });
            }
            return [];
        }

        return nest([], obj);
    }, [data]);

    if (matchType === 'LEVEL') {
        return (
            <TreeSelect
                loading={loading}
                disabled={disabled}
                treeNodeLabelProp="value"
                // treeExpandedKeys={data?.categories.map((n) =>
                //     [n.cat1, n.cat2, n.cat3, n.cat4, n.cat5, n.cat6, n.cat7].filter((n) => n).join(' > ')
                // )}
                treeDefaultExpandAll
                showSearch
                treeData={treeData}
                value={value}
                onSearch={(value) => {
                    setEditValue(value);
                }}
                onChange={(value) => {
                    if (onChange) {
                        onChange(value);
                    }
                    setEditValue(value);
                }}
            />
        );
    }

    return (
        <AutoComplete value={value} disabled={disabled} onChange={onChange}>
            {loading && (
                <AutoComplete.Option value={''} disabled>
                    Loading...
                </AutoComplete.Option>
            )}
            {data?.map((n, i) => {
                return (
                    <AutoComplete.Option
                        key={i}
                        value={
                            [n.cat1, n.cat2, n.cat3, n.cat4, n.cat5, n.cat6, n.cat7].filter((n) => n).join(' > ') ?? ''
                        }
                        disabled
                    >
                        {n.brand}
                    </AutoComplete.Option>
                );
            })}
        </AutoComplete>
    );
};

const ItemOr: React.FunctionComponent<{
    itemConditionType: 'asin' | 'itemName' | 'category' | 'brand';
    logType: 'browser' | 'app' | 'item';
    mode: Mode;
    exclude?: boolean;
    form: React.RefObject<FormInstance<any>>;
    conditionField: FormListFieldData;
    conditionIndex: number;
    conditionAdd: FormListOperation['add'];
    conditionRemove: FormListOperation['remove'];
    andFields: FormListFieldData[];
    andField: FormListFieldData;
    andIndex: number;
    andAdd: FormListOperation['add'];
    andRemove: FormListOperation['remove'];
    orFields: FormListFieldData[];
    orField: FormListFieldData;
    orIndex: number;
    orAdd: FormListOperation['add'];
    orRemove: FormListOperation['remove'];
}> = ({
    itemConditionType,
    logType,
    mode,
    exclude,
    form,
    conditionField,
    conditionIndex,
    conditionAdd,
    conditionRemove,
    andFields,
    andField,
    andIndex,
    andAdd,
    andRemove,
    orFields,
    orField,
    orIndex,
    orAdd,
    orRemove
}) => {
    const ButtonGroups = () => {
        return (
            <Col span={6}>
                <Button.Group>
                    <Button
                        disabled={
                            mode === 'read' || mode === 'update' || (andFields.length === 1 && orFields.length === 1)
                        }
                        onClick={() => {
                            if (orFields.length === 1) {
                                andRemove(andField.name);
                            } else {
                                orRemove(orField.name);
                            }
                        }}
                    >
                        <MinusOutlined />
                    </Button>
                    <Button
                        disabled={mode === 'read' || mode === 'update'}
                        onClick={() => {
                            if (orFields.length < 5) {
                                orAdd();
                            }
                        }}
                    >
                        OR
                    </Button>
                    <Button
                        disabled={mode === 'read' || mode === 'update'}
                        onClick={() => {
                            if (andFields.length < 5) {
                                andAdd();
                            }
                        }}
                    >
                        AND
                    </Button>
                </Button.Group>
            </Col>
        );
    };

    if (itemConditionType === 'asin') {
        return (
            <div style={{ marginLeft: 12 }}>
                {orIndex !== 0 && <Divider>OR</Divider>}
                <Row gutter={12}>
                    <Col span={18}>
                        <Form.Item
                            name={[orField.name, 'value']}
                            initialValue=""
                            rules={[
                                { required: true, message: '必須です。' }
                                // {
                                //     validator: async (rule, value) => {
                                //         if (logType === "item" && !value) {
                                //             return Promise.reject("必須です。");
                                //         }
                                //     },
                                // },
                            ]}
                        >
                            <ItemSearch
                                onDelete={() => {
                                    // orRemove(orIndex);
                                }}
                                disabled={mode === 'read' || mode === 'update'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <ButtonGroups />
                    </Col>
                </Row>
            </div>
        );
    }

    return (
        <div style={{ marginLeft: 12 }}>
            {orIndex !== 0 && <Divider>OR</Divider>}
            <Row gutter={12}>
                <Col span={6}>
                    <Form.Item
                        dependencies={[
                            [
                                exclude ? 'excludeConditions' : 'conditions',
                                conditionField.name,
                                'itemAnds',
                                andField.name,
                                'field'
                            ]
                        ]}
                    >
                        {({ getFieldsValue }) => {
                            const code = exclude ? 'excludeConditions' : 'conditions';
                            const value = getFieldsValue();
                            const field = value?.[code]?.[conditionField.name]?.['itemAnds']?.[andField.name]?.field;

                            return (
                                <Form.Item
                                    noStyle
                                    {...orField}
                                    name={[orField.name, 'matchType']}
                                    initialValue="PARTIAL"
                                >
                                    <Select disabled={mode === 'read' || mode === 'update'}>
                                        {field !== 'category' && <Option value="EXACT">完全一致</Option>}
                                        <Option value="PARTIAL">部分一致</Option>
                                        {field === 'category' && <Option value="LEVEL">階層一致</Option>}
                                    </Select>
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <ButtonGroups />
                </Col>
                <Col span={24}>
                    <Form.Item
                        noStyle
                        dependencies={[
                            [
                                exclude ? 'excludeConditions' : 'conditions',
                                conditionField.name,
                                'itemAnds',
                                andField.name,
                                'field'
                            ]
                        ]}
                    >
                        {({ getFieldsValue }) => {
                            const code = exclude ? 'excludeConditions' : 'conditions';
                            const value = getFieldsValue();
                            const field = value?.[code]?.[conditionField.name]?.['itemAnds']?.[andField.name]?.field;

                            if (field === 'itemName') {
                                return (
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: '必須です。'
                                            }
                                        ]}
                                        {...orField}
                                        name={[orField.name, 'value']}
                                    >
                                        <ItemAutoComplete disabled={mode === 'read' || mode === 'update'} />
                                    </Form.Item>
                                );
                            }

                            if (field === 'brand') {
                                return (
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: '必須です。'
                                            }
                                        ]}
                                        {...orField}
                                        name={[orField.name, 'value']}
                                    >
                                        <BrandAutoComplete disabled={mode === 'read' || mode === 'update'} />
                                    </Form.Item>
                                );
                            }

                            if (field === 'category') {
                                return (
                                    <Form.Item
                                        noStyle
                                        dependencies={[
                                            [
                                                exclude ? 'excludeConditions' : 'conditions',
                                                conditionField.name,
                                                'itemAnds',
                                                andField.name,
                                                'itemOrs',
                                                orField.name,
                                                'matchType'
                                            ]
                                        ]}
                                    >
                                        {({ getFieldsValue }) => {
                                            const code = exclude ? 'excludeConditions' : 'conditions';
                                            const value = getFieldsValue();
                                            const matchType =
                                                value?.[code]?.[conditionField.name]?.['itemAnds']?.[andField.name]?.[
                                                    'itemOrs'
                                                ]?.[orField.name]?.['matchType'];
                                            return (
                                                <Form.Item
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '必須です。'
                                                        }
                                                    ]}
                                                    {...orField}
                                                    name={[orField.name, 'value']}
                                                >
                                                    <CategoryAutoComplete
                                                        matchType={matchType}
                                                        disabled={mode === 'read' || mode === 'update'}
                                                    />
                                                </Form.Item>
                                            );
                                        }}
                                    </Form.Item>
                                );
                            }
                        }}
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};

const ItemAnd: React.FunctionComponent<{
    logType: 'browser' | 'app' | 'item';
    mode: Mode;
    exclude?: boolean;
    form: React.RefObject<FormInstance<any>>;
    conditionField: FormListFieldData;
    conditionIndex: number;
    conditionAdd: FormListOperation['add'];
    conditionRemove: FormListOperation['remove'];
    andFields: FormListFieldData[];
    andField: FormListFieldData;
    andIndex: number;
    andAdd: FormListOperation['add'];
    andRemove: FormListOperation['remove'];
}> = ({
    logType,
    mode,
    exclude,
    form,
    conditionField,
    conditionIndex,
    conditionAdd,
    conditionRemove,
    andFields,
    andField,
    andIndex,
    andAdd,
    andRemove
}) => {
    const [visible, setVisible] = React.useState(false);
    return (
        <Form.Item noStyle>
            {andIndex !== 0 && <Divider>AND</Divider>}
            <Row gutter={12}>
                <Col span={8}>
                    <Form.Item {...andField} name={[andField.name, 'field']} initialValue={'asin'}>
                        <Select disabled={mode === 'read' || mode === 'update'}>
                            <Option value="asin">商品指定</Option>
                            <Option value="itemName">商品名</Option>
                            <Option value="brand">ブランド名</Option>
                            <Option value="category">カテゴリ</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item {...andField} name={[andField.name, 'inclexcl']} initialValue="INCLUDE">
                        <Select disabled={andIndex === 0 || mode === 'read' || mode === 'update'}>
                            <Option value="INCLUDE">含める</Option>
                            <Option value="EXCLUDE">除外する</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                noStyle
                dependencies={[
                    [
                        exclude ? 'excludeConditions' : 'conditions',
                        conditionField.name,
                        'itemAnds',
                        andField.name,
                        'field'
                    ]
                ]}
            >
                {({ getFieldsValue, setFieldsValue, setFields }) => {
                    return (
                        <Form.List initialValue={[{}]} name={[andField.name, 'itemOrs']}>
                            {(orFields, { add: orAdd, remove: orRemove }) => (
                                <>
                                    {mode === 'create' && (
                                        <Form.Item
                                            noStyle
                                            dependencies={[
                                                [
                                                    exclude ? 'excludeConditions' : 'conditions',
                                                    conditionField.name,
                                                    'itemAnds',
                                                    andField.name,
                                                    'field'
                                                ]
                                            ]}
                                        >
                                            {({ getFieldsValue, setFieldsValue }) => {
                                                const code = exclude ? 'excludeConditions' : 'conditions';
                                                const value = getFieldsValue();
                                                const itemConditionType =
                                                    value?.[code]?.[conditionField.name]?.['itemAnds']?.[andField.name]
                                                        ?.field;

                                                if (itemConditionType !== 'asin') {
                                                    return <></>;
                                                }

                                                return (
                                                    <div
                                                        style={{
                                                            marginLeft: 12,
                                                            marginBottom: 12
                                                        }}
                                                    >
                                                        <ItemSearchModal
                                                            visible={visible}
                                                            setVisible={setVisible}
                                                            onOpen={(setItems) => {
                                                                const value = getFieldsValue();
                                                                const items: any[] =
                                                                    value?.[code]?.[conditionField.name]?.[
                                                                        'itemAnds'
                                                                    ]?.[andField.name]?.['itemOrs'] ?? [];
                                                                setItems(items.map((n) => n.value).filter((n) => n));
                                                            }}
                                                            onClose={(items) => {
                                                                // console.log(fields);
                                                                const fields: any[] =
                                                                    value?.[code]?.[conditionField.name]?.[
                                                                        'itemAnds'
                                                                    ]?.[andField.name]?.['itemOrs'];
                                                                fields.forEach((field, i) => {
                                                                    orRemove(0);
                                                                });

                                                                items.forEach((item) => {
                                                                    orAdd({
                                                                        value: item
                                                                    });
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </Form.Item>
                                    )}
                                    {orFields.map((orField, orIndex) => (
                                        <>
                                            <Form.Item
                                                key={orIndex}
                                                noStyle
                                                dependencies={[
                                                    [
                                                        exclude ? 'excludeConditions' : 'conditions',
                                                        conditionField.name,
                                                        'itemAnds',
                                                        andField.name,
                                                        'field'
                                                    ]
                                                ]}
                                            >
                                                {({ getFieldsValue, setFieldsValue }) => {
                                                    const code = exclude ? 'excludeConditions' : 'conditions';
                                                    const value = getFieldsValue();
                                                    const itemConditionType =
                                                        value?.[code]?.[conditionField.name]?.['itemAnds']?.[
                                                            andField.name
                                                        ]?.field;
                                                    return (
                                                        <ItemOr
                                                            key={orIndex}
                                                            {...{
                                                                itemConditionType,
                                                                logType,
                                                                mode,
                                                                exclude,
                                                                form,
                                                                conditionField,
                                                                conditionIndex,
                                                                conditionAdd,
                                                                conditionRemove,
                                                                andFields,
                                                                andField,
                                                                andIndex,
                                                                andAdd,
                                                                andRemove,
                                                                orFields,
                                                                orField,
                                                                orIndex,
                                                                orAdd,
                                                                orRemove
                                                            }}
                                                        />
                                                    );
                                                }}
                                            </Form.Item>
                                        </>
                                    ))}
                                </>
                            )}
                        </Form.List>
                    );
                }}
            </Form.Item>
        </Form.Item>
    );
};

export const ItemCard: React.FunctionComponent<{
    logType: 'browser' | 'app' | 'item';
    mode: Mode;
    exclude?: boolean;
    form: React.RefObject<FormInstance<any>>;
    conditionFields: FormListFieldData[];
    conditionField: FormListFieldData;
    conditionIndex: number;
    conditionAdd: FormListOperation['add'];
    conditionRemove: FormListOperation['remove'];
}> = ({
    logType,
    mode,
    exclude,
    form,
    conditionFields,
    conditionField,
    conditionIndex,
    conditionAdd,
    conditionRemove
}) => {
    const { data, loading, api } = useRequest<any[]>('/api/testCondition');
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        if (data) {
            setVisible(true);
        }
    }, [data]);

    return (
        <div>
            <Modal
                width={1200}
                title="対象商品"
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                }}
                okText="OK"
                cancelText="キャンセル"
                footer={null}
            >
                <div>
                    <Alert message={'商品の並びはアクセス数降順です。'} />
                    <Table
                        scroll={{ y: 400 }}
                        pagination={false}
                        tableLayout="fixed"
                        loading={loading}
                        dataSource={data || []}
                        columns={[
                            {
                                key: 'itemname',
                                dataIndex: 'itemname',
                                title: '商品名',
                                render: (value, record) => {
                                    return (
                                        <div
                                            style={{
                                                overflow: 'hidden',
                                                width: '100%'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    color: textColorSecondary,
                                                    fontSize: 12
                                                }}
                                            >
                                                {[record.cat1, record.cat2, record.cat3].filter((n) => n).join(' > ')}
                                            </div>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={`https://amazon.co.jp/dp/${record.asin}`}
                                                >
                                                    {value}
                                                </a>
                                            </div>
                                        </div>
                                    );
                                }
                            },
                            {
                                key: 'brand',
                                dataIndex: 'brand',
                                title: 'ブランド',
                                width: 240
                            },
                            {
                                key: 'listPrice',
                                dataIndex: 'listPrice',
                                title: '価格',
                                align: 'right',
                                width: 160,
                                render(value) {
                                    if (value > 0) {
                                        return <span>￥{format(',')(value)}</span>;
                                    }

                                    return <span>-</span>;
                                }
                            }
                        ]}
                    />
                </div>
            </Modal>
            {mode === 'create' && (
                <Button
                    style={{ marginBottom: 12 }}
                    onClick={() => {
                        const code = exclude ? 'excludeConditions' : 'conditions';
                        const values = form.current?.getFieldsValue();
                        const { startYm, endYm } = values;
                        api({
                            startYm,
                            endYm,
                            condition: values?.[code]?.[conditionField.name]
                        });
                    }}
                    loading={loading}
                >
                    対象商品を確認
                </Button>
            )}
            <Form.List initialValue={[{}]} name={[conditionField.name, 'itemAnds']}>
                {(andFields, { add: andAdd, remove: andRemove }) => (
                    <>
                        {andFields.map((andField, andIndex) => (
                            <>
                                <ItemAnd
                                    key={andIndex}
                                    {...{
                                        logType,
                                        mode,
                                        exclude,
                                        form,
                                        conditionField,
                                        conditionIndex,
                                        conditionAdd,
                                        conditionRemove,
                                        andFields,
                                        andField,
                                        andIndex,
                                        andAdd,
                                        andRemove
                                    }}
                                />
                            </>
                        ))}
                    </>
                )}
            </Form.List>
        </div>
    );
};
