export function downloadFile({
    headers,
    data,
    fileName
}: {
    headers: string[];
    data: (string | number | null | undefined)[][];
    fileName: string;
}) {
    const text = [
        headers.join('\t'),
        ...data.map((n) => n.join('\t'))
    ].join('\n');

    const blob = new Blob([text], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.txt`;
    link.click();
}