import { bluePalette2 } from '@/chartTheme';
import { RaccoonChart } from '@/components/RaccoonChart';
import { useGeneralWordsQuery } from '@/graphql/generated';
import { useExtractParam } from '@/pages/Extract/Context';
import { percentFormatters } from '@/server/utils/chartUtils';
import { Switch } from 'antd';
import React from 'react';

export const ExpandRowWords: React.FC<{
    site?: string;
    word?: string;
    app?: string;
}> = ({ site, word, app }) => {
    const { extractid, filter, clusterid } = useExtractParam();
    const [split, setSplit] = React.useState<boolean>(true);

    const { data, loading, error } = useGeneralWordsQuery({
        variables: {
            extractid,
            site,
            word,
            split,
            filter,
            clusterid
        }
    });

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <Switch checked={split} onChange={(checked) => setSplit(checked)} />
                <span style={{ marginLeft: 8 }}>単語分割</span>
            </div>
            <RaccoonChart
                loading={loading}
                height={300}
                data={data?.extract?.general.words?.map((n: any, index: number) => ({
                    ...n,
                    index,
                    name: '',
                    usersRate: n.usersRate * 100
                }))}
                chartOption={{
                    type: 'wordCloud',
                    textColumn: {
                        key: 'word',
                        title: 'ワード'
                    },
                    data: [],
                    sizeColumn: {
                        key: 'usersRate',
                        title: 'リーチ率',
                        ...percentFormatters
                    },
                    colorColumn: {
                        type: 'value',
                        key: 'index'
                    },
                    tooltip: {
                        trigger: 'hover'
                    },
                    legend: { show: false, position: 'bottom' },
                    color: {
                        colors: [...bluePalette2].reverse()
                        // colors: [
                        //     '#B8E1FF',
                        //     '#9AC5FF',
                        //     '#7DAAFF',
                        //     '#5B8FF9',
                        //     '#3D76DD',
                        //     '#085EC0',
                        //     '#0047A5',
                        //     '#00318A',
                        //     '#001D70'
                        // ].reverse()
                    }
                }}
            />
        </div>
    );
};
