import React, { useCallback, useState } from 'react';
import styles from './index.module.less';

const Favicon: React.FunctionComponent<{
    site?: string;
    style?: React.CSSProperties;
}> = ({ site, style = {} }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const onLoad = useCallback(() => {
        setLoading(false);
    }, []);

    const onError = useCallback(() => {
        setLoading(false);
        setError(true);
    }, []);

    return (
        <div className={styles.faviconWrap} style={style}>
            {loading && <div className={styles.empty} />}
            <div style={{ display: loading ? 'none' : 'inline-block' }}>
                {error ? (
                    <div className={styles.empty} />
                ) : (
                    <img
                        key={site}
                        className={styles.favicon}
                        alt={site}
                        src={`${process.env.REACT_APP_TURTLE_URL}/icon?site=${site}`}
                        onLoad={onLoad}
                        onError={onError}
                    />
                )}
            </div>
        </div>
    );
};

export default Favicon;
