import { DashboardCard } from '@/components/DashboardCard';
import { useBeforeAfterAppQuery } from '@/graphql/generated';
import { primaryColor } from '@/theme';
import { CaretRightOutlined } from '@ant-design/icons';
import { CategoryColumnWithType, ChartOptionUnion } from 'raccoon-chart';
import React from 'react';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';

const columns = [
    {
        type: 'simple' as 'simple',
        width: 40,
        key: 'index',
        title: '#',
        align: 'center' as 'center',
        render: (row: any, rowIndex: number) => {
            return `${rowIndex + 1}.`;
        }
    },
    {
        type: 'simple' as 'simple',
        key: 'app',
        title: 'アプリ',
        align: 'left' as 'left',
        render: (row: any) => {
            return row.gpname || row.applabel;
        },
        render2: (row: any) => {
            return row.packagename;
        },
        color2: primaryColor,
        link2: (row: any) => `https://play.google.com/store/apps/details?id=${row.packagename}&hl=ja`
    },
    {
        type: 'simple' as 'simple',
        width: 72,
        key: 'usersRate',
        title: 'リーチ率',
        align: 'right' as 'right',
        render: (row: { usersRate: number }) => {
            return row.usersRate.toPercent().toFixed(2) + ' %';
        }
    },
    // {
    //     type: 'simple' as 'simple',
    //     width: 72,
    //     key: 'appearRate',
    //     title: '出現率',
    //     align: 'right' as 'right',
    //     render: (row: { cnt: number }) => {
    //         return row.cnt.toPercent().toFixed(2) + ' %';
    //     }
    // },
    {
        type: 'simple' as 'simple',
        width: 72,
        key: 'score',
        title: 'スコア',
        align: 'right' as 'right',
        render: (row: { score: number }) => {
            return row.score.toPercent().toFixed(1) + ' pt';
        }
    }
];

const DataTable: React.FC<{
    beforeAfter: number;
}> = ({ beforeAfter }) => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useBeforeAfterAppQuery({
        variables: {
            extractid,
            filter,
            clusterid,
            beforeAfter,
            limit: 100
        }
    });

    const title = React.useMemo(() => {
        switch (beforeAfter) {
            case 1:
                return '前半';
            case 2:
                return '中間';
            case 3:
                return '後半';
        }
    }, [beforeAfter]);

    const chart = React.useMemo(() => {
        return {
            type: 'dataTable',
            data: [],
            dataColumns: [
                { type: 'category', key: 'packagename', title: 'アプリ' } as CategoryColumnWithType,
                {
                    type: 'value',
                    key: 'usersRate',
                    title: 'リーチ率',
                    formatter: (usersRate: number) => {
                        return usersRate.toPercent().toFixed(2) + ' %';
                    }
                },
                {
                    type: 'value',
                    key: 'score',
                    title: 'スコア',
                    formatter: (score: number) => {
                        return score.toPercent().toFixed(1) + ' pt';
                    }
                }
            ],
            columns
        } as ChartOptionUnion;
    }, []);

    return (
        <DashboardCard
            size="small"
            title={title}
            height={'100%'}
            loading={loading}
            data={data?.extract?.beforeAfterApp}
            error={error}
            chartOption={chart}
        />
    );
};

export const BeforeAfterAppPage: React.FunctionComponent<{}> = () => {
    return (
        <div style={{ height: '100%' }}>
            <div style={{ padding: 12, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: 12 }}>
                    <Filter />
                </div>

                <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                        <DataTable {...{ beforeAfter: 1 }} />
                    </div>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <CaretRightOutlined style={{ margin: 'auto', fontSize: 32 }} />
                    </div>
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                        <DataTable {...{ beforeAfter: 2 }} />
                    </div>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <CaretRightOutlined style={{ margin: 'auto', fontSize: 32 }} />
                    </div>
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                        <DataTable {...{ beforeAfter: 3 }} />
                    </div>
                </div>
            </div>
        </div>
    );
};
