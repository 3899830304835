import { Appicon } from '@/components/Appicon';
import useRequest from '@/utils/useRequest';
import { Select, Spin } from 'antd';
import React from 'react';
import styles from './index.module.less';

const { Option } = Select;

interface Props {
    disabled?: boolean;
    value?: string;
    onChange?: (value: string) => void;
}

export const AppSearch: React.FC<Props> = ({ disabled, value, onChange }) => {
    const { data, loading, api } = useRequest<any[]>('/api/app');

    const counter = React.useRef<number>(0);

    React.useEffect(() => {
        if (!value) {
            return;
        }

        api({
            value
        });
    }, [value, api]);

    const onSearch = React.useCallback(
        (pattern: any) => {
            if (!pattern) {
                return;
            }

            const id = ++counter.current;
            setTimeout(() => {
                if (id !== counter.current) {
                    return;
                }

                api({
                    pattern,
                    value
                });
            }, 500);
        },
        [value, api]
    );

    return (
        <Select
            size="middle"
            value={value}
            onChange={onChange}
            listItemHeight={40}
            loading={loading}
            showSearch
            placeholder="アプリ"
            disabled={disabled}
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={onSearch}
            allowClear
            notFoundContent={null}
        >
            {loading && (
                <div style={{ textAlign: 'center', pointerEvents: 'none' }}>
                    <Spin spinning={true} size="small" />
                </div>
            )}
            {data?.map((n) => {
                return (
                    <Option key={n.packagename} value={n.packagename}>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    margin: 'auto 8px auto 0'
                                }}
                            >
                                <Appicon app={n.packagename} />
                            </div>
                            <div style={{ lineHeight: 1.2, margin: 'auto 0' }}>
                                <div className={styles.gpname}>{n.gpname}</div>
                                <div className={styles.applabel}>{n.packagename}</div>
                            </div>
                        </div>
                    </Option>
                );
            })}
        </Select>
    );
};
