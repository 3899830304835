const DATA_202208 = {
    1: '国内旅行',
    2: '海外旅行',
    3: '読書・書籍',
    4: 'スポーツ観戦',
    5: 'マンガ',
    6: 'アニメ',
    7: '音楽鑑賞',
    8: '楽器演奏など自身が行う音楽',
    9: '映画',
    10: '舞台、演劇',
    11: 'テレビ番組',
    12: '動画共有サイト（YouTube、ニコニコ動画など）',
    13: '韓国ドラマ',
    14: 'ゲーム',
    15: 'レジャー施設、テーマパーク',
    16: 'イベント、コンサート',
    17: '競馬',
    18: 'パチンコ・パチスロ',
    19: '競馬以外の公営ギャンブル（競輪、オートレース、競艇など）',
    20: '写真、カメラ',
    21: 'アート、芸術',
    22: 'アイドル',
    23: '俳優・女優',
    24: '歌手・ミュージシャン',
    25: 'スポーツ選手',
    26: 'お笑い芸人',
    27: 'アナウンサー',
    28: 'モデル',
    29: '声優',
    30: 'アーティスト',
    31: 'その他芸能人・有名人',
    32: 'ビジネス関連',
    33: '資格取得、習い事',
    34: '就職、求人',
    35: '受験、学校',
    36: 'マネー、投資',
    37: '不動産投資',
    38: '電力自由化',
    39: '政治',
    40: '経済',
    41: 'ショッピング',
    42: '健康、医療、病気',
    43: 'スポーツ（ジョギング、ジムなど自身が行うスポーツ）',
    44: '釣り',
    45: '登山・トレッキング',
    46: 'キャンプ',
    47: 'アウトドア（釣り、キャンプ除く）',
    48: '車',
    49: 'バイク',
    50: '鉄道',
    51: '育児',
    52: '子どもの教育',
    53: '料理',
    54: 'ペット',
    55: '園芸、家庭菜園',
    56: 'インテリア',
    57: '住宅・エクステリア',
    58: '保険',
    59: 'グルメ、レストラン',
    60: 'お酒',
    61: 'パソコン',
    62: 'スマートフォン',
    63: 'デジタル機器',
    64: '腕時計',
    65: '占い',
    66: '恋愛',
    67: '結婚',
    68: '手芸・編み物',
    69: 'コスメ、化粧品',
    70: 'ヘアケア、ヘアサロン',
    71: 'ネイルケア、ネイルサロン',
    72: 'デンタルケア（歯並び、ホワイトニングなど）',
    73: 'ファッション',
    74: 'アクセサリー',
    75: 'ダイエット'
};

export const INTEREST: {
    [enqversion: number]: {
        [s: string]: string;
    };
} = {
    202404: DATA_202208,
    202307: DATA_202208,
    202304: DATA_202208,
    202208: DATA_202208,
    202207: {
        1: '国内旅行',
        2: '海外旅行',
        3: '読書・書籍',
        4: 'スポーツ観戦',
        5: 'マンガ',
        6: 'アニメ',
        7: '音楽鑑賞',
        8: '楽器演奏など自身が行う音楽',
        9: '映画',
        10: '舞台、演劇',
        11: 'テレビ番組',
        12: '動画共有サイト（YouTube、ニコニコ動画など）',
        13: '韓国ドラマ',
        14: 'ゲーム',
        15: 'レジャー施設、テーマパーク',
        16: 'イベント、コンサート',
        17: '競馬',
        18: 'パチンコ・パチスロ',
        19: '競馬以外の公営ギャンブル（競輪、オートレース、競艇など）',
        20: '写真、カメラ',
        21: 'アート、芸術',
        22: 'アイドル',
        23: '俳優・女優',
        24: '歌手・ミュージシャン',
        25: 'スポーツ選手',
        26: 'お笑い芸人',
        27: 'アナウンサー',
        28: 'モデル',
        29: '声優',
        30: 'アーティスト',
        31: 'その他芸能人・有名人',
        32: 'ビジネス関連',
        33: '資格取得、習い事',
        34: '就職、求人',
        35: '受験、学校',
        36: 'マネー、投資',
        37: '不動産投資',
        38: '電力自由化',
        39: '政治',
        40: '経済',
        41: 'ショッピング',
        42: '健康、医療、病気',
        43: 'スポーツ（ジョギング、ジムなど自身が行うスポーツ）',
        44: '釣り',
        45: '登山・トレッキング',
        46: 'キャンプ',
        47: 'アウトドア（釣り、キャンプ除く）',
        48: '車',
        49: 'バイク',
        50: '鉄道',
        51: '育児',
        52: '子どもの教育',
        53: '料理',
        54: 'ペット',
        55: '園芸、家庭菜園',
        56: 'インテリア',
        57: '住宅・エクステリア',
        58: '保険',
        59: 'グルメ、レストラン',
        60: 'お酒',
        61: 'パソコン',
        62: 'スマートフォン',
        63: 'デジタル機器',
        64: '腕時計',
        65: '占い',
        66: '恋愛',
        67: '結婚',
        68: '手芸・編み物',
        69: 'コスメ、化粧品',
        70: 'ヘアケア、ヘアサロン',
        71: 'ネイルケア、ネイルサロン',
        72: 'デンタルケア（歯並び、ホワイトニングなど）',
        73: 'ファッション',
        74: 'アクセサリー',
        75: 'ダイエット'
    },
    202204: {
        1: '国内旅行',
        2: '海外旅行',
        3: '読書・書籍',
        4: 'スポーツ観戦',
        5: 'マンガ',
        6: 'アニメ',
        7: '音楽鑑賞',
        8: '楽器演奏など自身が行う音楽',
        9: '映画',
        10: '舞台、演劇',
        11: 'テレビ番組',
        12: '動画共有サイト（YouTube、ニコニコ動画など）',
        13: '韓国ドラマ',
        14: 'ゲーム',
        15: 'レジャー施設、テーマパーク',
        16: 'イベント、コンサート',
        17: '競馬',
        18: 'パチンコ・パチスロ',
        19: '競馬以外の公営ギャンブル（競輪、オートレース、競艇など）',
        20: '写真、カメラ',
        21: 'アート、芸術',
        22: 'アイドル',
        23: '俳優・女優',
        24: '歌手・ミュージシャン',
        25: 'スポーツ選手',
        26: 'お笑い芸人',
        27: 'アナウンサー',
        28: 'モデル',
        29: '声優',
        30: 'アーティスト',
        31: 'その他芸能人・有名人',
        32: 'ビジネス関連',
        33: '資格取得、習い事',
        34: '就職、求人',
        35: '受験、学校',
        36: 'マネー、投資',
        37: '不動産投資',
        38: '電力自由化',
        39: '政治',
        40: '経済',
        41: 'ショッピング',
        42: '健康、医療、病気',
        43: 'スポーツ（ジョギング、ジムなど自身が行うスポーツ）',
        44: '釣り',
        45: '登山・トレッキング',
        46: 'キャンプ',
        47: 'アウトドア（釣り、キャンプ除く）',
        48: '車',
        49: 'バイク',
        50: '鉄道',
        51: '育児',
        52: '子どもの教育',
        53: '料理',
        54: 'ペット',
        55: '園芸、家庭菜園',
        56: 'インテリア',
        57: '住宅・エクステリア',
        58: '保険',
        59: 'グルメ、レストラン',
        60: 'お酒',
        61: 'パソコン',
        62: 'スマートフォン',
        63: 'デジタル機器',
        64: '腕時計',
        65: '占い',
        66: '恋愛',
        67: '結婚',
        68: '手芸・編み物',
        69: 'コスメ、化粧品',
        70: 'ヘアケア、ヘアサロン',
        71: 'ネイルケア、ネイルサロン',
        72: 'デンタルケア（歯並び、ホワイトニングなど）',
        73: 'ファッション',
        74: 'アクセサリー',
        75: 'ダイエット'
    },
    202110: {
        1: '国内旅行',
        2: '海外旅行',
        3: '読書・書籍',
        4: 'スポーツ観戦',
        5: 'マンガ',
        6: 'アニメ',
        7: '音楽鑑賞',
        8: '楽器演奏など自身が行う音楽',
        9: '映画',
        10: '舞台、演劇',
        11: 'テレビ番組',
        12: '動画共有サイト（YouTube、ニコニコ動画など）',
        13: '韓国ドラマ',
        14: 'ゲーム',
        15: 'レジャー施設、テーマパーク',
        16: 'イベント、コンサート',
        17: '競馬',
        18: 'パチンコ・パチスロ',
        19: '競馬以外の公営ギャンブル（競輪、オートレース、競艇など）',
        20: '写真、カメラ',
        21: 'アート、芸術',
        22: 'アイドル',
        23: '俳優・女優',
        24: '歌手・ミュージシャン',
        25: 'スポーツ選手',
        26: 'お笑い芸人',
        27: 'アナウンサー',
        28: 'モデル',
        29: '声優',
        30: 'アーティスト',
        31: 'その他芸能人・有名人',
        32: 'ビジネス関連',
        33: '資格取得、習い事',
        34: '就職、求人',
        35: '受験、学校',
        36: 'マネー、投資',
        37: '不動産投資',
        38: '電力自由化',
        39: '政治',
        40: '経済',
        41: 'ショッピング',
        42: '健康、医療、病気',
        43: 'スポーツ（ジョギング、ジムなど自身が行うスポーツ）',
        44: '釣り',
        45: '登山・トレッキング',
        46: 'キャンプ',
        47: 'アウトドア（釣り、キャンプ除く）',
        48: '車',
        49: 'バイク',
        50: '鉄道',
        51: '育児',
        52: '子どもの教育',
        53: '料理',
        54: 'ペット',
        55: '園芸、家庭菜園',
        56: 'インテリア',
        57: '住宅・エクステリア',
        58: '保険',
        59: 'グルメ、レストラン',
        60: 'お酒',
        61: 'パソコン',
        62: 'スマートフォン',
        63: 'デジタル機器',
        64: '腕時計',
        65: '占い',
        66: '恋愛',
        67: '結婚',
        68: '手芸・編み物',
        69: 'コスメ、化粧品',
        70: 'ヘアケア、ヘアサロン',
        71: 'ネイルケア、ネイルサロン',
        72: 'デンタルケア（歯並び、ホワイトニングなど）',
        73: 'ファッション',
        74: 'アクセサリー',
        75: 'ダイエット'
    },
    202012: {
        1: '国内旅行',
        2: '海外旅行',
        3: '読書・書籍',
        4: 'スポーツ観戦',
        5: 'マンガ',
        6: 'アニメ',
        7: '音楽鑑賞',
        8: '楽器演奏など自身が行う音楽',
        9: '映画',
        10: '舞台、演劇',
        11: 'テレビ番組',
        12: '動画共有サイト（YouTube、ニコニコ動画など）',
        13: '韓国ドラマ',
        14: 'ゲーム',
        15: 'レジャー施設、テーマパーク',
        16: 'イベント、コンサート',
        17: '競馬',
        18: 'パチンコ・パチスロ',
        19: '競馬以外の公営ギャンブル（競輪、オートレース、競艇など）',
        20: '写真、カメラ',
        21: 'アート、芸術',
        22: 'アイドル',
        23: '俳優・女優',
        24: '歌手・ミュージシャン',
        25: 'スポーツ選手',
        26: 'お笑い芸人',
        27: 'アナウンサー',
        28: 'モデル',
        29: '声優',
        30: 'アーティスト',
        31: 'その他芸能人・有名人',
        32: 'ビジネス関連',
        33: '資格取得、習い事',
        34: '就職、求人',
        35: '受験、学校',
        36: 'マネー、投資',
        37: '不動産投資',
        38: '電力自由化',
        39: '政治',
        40: '経済',
        41: 'ショッピング',
        42: '健康、医療、病気',
        43: 'スポーツ（ジョギング、ジムなど自身が行うスポーツ）',
        44: '釣り',
        45: '登山・トレッキング',
        46: 'キャンプ',
        47: 'アウトドア（釣り、キャンプ除く）',
        48: '車',
        49: 'バイク',
        50: '鉄道',
        51: '育児',
        52: '子どもの教育',
        53: '料理',
        54: 'ペット',
        55: '園芸、家庭菜園',
        56: 'インテリア',
        57: '住宅・エクステリア',
        58: '保険',
        59: 'グルメ、レストラン',
        60: 'お酒',
        61: 'パソコン',
        62: 'スマートフォン',
        63: 'デジタル機器',
        64: '腕時計',
        65: '占い',
        66: '恋愛',
        67: '結婚',
        68: '手芸・編み物',
        69: 'コスメ、化粧品',
        70: 'ヘアケア、ヘアサロン',
        71: 'ネイルケア、ネイルサロン',
        72: 'デンタルケア（歯並び、ホワイトニングなど）',
        73: 'ファッション',
        74: 'アクセサリー',
        75: 'ダイエット'
    },
    202011: {
        1: '国内旅行',
        2: '海外旅行',
        3: '読書・書籍',
        4: 'スポーツ観戦',
        5: 'マンガ',
        6: 'アニメ',
        7: '音楽鑑賞',
        8: '楽器演奏など自身が行う音楽',
        9: '映画',
        10: '舞台、演劇',
        11: 'テレビ番組',
        12: '動画共有サイト（YouTube、ニコニコ動画など）',
        13: 'ゲーム',
        14: 'レジャー施設、テーマパーク',
        15: 'イベント、コンサート',
        16: '競馬',
        17: 'パチンコ・パチスロ',
        18: '競馬以外の公営ギャンブル（競輪、オートレース、競艇など）',
        19: '写真、カメラ',
        20: 'アート、芸術',
        21: 'アイドル',
        22: '俳優・女優',
        23: '歌手・ミュージシャン',
        24: 'スポーツ選手',
        25: 'お笑い芸人',
        26: 'アナウンサー',
        27: 'モデル',
        28: '声優',
        29: 'アーティスト',
        30: 'その他芸能人・有名人',
        31: 'ビジネス関連',
        32: '資格取得、習い事',
        33: '就職、求人',
        34: '受験、学校',
        35: 'マネー、投資  ',
        36: '不動産投資',
        37: '電力自由化',
        38: '政治',
        39: '経済',
        40: 'ショッピング',
        41: '健康、医療、病気',
        42: 'スポーツ（ジョギング、ジムなど自身が行うスポーツ）',
        43: '釣り',
        44: '登山・トレッキング',
        45: 'キャンプ',
        46: 'アウトドア（釣り、キャンプ除く）',
        47: '車',
        48: 'バイク',
        49: '鉄道',
        50: '育児',
        51: '子どもの教育',
        52: '料理',
        53: 'ペット',
        54: '園芸、家庭菜園',
        55: 'インテリア',
        56: '住宅・エクステリア',
        57: '保険',
        58: 'グルメ、レストラン',
        59: 'お酒',
        60: 'パソコン',
        61: 'スマートフォン',
        62: 'デジタル機器',
        63: '腕時計',
        64: '占い',
        65: '恋愛',
        66: '結婚',
        67: '手芸・編み物',
        68: 'コスメ、化粧品',
        69: 'ヘアケア、ヘアサロン',
        70: 'ネイルケア、ネイルサロン',
        71: 'デンタルケア（歯並び、ホワイトニングなど）',
        72: 'ファッション',
        73: 'アクセサリー',
        74: 'ダイエット'
    }
};
