import { geekblue, red } from '@/chartTheme';
import { AppSearch } from '@/components/AppSearch';
import { useAuth } from '@/contexts/Auth';
import { useGlobal } from '@/contexts/Global';
import {
    Extract,
    State,
    useApproveExtractMutation,
    useCanExtractMutation,
    useCreateExtractMutation,
    useDeleteExtractMutation,
    useExtractInfoLazyQuery,
    useExtractListQuery,
    useShareExtractMutation,
    useUpdateExtractMutation
} from '@/graphql/generated';
import { successColor } from '@/theme';
import { logger } from '@/utils/logger';
import { parseYm } from '@/utils/parseYm';
import {
    CloseOutlined,
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    MinusOutlined,
    PlusOutlined,
    ShareAltOutlined
} from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    DatePicker,
    Divider,
    Dropdown,
    Form,
    Input,
    Menu,
    Modal,
    Pagination,
    Radio,
    Row,
    Select,
    Spin,
    Table,
    Tabs,
    Tag,
    Typography,
    message,
    notification
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FormListFieldData, FormListOperation } from 'antd/lib/form/FormList';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ItemCard } from './AmazonItem';

const { confirm } = Modal;
const { Option } = Select;
const { Link } = Typography;

function userCountMessage(users: number) {
    if (users >= 1000) {
        return '1000人以上';
    }

    if (users >= 500) {
        return '500人以上1000人未満';
    }

    if (users >= 100) {
        return '100人以上500人未満';
    }

    if (users >= 50) {
        return '50人以上100人未満';
    }

    if (users >= 15) {
        return '15人以上50人未満';
    }

    if (users >= 5) {
        return '5人以上15人未満';
    }

    return '抽出不可';
}

const BrowserOr: React.FunctionComponent<{
    logType: 'browser' | 'app' | 'item';
    mode: Mode;
    exclude?: boolean;
    form: React.RefObject<FormInstance<any>>;
    conditionField: FormListFieldData;
    conditionIndex: number;
    conditionAdd: FormListOperation['add'];
    conditionRemove: FormListOperation['remove'];
    andFields: FormListFieldData[];
    andField: FormListFieldData;
    andIndex: number;
    andAdd: FormListOperation['add'];
    andRemove: FormListOperation['remove'];
    orFields: FormListFieldData[];
    orField: FormListFieldData;
    orIndex: number;
    orAdd: FormListOperation['add'];
    orRemove: FormListOperation['remove'];
}> = ({
    logType,
    mode,
    exclude,
    form,
    conditionField,
    conditionIndex,
    conditionAdd,
    conditionRemove,
    andFields,
    andField,
    andIndex,
    andAdd,
    andRemove,
    orFields,
    orField,
    orIndex,
    orAdd,
    orRemove
}) => {
    return (
        <div style={{ marginLeft: 12 }}>
            {orIndex !== 0 && <Divider>OR</Divider>}
            <Row key={orIndex} gutter={12}>
                <Col span={6}>
                    <Form.Item
                        dependencies={[
                            [
                                exclude ? 'excludeConditions' : 'conditions',
                                conditionField.name,
                                'ands',
                                andField.name,
                                'field'
                            ]
                        ]}
                    >
                        {({ getFieldsValue }) => {
                            const code = exclude ? 'excludeConditions' : 'conditions';
                            const value = getFieldsValue();
                            return (
                                <Form.Item
                                    {...orField}
                                    name={[orField.name, 'matchType']}
                                    initialValue="PARTIAL"
                                    noStyle
                                >
                                    <Select disabled={mode === 'read' || mode === 'update'}>
                                        <Option value="EXACT">完全一致</Option>
                                        <Option value="PARTIAL">部分一致</Option>
                                        {value?.[code]?.[conditionIndex]?.ands?.[andIndex]?.field === 'keyword' && (
                                            <Option value="WORD">単語一致</Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            );
                        }}
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        {...orField}
                        name={[orField.name, 'value']}
                        validateTrigger={['onBlur']}
                        rules={[
                            {
                                validator: async (rule, value) => {
                                    if (logType === 'browser' && !value) {
                                        return Promise.reject('必須です。');
                                    }
                                }
                            },
                            {
                                validateTrigger: ['onBlur'],
                                validator: async (rule, value) => {
                                    const code = exclude ? 'excludeConditions' : 'conditions';
                                    const values = form.current?.getFieldsValue();
                                    if (values?.[code]?.[conditionIndex]?.ands?.[andIndex]?.field === 'url') {
                                        try {
                                            const [hostname] = value.split('/');
                                            if (!hostname) {
                                                return Promise.reject('URLのホストを指定してください。');
                                            } else if (hostname.includes('*')) {
                                                return Promise.reject('ホストに「*」は使用できません。');
                                            }
                                        } catch (e) {
                                            return Promise.reject('URLの形式が不正です。');
                                        }
                                    }
                                }
                            }
                        ]}
                        normalize={(value, prevValue, allValues) => {
                            const code = exclude ? 'excludeConditions' : 'conditions';
                            if (allValues?.[code]?.[conditionIndex]?.ands?.[andIndex]?.field === 'url') {
                                return value.replace(/^https?:\/\//, '');
                            }
                            return value;
                        }}
                        dependencies={[
                            [
                                exclude ? 'excludeConditions' : 'conditions',
                                conditionField.name,
                                'ands',
                                andField.name,
                                'field'
                            ]
                        ]}
                    >
                        <Input disabled={mode === 'read' || mode === 'update'} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Button.Group>
                        <Button
                            disabled={
                                mode === 'read' ||
                                mode === 'update' ||
                                (andFields.length === 1 && orFields.length === 1)
                            }
                            onClick={() => {
                                if (orFields.length === 1) {
                                    andRemove(andField.name);
                                } else {
                                    orRemove(orField.name);
                                }
                            }}
                        >
                            <MinusOutlined />
                        </Button>
                        <Button
                            disabled={mode === 'read' || mode === 'update'}
                            onClick={() => {
                                if (orFields.length < 5) {
                                    orAdd();
                                }
                            }}
                        >
                            OR
                        </Button>
                        <Button
                            disabled={mode === 'read' || mode === 'update'}
                            onClick={() => {
                                if (andFields.length < 5) {
                                    andAdd();
                                }
                            }}
                        >
                            AND
                        </Button>
                    </Button.Group>
                </Col>
            </Row>
        </div>
    );
};

const BrowserAnd: React.FunctionComponent<{
    logType: 'browser' | 'app' | 'item';
    mode: Mode;
    exclude?: boolean;
    form: React.RefObject<FormInstance<any>>;
    conditionField: FormListFieldData;
    conditionIndex: number;
    conditionAdd: FormListOperation['add'];
    conditionRemove: FormListOperation['remove'];
    andFields: FormListFieldData[];
    andField: FormListFieldData;
    andIndex: number;
    andAdd: FormListOperation['add'];
    andRemove: FormListOperation['remove'];
}> = ({
    logType,
    mode,
    exclude,
    form,
    conditionField,
    conditionIndex,
    conditionAdd,
    conditionRemove,
    andFields,
    andField,
    andIndex,
    andAdd,
    andRemove
}) => {
    return (
        <Form.Item noStyle>
            {andIndex !== 0 && <Divider>AND</Divider>}
            <Row gutter={12}>
                <Col span={8}>
                    <Form.Item {...andField} name={[andField.name, 'field']} initialValue={'keyword'}>
                        <Select disabled={mode === 'read' || mode === 'update'}>
                            <Option value="url">URL</Option>
                            <Option value="keyword">検索キーワード</Option>
                            <Option value="title">ページタイトル</Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item {...andField} name={[andField.name, 'inclexcl']} initialValue="INCLUDE">
                        <Select disabled={andIndex === 0 || mode === 'read' || mode === 'update'}>
                            <Option value="INCLUDE">含める</Option>
                            <Option value="EXCLUDE">除外する</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                noStyle
                dependencies={[
                    [exclude ? 'excludeConditions' : 'conditions', conditionField.name, 'ands', andField.name, 'field']
                ]}
            >
                {({ getFieldsValue }) => {
                    const code = exclude ? 'excludeConditions' : 'conditions';
                    const value = getFieldsValue();
                    return (
                        value?.[code]?.[conditionIndex]?.ands?.[andIndex]?.field === 'url' && (
                            <div style={{ color: red, fontSize: 12, lineHeight: 1, marginBottom: 8 }}>
                                <div>アスタリスク（*）で任意の0文字以上の文字列を表します。</div>
                                <div>ホスト名は完全一致でマッチします。</div>
                            </div>
                        )
                    );
                }}
            </Form.Item>
            <Form.List initialValue={[{}]} name={[andField.name, 'ors']}>
                {(orFields, { add: orAdd, remove: orRemove }) => (
                    <>
                        {orFields.map((orField, orIndex) => (
                            <>
                                <BrowserOr
                                    key={orField.key}
                                    {...{
                                        logType,
                                        mode,
                                        exclude,
                                        form,
                                        conditionField,
                                        conditionIndex,
                                        conditionAdd,
                                        conditionRemove,
                                        andFields,
                                        andField,
                                        andIndex,
                                        andAdd,
                                        andRemove,
                                        orFields,
                                        orField,
                                        orIndex,
                                        orAdd,
                                        orRemove
                                    }}
                                />
                            </>
                        ))}
                    </>
                )}
            </Form.List>
        </Form.Item>
    );
};

const ConditionCard: React.FunctionComponent<{
    mode: Mode;
    exclude?: boolean;
    form: React.RefObject<FormInstance<any>>;
    conditionFields: FormListFieldData[];
    conditionField: FormListFieldData;
    conditionIndex: number;
    conditionAdd: FormListOperation['add'];
    conditionRemove: FormListOperation['remove'];
}> = ({ mode, exclude, form, conditionFields, conditionField, conditionIndex, conditionAdd, conditionRemove }) => {
    return (
        <Card
            headStyle={{ borderTop: `2px solid ${exclude ? red : geekblue}` }}
            size="small"
            title={exclude ? `除外条件${conditionIndex + 1}` : `条件${conditionIndex + 1}`}
            extra={
                mode === 'create' && (
                    <CloseOutlined
                        onClick={() => {
                            if (exclude) {
                                conditionRemove(conditionField.name);
                            }

                            if (conditionFields.length > 1) {
                                conditionRemove(conditionField.name);
                            }
                        }}
                    />
                )
            }
        >
            <Form.Item {...conditionField} name={[conditionField.name, 'type']} initialValue="browser">
                <Radio.Group
                    disabled={mode === 'read' || mode === 'update'}
                    size="small"
                    options={[
                        { label: 'ブラウザ', value: 'browser' },
                        { label: 'アプリ', value: 'app' },
                        { label: 'Amazon商品', value: 'item' }
                    ]}
                    optionType="button"
                    buttonStyle="solid"
                />
            </Form.Item>
            <Form.Item
                noStyle
                dependencies={[[exclude ? 'excludeConditions' : 'conditions', conditionField.name, 'type']]}
            >
                {({ getFieldsValue, setFieldsValue }) => {
                    const code = exclude ? 'excludeConditions' : 'conditions';
                    const value = getFieldsValue();
                    const logType = value?.[code]?.[conditionField.name]?.type;
                    return (
                        <>
                            {logType === 'item' && (
                                <ItemCard
                                    {...{
                                        logType,
                                        mode,
                                        exclude,
                                        form,
                                        conditionFields,
                                        conditionField,
                                        conditionIndex,
                                        conditionAdd,
                                        conditionRemove
                                    }}
                                />
                            )}
                            <div
                                style={{
                                    display: logType === 'app' ? '' : 'none'
                                }}
                            >
                                <Form.List initialValue={[{}]} name={[conditionField.name, 'apps']}>
                                    {(fields, { add: orAdd, remove: orRemove }) => (
                                        <>
                                            {fields.map((field, orIndex) => (
                                                <>
                                                    {orIndex !== 0 && <Divider key={`divider-${orIndex}`}>OR</Divider>}
                                                    <Row key={field.key} gutter={12}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                name={[field.name, 'app']}
                                                                rules={[
                                                                    {
                                                                        validator: async (rule, value) => {
                                                                            if (logType === 'app' && !value) {
                                                                                return Promise.reject('必須です。');
                                                                            }
                                                                        }
                                                                    }
                                                                ]}
                                                            >
                                                                <AppSearch
                                                                    disabled={mode === 'read' || mode === 'update'}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={6}>
                                                            <Button.Group>
                                                                <Button
                                                                    disabled={
                                                                        mode === 'read' ||
                                                                        mode === 'update' ||
                                                                        (orIndex === 0 && fields.length === 1)
                                                                    }
                                                                    onClick={() => {
                                                                        orRemove(orIndex);
                                                                    }}
                                                                >
                                                                    <MinusOutlined />
                                                                </Button>
                                                                <Button
                                                                    disabled={
                                                                        mode === 'read' ||
                                                                        mode === 'update' ||
                                                                        fields.length > 9
                                                                    }
                                                                    onClick={() => {
                                                                        orAdd();
                                                                    }}
                                                                >
                                                                    <PlusOutlined />
                                                                </Button>
                                                            </Button.Group>
                                                        </Col>
                                                    </Row>
                                                </>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                            </div>
                            <div
                                style={{
                                    display: logType === 'browser' ? '' : 'none'
                                }}
                            >
                                <Form.List initialValue={[{}]} name={[conditionField.name, 'ands']}>
                                    {(andFields, { add: andAdd, remove: andRemove }) => (
                                        <>
                                            {andFields.map((andField, andIndex) => (
                                                <>
                                                    <BrowserAnd
                                                        key={andField.key}
                                                        {...{
                                                            logType,
                                                            mode,
                                                            exclude,
                                                            form,
                                                            conditionField,
                                                            conditionIndex,
                                                            conditionAdd,
                                                            conditionRemove,
                                                            andFields,
                                                            andField,
                                                            andIndex,
                                                            andAdd,
                                                            andRemove
                                                        }}
                                                    />
                                                </>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                            </div>
                        </>
                    );
                }}
            </Form.Item>
        </Card>
    );
};

export type Mode = 'create' | 'update' | 'read';

type Field = 'url' | 'keyword' | 'title';
type MatchType = 'WORD' | 'PARTIAL';

type Condition = {
    ands?: {
        field: Field;
        ors?: {
            matchType: MatchType;
            value: string;
        }[];
    }[];
    itemAnds?: {
        itemOrs?: {
            value: string;
        }[];
    }[];
};

// これがないと古い抽出でエラー
function normalizeConditions(_conditions: Condition) {
    const conditions = JSON.parse(JSON.stringify(_conditions ?? []));
    if (!Array.isArray(conditions)) {
        return [];
    }

    conditions.forEach((condition) => {
        condition.type = condition.type ?? 'browser';
    });

    return conditions;
}

export const ExtractForm: React.FunctionComponent<{
    refetchExtract: () => void;
    extractid?: number;
    mode: Mode;
    visible: boolean;
    setVisible: (b: boolean) => void;
}> = ({ extractid, mode, refetchExtract, visible, setVisible }) => {
    const form = React.useRef<FormInstance>(null);
    const [createExtract] = useCreateExtractMutation();
    const [updateExtract] = useUpdateExtractMutation();
    const [fetch, extract] = useExtractInfoLazyQuery();
    const [jsonVersion, setJsonVersion] = React.useState<null | string>(null);

    React.useEffect(() => {
        if (!visible || !extractid) {
            return;
        }

        if (extractid) {
            fetch({ variables: { extractid } });
        } else {
            form.current?.resetFields();
        }
    }, [fetch, mode, extractid, visible]);

    React.useEffect(() => {
        if (extract.data?.extract) {
            const { title, startYm, endYm, jsonVersion, aroundMin, condition } = extract.data?.extract;

            const { query, conditions, excludeConditions, extractOperator } = condition;
            setJsonVersion(jsonVersion);
            form.current?.setFieldsValue({
                title,
                startYm: parseYm(startYm),
                endYm: parseYm(endYm),
                aroundMin: aroundMin,
                extractOperator,
                conditions: normalizeConditions(conditions ?? []),
                excludeConditions: normalizeConditions(excludeConditions ?? []),
                ...(query || {})
            });
        }
    }, [extract]);

    React.useEffect(() => {
        if (!visible) {
            return;
        }

        setJsonVersion('3');
        form.current?.resetFields();
        form.current?.setFieldsValue({
            conditions: [{}],
            excludeConditions: []
        });
    }, [visible]);

    const { availableMonth } = useGlobal();
    const disabledDate = React.useCallback(
        (date: moment.Moment) => {
            const { startYm, endYm } = availableMonth;
            return date < startYm || date >= endYm.clone().add(1, 'months');
        },
        [availableMonth]
    );

    const dispatchExtract = React.useCallback(async () => {
        try {
            const value = await form.current?.validateFields();
            if (mode === 'create') {
                const conditions = value.conditions as Condition[];
                const excludeConditions = value.excludeConditions as Condition[];

                // urlにhttpsが入っちゃうのをここで防ぐ
                [...conditions, ...excludeConditions]?.forEach((condition) => {
                    condition.ands?.forEach((and) => {
                        if (and.field === 'url') {
                            and.ors?.forEach((or) => {
                                or.value = or.value.replace(/^https?:\/\//, '');
                            });
                        }
                    });
                });

                // keyword以外にも単語一致が選べちゃうのをここで防ぐ
                [...conditions, ...excludeConditions]?.forEach((condition) => {
                    condition.ands?.forEach((and) => {
                        if (and.field !== 'keyword') {
                            and.ors?.forEach((or) => {
                                if (or.matchType === 'WORD') {
                                    or.matchType = 'PARTIAL';
                                }
                            });
                        }
                    });
                });

                if (!value.startYm || !value.endYm) {
                    return;
                }

                if (value.endYm.diff(value.startYm, 'months') < 0) {
                    message.error('期間が無効です。');
                    return;
                }

                if (value.endYm.diff(value.startYm, 'months') > 11) {
                    message.error('期間は12ヶ月以内で指定してください。');
                    return;
                }

                // トリム
                [...conditions, ...excludeConditions].forEach((condition) => {
                    condition.ands?.forEach((and) => {
                        and.ors?.forEach((or) => {
                            or.value = (or.value ?? '').trim();
                        });
                    });

                    condition.itemAnds?.forEach((and) => {
                        and.itemOrs?.forEach((or) => {
                            or.value = (or.value ?? '').trim();
                        });
                    });
                });

                const urlRegex = new RegExp('^(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$', 'i');

                const hasUrlInKeyword = (condition: Condition) => {
                    return condition.ands?.some((and) => {
                        if (and.field === 'url') {
                            return false;
                        }
                        return and.ors?.some((or) => {
                            return urlRegex.test(or.value);
                        });
                    });
                };

                // 条件がキーワードだが、URLが入っていないかチェック
                const includeUrlInKeyword =
                    conditions.some((condition) => hasUrlInKeyword(condition)) ||
                    excludeConditions.some((condition) => hasUrlInKeyword(condition));

                if (includeUrlInKeyword) {
                    const confirmed = await new Promise<boolean>((resolve) => {
                        confirm({
                            centered: true,
                            content:
                                '検索キーワードの条件にURL形式の文字列が入力されています。' + 'このまま続行しますか？',
                            onOk() {
                                resolve(true);
                            },
                            onCancel() {
                                resolve(false);
                            }
                        });
                    });
                    if (!confirmed) {
                        return;
                    }
                }

                const confirmed = await new Promise<boolean>((resolve) => {
                    Modal.confirm({
                        centered: true,
                        content: mode === 'create' ? '対象者の人数を確認しますか?' : '変更しますか?',
                        onOk: async () => {
                            await createExtract({
                                variables: {
                                    data: {
                                        title: value.title,
                                        startYm: value.startYm?.format('YYYYMM'),
                                        endYm: value.endYm?.format('YYYYMM'),
                                        aroundMin: value.aroundMin,
                                        extractOperator: value.extractOperator,
                                        conditions: conditions,
                                        excludeConditions: excludeConditions
                                    }
                                }
                            });
                            resolve(true);
                        },
                        onCancel: () => {
                            resolve(false);
                        }
                    });
                });

                if (confirmed) {
                    refetchExtract();
                    setVisible(false);
                }
            } else if (mode === 'update' && extractid) {
                await updateExtract({
                    variables: {
                        extractid,
                        title: value.title
                    }
                });
                refetchExtract();
                setVisible(false);
            }
        } catch (e) {
            console.log(e);
            message.error('登録に失敗しました。');
        }
    }, [createExtract, extractid, mode, refetchExtract, setVisible, updateExtract]);

    return (
        <Modal
            title="抽出条件"
            maskClosable={false}
            visible={visible}
            width={800}
            footer={mode === 'read' ? null : undefined}
            okText={mode === 'create' ? '人数確認' : '変更'}
            onOk={() => {
                dispatchExtract();
            }}
            onCancel={() => {
                setVisible(false);
            }}
        >
            <Spin spinning={extract.loading}>
                <Form
                    preserve={false}
                    layout="vertical"
                    size="small"
                    ref={form}
                    validateTrigger={[]}
                    autoComplete="off"
                >
                    <Form.Item label="タイトル" name="title" rules={[{ required: true, message: '必須です。' }]}>
                        <Input maxLength={100} disabled={mode === 'read'} />
                    </Form.Item>
                    <Form.Item label="期間">
                        <Form.Item
                            noStyle
                            name="startYm"
                            initialValue={availableMonth.endYm.clone().add(-11, 'months')}
                            rules={[{ required: true, message: '必須です。' }]}
                        >
                            <DatePicker
                                disabled={mode === 'read' || mode === 'update'}
                                disabledDate={disabledDate}
                                picker="month"
                                format="YYYY-MM"
                                style={{ width: 150 }}
                            />
                        </Form.Item>
                        <span style={{ margin: 'auto 8px' }}>-</span>
                        <Form.Item
                            noStyle
                            name="endYm"
                            initialValue={availableMonth.endYm.clone()}
                            rules={[{ required: true, message: '必須です。' }]}
                        >
                            <DatePicker
                                disabled={mode === 'read' || mode === 'update'}
                                disabledDate={disabledDate}
                                picker="month"
                                format="YYYY-MM"
                                style={{ width: 150 }}
                            />
                        </Form.Item>
                    </Form.Item>
                    <Row gutter={12}>
                        <Col span={8}>
                            <Form.Item
                                name="aroundMin"
                                initialValue={180}
                                rules={[{ required: true, message: '必須です。' }]}
                            >
                                <Select disabled={mode === 'read' || mode === 'update'}>
                                    {[30, 60, 90, 120, 150, 180].map((n) => (
                                        <Option key={n} value={n}>
                                            前後{n}分
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {jsonVersion === '3' && (
                            <Col span={16}>
                                <Form.Item
                                    name="extractOperator"
                                    initialValue="OR"
                                    rules={[
                                        {
                                            required: true,
                                            message: '必須です。'
                                        }
                                    ]}
                                >
                                    <Select disabled={mode === 'read' || mode === 'update'}>
                                        <Option value="AND">すべての条件を満たすユーザーを抽出する</Option>
                                        <Option value="OR">いずれかの条件を満たすユーザーを抽出する</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    {jsonVersion === '1' && (
                        <Row gutter={12}>
                            <Col span={8}>
                                <Form.Item name="searchType">
                                    <Select disabled>
                                        <Option value="keyword">キーワード</Option>
                                        <Option value="title">タイトル</Option>
                                        <Option value="chname">サイト</Option>
                                        <Option value="host">ホスト</Option>
                                        <Option value="url">URL</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={16}>
                                <Form.Item name="searchValue">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {jsonVersion === '3' && (
                        <>
                            <Form.List name="conditions" initialValue={[{}]}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => {
                                            return (
                                                <Form.Item key={field.key}>
                                                    <ConditionCard
                                                        mode={mode}
                                                        key={index}
                                                        form={form}
                                                        conditionFields={fields}
                                                        conditionField={field}
                                                        conditionIndex={index}
                                                        conditionAdd={add}
                                                        conditionRemove={remove}
                                                    />
                                                </Form.Item>
                                            );
                                        })}
                                        {mode === 'create' && (
                                            <div>
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        block
                                                        disabled={fields.length > 4}
                                                        onClick={() => {
                                                            add();
                                                        }}
                                                    >
                                                        条件の追加
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Form.List>
                            <Form.List name="excludeConditions" initialValue={[]}>
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => {
                                            return (
                                                <Form.Item key={field.key}>
                                                    <ConditionCard
                                                        mode={mode}
                                                        exclude
                                                        key={index}
                                                        form={form}
                                                        conditionFields={fields}
                                                        conditionField={field}
                                                        conditionIndex={index}
                                                        conditionAdd={add}
                                                        conditionRemove={remove}
                                                    />
                                                </Form.Item>
                                            );
                                        })}
                                        {mode === 'create' && (
                                            <div>
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        danger
                                                        block
                                                        disabled={fields.length > 4}
                                                        onClick={() => {
                                                            add();
                                                        }}
                                                    >
                                                        除外条件の追加
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Form.List>
                        </>
                    )}
                </Form>
            </Spin>
        </Modal>
    );
};

const stateConfigs = {
    [State.Pending]: {
        label: '保留',
        color: 'grey',
        canDelete: false
    },
    [State.Checking]: {
        label: '確認中',
        color: 'blue',
        canDelete: false
    },
    [State.Extracting]: {
        label: '抽出中',
        color: 'blue',
        canDelete: false
    },
    [State.UserNotEnough]: {
        label: '対象者不足',
        color: 'error',
        canDelete: true
    },
    [State.ErrorCheckResult]: {
        label: 'エラー',
        color: 'error',
        canDelete: true
    },
    [State.ErrorExtractData]: {
        label: 'エラー',
        color: 'error',
        canDelete: true
    },
    [State.WaitForApproval]: {
        label: '集計実行待ち',
        color: 'warning',
        canDelete: true
    },
    [State.WaitForExtract]: {
        label: '抽出待ち',
        color: 'blue',
        canDelete: false
    },
    [State.Complete]: {
        label: '完了',
        color: 'success',
        canDelete: true
    }
};

const pageSize = 20;

export const HomePage = () => {
    const [extractid, setExtractid] = React.useState<number>();
    const [mode, setMode] = React.useState<'create' | 'update'>('create');
    const [modal, setModal] = React.useState<boolean>(false);
    const [page, setPage] = React.useState(1);
    const [tab, setTab] = React.useState('myreport');
    const { data, loading, error, refetch, startPolling, stopPolling } = useExtractListQuery({
        fetchPolicy: 'no-cache',
        variables: {
            shared: tab === 'sharedreport',
            limit: pageSize,
            offset: (page - 1) * pageSize
        }
    });
    const auth = useAuth();

    const history = useHistory();
    const [_checkCanExtract] = useCanExtractMutation();
    const checkCanExtract = React.useCallback(() => {
        return _checkCanExtract().then(
            () => true,
            () => false
        );
    }, [_checkCanExtract]);

    React.useEffect(() => {
        logger({
            url: window.location.pathname,
            action: 'TOGGLE_TAB',
            actionParam: {
                toggleTo: tab
            }
        });
    }, [tab]);

    const [deleteExtract] = useDeleteExtractMutation();
    const [shareExtract] = useShareExtractMutation();
    const [approveExtract] = useApproveExtractMutation();

    const refetchExtract = React.useCallback(() => {
        refetch({
            shared: tab === 'sharedreport',
            limit: pageSize,
            offset: (page - 1) * pageSize
        });
    }, [refetch, tab, page]);

    React.useEffect(() => {
        startPolling(1000 * 30);
        return stopPolling;
    }, []);

    const deleteExtractPrompt = React.useCallback(
        (extractid: number) => {
            Modal.confirm({
                content: '削除してもよろしいですか?',
                onOk: async () => {
                    try {
                        await deleteExtract({
                            variables: {
                                extractid
                            }
                        });

                        refetchExtract();

                        notification.success({
                            message: '削除しました。'
                        });
                    } catch (e) {
                        notification.error({
                            message: '通信エラーが発生しました。'
                        });
                    }
                }
            });
        },
        [deleteExtract, refetchExtract]
    );

    const shareExtractPrompt = React.useCallback(
        (extractid: number, share: boolean) => {
            Modal.confirm({
                content: `${share ? '共有' : '共有を解除'}してもよろしいですか?`,
                onOk: async () => {
                    try {
                        await shareExtract({
                            variables: {
                                extractid,
                                share
                            }
                        });

                        refetchExtract();

                        notification.success({
                            message: `${share ? '共有' : '共有を解除'}しました。`
                        });
                    } catch (e) {
                        notification.error({
                            message: '通信エラーが発生しました。'
                        });
                    }
                }
            });
        },
        [shareExtract, refetchExtract]
    );

    const approveExtractPrompt = React.useCallback(
        (extractid: number, extract: Partial<Extract>) => {
            const leftTicket = (auth.menu?.maxCount || 0) - (auth.menu?.count || 0);

            Modal.confirm({
                width: 600,
                content: (
                    <div>
                        <h3>抽出を開始するには集計実行ボタンを押してください。</h3>
                        <div
                            style={{
                                margin: '8px 0',
                                padding: 12,
                                border: '1px solid #e5e7eb',
                                background: '#fff'
                            }}
                        >
                            抽出人数: {userCountMessage(extract?.userCount || 0)}
                        </div>
                        {extract.userCount && extract.userCount < 100 && (
                            <p style={{ color: red }}>※ 対象人数に満たないため、クラスタリング機能が使えません。</p>
                        )}
                        {auth.menu?.contractType === 'ticket' && (
                            <p style={{ color: red }}>※ 1チケット消費します。（残り{leftTicket} チケット）</p>
                        )}
                    </div>
                ),
                onOk: async () => {
                    try {
                        await approveExtract({
                            variables: {
                                extractid
                            }
                        });

                        refetchExtract();

                        notification.success({
                            message: '抽出を開始しました。'
                        });
                    } catch (e) {
                        notification.error({
                            message: '通信エラーが発生しました。'
                        });
                    }
                }
            });
        },
        [approveExtract, refetchExtract, auth]
    );

    const tabContent = React.useMemo(() => {
        return (
            <div>
                <Table
                    loading={loading}
                    rowKey="extractid"
                    pagination={false}
                    size="small"
                    columns={[
                        {
                            title: 'ID',
                            dataIndex: 'extractid',
                            align: 'center',
                            width: 80
                        },
                        {
                            title: '抽出',
                            dataIndex: 'site',
                            render(_, record) {
                                return (
                                    <div>
                                        <div>
                                            <Link
                                                disabled={record.state !== 'COMPLETE'}
                                                onClick={() => {
                                                    history.push(`/browsingstory/${record.extractid}`);
                                                }}
                                            >
                                                {record.title}
                                            </Link>
                                        </div>
                                        <div>
                                            <span style={{ marginRight: 8 }}>作成者: {record.user.username}</span>
                                            <span style={{ marginRight: 8 }}>
                                                抽出日時: {moment(record.createdAt).format('YYYY-MM-DD HH:mm')}
                                            </span>
                                            {(() => {
                                                const { label, color } = stateConfigs[record.state];
                                                return <Tag color={color}>{label}</Tag>;
                                            })()}
                                            {record.shared && <Tag color={successColor}>共有済み</Tag>}
                                        </div>
                                    </div>
                                );
                            }
                        },
                        {
                            dataIndex: 'approve',
                            width: 120,
                            render(_, record) {
                                if (record.userid === auth.userid && record.state === State.WaitForApproval) {
                                    return (
                                        <div style={{ textAlign: 'center' }}>
                                            <Button
                                                shape="round"
                                                type="primary"
                                                onClick={() => {
                                                    approveExtractPrompt(record.extractid, record);
                                                }}
                                            >
                                                集計実行
                                            </Button>
                                        </div>
                                    );
                                }

                                return <></>;
                            }
                        },
                        {
                            dataIndex: 'edit',
                            width: 120,
                            render(_, record) {
                                return (
                                    <div style={{ textAlign: 'center' }}>
                                        <Dropdown.Button
                                            size="small"
                                            overlay={
                                                <Menu
                                                    onClick={(e) => {
                                                        if (e.key === 'delete') {
                                                            deleteExtractPrompt(record.extractid);
                                                        }

                                                        if (e.key === 'share') {
                                                            shareExtractPrompt(record.extractid, !record.shared);
                                                        }

                                                        if (e.key === 'copy') {
                                                            if (record.jsonVersion !== '3') {
                                                                return;
                                                            }

                                                            checkCanExtract().then((n) => {
                                                                if (n) {
                                                                    setModal(true);
                                                                    setMode('create');
                                                                    setExtractid(record.extractid);
                                                                }
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <Menu.Item
                                                        key="delete"
                                                        disabled={
                                                            record.userid !== auth.userid ||
                                                            !stateConfigs[record.state].canDelete
                                                        }
                                                        icon={<DeleteOutlined />}
                                                    >
                                                        削除
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        disabled={
                                                            record.userid !== auth.userid ||
                                                            record.state !== State.Complete
                                                        }
                                                        key="share"
                                                        icon={<ShareAltOutlined />}
                                                    >
                                                        {record.shared ? '共有解除' : '共有'}
                                                    </Menu.Item>
                                                    <Menu.Item
                                                        key="copy"
                                                        disabled={record.jsonVersion !== '3'}
                                                        icon={<CopyOutlined />}
                                                    >
                                                        再作成
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                            disabled={record.userid !== auth.userid}
                                            placement="bottom"
                                            onClick={() => {
                                                setModal(true);
                                                setMode('update');
                                                setExtractid(record.extractid);
                                            }}
                                            icon={<DownOutlined />}
                                        >
                                            編集
                                        </Dropdown.Button>
                                    </div>
                                );
                            }
                        }
                    ]}
                    dataSource={data?.extractList?.extracts}
                />
                <div style={{ textAlign: 'right', marginTop: 8 }}>
                    <Pagination
                        size="small"
                        pageSize={pageSize}
                        current={page}
                        onChange={(page) => {
                            setPage(page);
                        }}
                        total={data?.extractList?.total}
                    />
                </div>
            </div>
        );
    }, [
        auth,
        history,
        data,
        deleteExtractPrompt,
        shareExtractPrompt,
        approveExtractPrompt,
        page,
        loading,
        checkCanExtract
    ]);

    return (
        <div className="slim-scroll" style={{ height: '100%', padding: 12 }}>
            {modal && (
                <ExtractForm
                    extractid={extractid}
                    mode={mode}
                    refetchExtract={refetchExtract}
                    visible={modal}
                    setVisible={setModal}
                />
            )}
            <div style={{ display: 'flex', marginBottom: 12 }}>
                <Button
                    shape="round"
                    type="primary"
                    size="large"
                    onClick={() => {
                        checkCanExtract().then((n) => {
                            if (n) {
                                setExtractid(undefined);
                                setModal(true);
                                setMode('create');
                            }
                        });
                    }}
                >
                    <PlusOutlined />
                    データを抽出する
                </Button>
                <div style={{ flex: 1 }} />
                {/* <span style={{ margin: "auto" }}>
                    2021年1月26日
                    <a
                        href={`${process.env.PUBLIC_URL}/story bankアップデートのご案内_20210126.pdf`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        バージョンアップのお知らせ
                    </a>
                </span> */}
            </div>
            <Card size="small">
                <Tabs
                    type="line"
                    activeKey={tab}
                    onChange={(tab) => {
                        setTab(tab);
                    }}
                >
                    <Tabs.TabPane key="myreport" tab="自分が作成したレポート">
                        {tabContent}
                    </Tabs.TabPane>
                    <Tabs.TabPane key="sharedreport" tab="共有されたレポート">
                        {tabContent}
                    </Tabs.TabPane>
                </Tabs>
            </Card>
        </div>
    );
};
