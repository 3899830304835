import { RaccoonChart } from '@/components/RaccoonChart';
import React from 'react';

export const WordCloudCard: React.FC<{
    loading?: boolean;
    title: string;
    words: {
        word: string;
        score: number;
    }[];
    color: string;
    stopwords: string[];
    toggleStopword: (word: string) => void;
}> = ({ loading, title, words, color, stopwords, toggleStopword }) => {
    const chart = React.useMemo(() => {
        return (
            <RaccoonChart
                height={200}
                loading={loading}
                data={words
                    .filter(({ word }) => !stopwords.includes(word))
                    .map(({ word, score }, index) => ({
                        index,
                        name: word,
                        value: score
                    }))}
                chartOption={{
                    data: [],
                    type: 'wordCloud',
                    colorColumn: {
                        type: 'value',
                        key: 'index'
                    },
                    color: {
                        colors: [color, '#eee']
                    },
                    legend: { show: false, position: 'bottom' },
                    textColumn: {
                        key: 'name',
                        title: 'ワード'
                    },
                    sizeRange: [12, 32],
                    sizeColumn: {
                        key: 'value',
                        title: '特徴値',
                        formatter: (value) => {
                            if (value == null) return '-';
                            return `${value.toFixed(2)} pt`;
                        },
                        min: 0
                    },
                    onClick: (shape: any) => {
                        toggleStopword(shape.data.text);
                    }
                }}
            />
        );
    }, [loading, words, color, stopwords, toggleStopword]);

    return <div style={{ position: 'relative' }}>{chart}</div>;
};
