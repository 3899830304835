import { geekblue } from '@/chartTheme';
import { RaccoonChart } from '@/components/RaccoonChart';
import { useGeneralTrendQuery } from '@/graphql/generated';
import { useExtractParam } from '@/pages/Extract/Context';
import { makeYws, parseYm } from '@/utils/parseYm';
import React from 'react';

export const ExpandRowTrend: React.FC<{
    site?: string;
    word?: string;
    app?: string;
}> = ({ site, word, app }) => {
    const {
        extractid,
        extract: { startYm, endYm },
        filter,
        clusterid
    } = useExtractParam();
    const { data, loading, error } = useGeneralTrendQuery({
        variables: {
            extractid,
            site,
            word,
            app,
            filter,
            clusterid
        }
    });

    const yws = makeYws(parseYm(startYm), parseYm(endYm));

    const map = new Map<string, string>();

    yws.forEach((yw) => {
        map.set(yw.format('GGGG-WW'), yw.format('YYYY-MM-DD'));
    });

    return (
        <div>
            <RaccoonChart
                loading={loading}
                height={300}
                data={data?.extract?.general.trend.map((n: any) => ({
                    ...n,
                    name: ''
                }))}
                chartOption={{
                    type: 'line',
                    data: [],
                    smooth: true,
                    stack: true,
                    xColumn: {
                        key: 'date',
                        title: '日付',
                        domain: yws.map((yw) => yw.format('GGGG-WW')),
                        formatter: (date: string) => {
                            return map.get(date) || '';
                        }
                    },
                    yColumn: {
                        key: 'sessionsRate',
                        title: 'セッション数比率',
                        formatter: (value) => {
                            if (value == null) return '-';
                            return value.toPercent().toFixed(1) + ' %';
                        },
                        axisFormatter: (value) => {
                            if (value == null) return '-';
                            return value.toPercent().toFixed(0) + ' %';
                        },
                        min: 0
                    },
                    seriesColumn: {
                        key: 'name',
                        title: '',
                        domain: ['']
                    },
                    color: { colors: [geekblue] },
                    legend: { show: false, position: 'bottom' }
                }}
            />
        </div>
    );
};
