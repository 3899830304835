import { cyan } from '@/chartTheme';
import { DashboardCard } from '@/components/DashboardCard';
import { BODY_TROUBLE } from '@/constants';
import { useBodyTroubleQuery, ValidState } from '@/graphql/generated';
import { Alert } from 'antd';
import React from 'react';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';
import { makeScatterChartOption } from '../Interest';

export const BodyTroubleCard: React.FunctionComponent<{
    loading: boolean;
    error: any;
    data?: {
        bodyTrouble: string;
        usersRate: number;
        score: number;
        name: string;
    }[];
    validState?: ValidState;
}> = ({
    error,
    loading,
    data,
    validState
    // fields
}) => {
    const chart = React.useMemo(() => {
        if (!data) {
            return;
        }

        if (validState === 'INVALID') {
            return;
        }

        return makeScatterChartOption({
            data: data.map(({ bodyTrouble, usersRate, score, name }) => {
                return {
                    id: Number(bodyTrouble),
                    text: BODY_TROUBLE[bodyTrouble],
                    sizeValue: usersRate,
                    xValue: score,
                    yValue: usersRate,
                    color: cyan,
                    score,
                    usersRate,
                    name
                };
            }),
            domain: ['care', 'measure'],
            formatter: (s) => {
                return (
                    {
                        care: '気にしていること',
                        measure: '対策していること'
                    }[s] || ''
                );
            }
        });
    }, [validState, data]);

    return (
        <DashboardCard
            size="small"
            title="身体の悩み"
            data={chart?.data}
            chartOption={chart}
            height="100%"
            loading={loading}
            error={error}
            warn={
                <>
                    {validState === 'WARNING' && (
                        <Alert type="warning" message="サンプル数が少ないため表示されている値は参考値になります。" />
                    )}
                    {validState === 'INVALID' && (
                        <Alert type="error" message="サンプル数が少ないため、表示できません。" />
                    )}
                </>
            }
        />
    );
};

export const BodyTroublePage: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useBodyTroubleQuery({
        variables: {
            extractid,
            filter,
            clusterid
        }
    });

    const bodyData = React.useMemo(() => {
        return [
            ...(data?.extract?.bodyCare?.data.map((n) => ({
                ...n,
                name: 'care'
            })) ?? []),
            ...(data?.extract?.bodyMeasure?.data.map((n) => ({
                ...n,
                name: 'measure'
            })) ?? [])
        ];
    }, [data]);

    const validState = React.useMemo(() => {
        return data?.extract?.bodyCare?.validState;
    }, [data]);

    return (
        <div style={{ padding: 12, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: 12 }}>
                <Filter />
            </div>
            <div style={{ flex: 1, overflow: 'hidden' }}>
                <BodyTroubleCard loading={loading} error={error} data={bodyData} validState={validState} />
            </div>
        </div>
    );
};
