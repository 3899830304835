import { bluePalette, colorPalette, cyan, geekblue, redPalette } from '@/chartTheme';
import { RaccoonChart } from '@/components/RaccoonChart';
import { CHILD, GENDER_AGE_BRACKET, LIVING_AREA, MARRIAGE } from '@/constants';
import { useAttributesQuery } from '@/graphql/generated';
import { useExtractParam } from '@/pages/Extract/Context';
import { Card, Col, Row } from 'antd';
import React from 'react';

export function groupBy(data: any[], keys: string[]) {
    const key2row: Record<string, any> = {};

    data.forEach((row) => {
        const key = keys.map((key) => row[key] || '').join('_');
        if (!key2row[key]) {
            key2row[key] = { ...row };
        } else {
            key2row[key].users += row.users;
        }
    });

    return Object.values(key2row);
}

const GenderAgeBracket: React.FC<{
    loading?: boolean;
    data?: any[];
}> = ({ loading, data }) => {
    const _data = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return groupBy(data, ['name', 'genderAgeBracket']);
    }, [data]);

    return (
        <RaccoonChart
            loading={loading}
            data={_data}
            height={120}
            chartOption={{
                type: 'bar',
                layout: 'horizontal',
                data: [],
                stack: true,
                percent: true,
                xColumn: {
                    key: 'name',
                    title: '',
                    domain: ['']
                },
                yColumn: {
                    key: 'users',
                    title: 'リーチ率',
                    min: 0
                },
                seriesColumn: {
                    key: 'genderAgeBracket',
                    title: '性年代',
                    domain: [1, 2].flatMap((gender) => [2, 3, 4, 5, 6].map((ageBracket) => `${gender}-${ageBracket}`)),
                    formatter(s: string) {
                        return GENDER_AGE_BRACKET[s];
                    }
                },
                color: {
                    colors: [...[...bluePalette], ...[...redPalette]]
                }
            }}
        />
    );
};

const LivingArea: React.FC<{
    loading?: boolean;
    data?: any[];
}> = ({ loading, data }) => {
    const _data = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return groupBy(data, ['name', 'area']);
    }, [data]);

    return (
        <RaccoonChart
            loading={loading}
            data={_data}
            height={120}
            chartOption={{
                type: 'bar',
                layout: 'horizontal',
                data: [],
                stack: true,
                percent: true,
                xColumn: {
                    key: 'name',
                    title: '',
                    domain: ['']
                },
                yColumn: {
                    key: 'users',
                    title: 'リーチ率',
                    min: 0
                },
                seriesColumn: {
                    key: 'area',
                    title: '居住地域',
                    domain: Object.keys(LIVING_AREA),
                    formatter(s: string) {
                        return LIVING_AREA[s];
                    }
                },
                color: { colors: colorPalette }
            }}
        />
    );
};

const Marriage: React.FC<{
    loading?: boolean;
    data?: any[];
}> = ({ loading, data }) => {
    const _data = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return groupBy(data, ['name', 'marriage']);
    }, [data]);

    return (
        <RaccoonChart
            loading={loading}
            data={_data}
            height={120}
            chartOption={{
                type: 'bar',
                layout: 'horizontal',
                data: [],
                stack: true,
                percent: true,
                xColumn: {
                    key: 'name',
                    title: '',
                    domain: ['']
                },
                yColumn: {
                    key: 'users',
                    title: 'リーチ率',
                    min: 0
                },
                seriesColumn: {
                    key: 'marriage',
                    title: '未既婚',
                    domain: Object.keys(MARRIAGE),
                    formatter(s: string) {
                        return MARRIAGE[s];
                    }
                },
                color: { colors: [geekblue, cyan] }
            }}
        />
    );
};

const Child: React.FC<{
    loading?: boolean;
    data?: any[];
}> = ({ loading, data }) => {
    const _data = React.useMemo(() => {
        if (!data) {
            return [];
        }
        return groupBy(data, ['name', 'child']);
    }, [data]);

    return (
        <RaccoonChart
            loading={loading}
            data={_data}
            height={120}
            chartOption={{
                type: 'bar',
                layout: 'horizontal',
                data: [],
                stack: true,
                percent: true,
                xColumn: {
                    key: 'name',
                    title: '',
                    domain: ['']
                },
                yColumn: {
                    key: 'users',
                    title: 'リーチ率',
                    min: 0
                },
                seriesColumn: {
                    key: 'child',
                    title: '子供有無',
                    domain: Object.keys(CHILD),
                    formatter(s: string) {
                        return CHILD[s];
                    }
                },
                color: { colors: [geekblue, cyan] }
            }}
        />
    );
};

export const ExpandRowAttributes: React.FC<{
    site?: string;
    word?: string;
    app?: string;
}> = ({ site, word, app }) => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useAttributesQuery({
        variables: {
            extractid,
            site,
            word,
            app,
            filter,
            clusterid
        }
    });

    return (
        <div>
            <Row gutter={12}>
                <Col span={12}>
                    <Card size="small" title="性年代" style={{ marginBottom: 12 }}>
                        <GenderAgeBracket
                            loading={loading}
                            data={data?.extract?.general.attributes.map((n: any) => ({
                                ...n,
                                name: '',
                                genderAgeBracket: `${n.gender}-${n.ageBracket}`,
                                area: String(n.area),
                                marriage: String(n.marriage),
                                child: String(n.child),
                                job: String(n.job),
                                homeIncome: String(n.home_income)
                            }))}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card size="small" title="居住地域" style={{ marginBottom: 12 }}>
                        <LivingArea
                            loading={loading}
                            data={data?.extract?.general.attributes.map((n: any) => ({
                                ...n,
                                name: '',
                                genderAgeBracket: `${n.gender}-${n.ageBracket}`,
                                area: String(n.area),
                                marriage: String(n.marriage),
                                child: String(n.child),
                                job: String(n.job),
                                homeIncome: String(n.home_income)
                            }))}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card size="small" title="未既婚" style={{ marginBottom: 12 }}>
                        <Marriage
                            loading={loading}
                            data={data?.extract?.general.attributes.map((n: any) => ({
                                ...n,
                                name: '',
                                genderAgeBracket: `${n.gender}-${n.ageBracket}`,
                                area: String(n.area),
                                marriage: String(n.marriage),
                                child: String(n.child),
                                job: String(n.job),
                                homeIncome: String(n.home_income)
                            }))}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card size="small" title="子供有無" style={{ marginBottom: 12 }}>
                        <Child
                            loading={loading}
                            data={data?.extract?.general.attributes.map((n: any) => ({
                                ...n,
                                name: '',
                                genderAgeBracket: `${n.gender}-${n.ageBracket}`,
                                area: String(n.area),
                                marriage: String(n.marriage),
                                child: String(n.child),
                                job: String(n.job),
                                homeIncome: String(n.home_income)
                            }))}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
