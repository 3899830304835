import { bluePalette2 } from '@/chartTheme';
import { DashboardCard } from '@/components/DashboardCard';
import {
    Maybe,
    useBeforeAfterQuery,
    useBeforeAfterSankeyQuery,
    useGeneralLandingPagesQuery,
    useGeneralSitesQuery,
    useGeneralWordsQuery
} from '@/graphql/generated';
import { primaryColor } from '@/theme';
import { CaretRightOutlined } from '@ant-design/icons';
import { Modal, Tabs } from 'antd';
import { ChartOptionUnion } from 'raccoon-chart';
import React from 'react';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';

export * from './app';
export * from './word';

const DataTable: React.FC<{
    beforeAfter: number;
    active: {
        site: string;
        befaft: number;
    } | null;
    setActive: React.Dispatch<
        React.SetStateAction<{
            site: string;
            befaft: number;
        } | null>
    >;
}> = ({ beforeAfter, setActive }) => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useBeforeAfterQuery({
        variables: {
            extractid,
            filter,
            clusterid,
            beforeAfter,
            limit: 100
        }
    });

    const title = React.useMemo(() => {
        switch (beforeAfter) {
            case 1:
                return '前半';
            case 2:
                return '中間';
            case 3:
                return '後半';
        }
    }, [beforeAfter]);

    const columns = React.useMemo(
        () => [
            {
                type: 'simple' as 'simple',
                width: 40,
                key: 'index',
                title: '#',
                align: 'center' as 'center',
                render: (row: any, rowIndex: number) => {
                    return `${rowIndex + 1}.`;
                }
            },
            {
                type: 'image' as 'image',
                width: 32,
                key: 'favicon',
                size: { width: 16, height: 16 },
                imageUrl: (row: any) => `${process.env.REACT_APP_TURTLE_URL}/icon?site=${row.chname}`
            },
            {
                type: 'simple' as 'simple',
                key: 'chname',
                title: 'サイト',
                align: 'left' as 'left',
                render: (row: any) => {
                    return row.title;
                },
                render2: (row: any) => {
                    return row.chname;
                },
                color2: primaryColor,
                link2: (row: any) => `https://${row.chname}`
            },
            {
                type: 'simple' as 'simple',
                width: 72,
                key: 'usersRate',
                title: 'リーチ率',
                align: 'right' as 'right',
                render: (row: { usersRate: number }) => {
                    return row.usersRate.toPercent().toFixed(2) + ' %';
                }
            },
            // {
            //     type: 'simple' as 'simple',
            //     width: 72,
            //     key: 'appearRate',
            //     title: '出現率',
            //     align: 'right' as 'right',
            //     render: (row: { cnt: number }) => {
            //         return row.cnt.toPercent().toFixed(2) + ' %';
            //     }
            // },
            {
                type: 'simple' as 'simple',
                width: 72,
                key: 'score',
                title: 'スコア',
                align: 'right' as 'right',
                render: (row: { score: number }) => {
                    return row.score.toPercent().toFixed(1) + ' pt';
                }
            },
            {
                type: 'svg',
                svg: `
                    <svg
                        stroke="#333"
                        fill="#333"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="12"
                        width="12"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
                        <path d="M11 11h2v6h-2zm0-4h2v2h-2z"></path>
                    </svg>
                `,
                size: { width: 12, height: 12 },
                width: 48,
                onClick: (row: any) => {
                    setActive({
                        site: row.chname,
                        befaft: beforeAfter
                    });
                }
            }
        ],
        [beforeAfter, setActive]
    );

    const chart = React.useMemo(() => {
        return {
            type: 'dataTable',
            data: [],
            dataColumns: [
                { type: 'category', key: 'chname', title: 'サイト' },
                { type: 'category', key: 'title', title: 'タイトル' },
                {
                    type: 'value',
                    key: 'usersRate',
                    title: 'リーチ率',
                    formatter: (usersRate: number) => {
                        return usersRate.toPercent().toFixed(2) + ' %';
                    }
                },
                {
                    type: 'value',
                    key: 'score',
                    title: 'スコア',
                    formatter: (score: number) => {
                        return score.toPercent().toFixed(1) + ' pt';
                    }
                }
            ],
            columns
        } as ChartOptionUnion;
    }, [columns]);

    return (
        <DashboardCard
            size="small"
            title={title}
            height={'100%'}
            loading={loading}
            data={data?.extract?.beforeAfter}
            error={error}
            chartOption={chart}
        />
    );
};

export const SiteSankey: React.FC<{
    site?: string;
    word?: string;
    befaft: number;
}> = ({ site, word, befaft }) => {
    const { extractid } = useExtractParam();
    const { data, loading } = useBeforeAfterSankeyQuery({
        variables: {
            extractid,
            beforeAfter: befaft,
            site,
            word
        }
    });

    return (
        <DashboardCard
            loading={loading}
            data={data?.extract?.beforeAfterSankey}
            chartHeight={400}
            wrapCard={false}
            chartOption={{
                type: 'sankey',
                data: [],
                layerColumns: [
                    {
                        title: '前半',
                        key: 'bef'
                    },
                    {
                        title: '中間',
                        key: 'mid'
                    },
                    {
                        title: '後半',
                        key: 'aft'
                    }
                ],
                valueColumn: {
                    key: 'cnt',
                    title: 'リーチ率',
                    valueGetter: (row: any) => Number(row.cnt),
                    formatter: (value) => {
                        if (value == null) return '-';
                        return value.toPercent().toFixed(2) + ' %';
                    }
                }
            }}
        />
    );
};

export const SiteList: React.FC<{
    site?: string;
    word?: string;
    befaft: number;
}> = ({ site, word, befaft }) => {
    const { extractid } = useExtractParam();

    const {
        data: _data,
        loading,
        error
    } = useGeneralSitesQuery({
        variables: {
            extractid,
            befaft,
            site,
            word
        }
    });

    const data: Maybe<any[]> = React.useMemo(() => {
        return _data?.extract?.general.sites;
    }, [_data]);

    return (
        <DashboardCard
            wrapCard={false}
            loading={loading}
            data={data}
            error={error}
            chartHeight={400}
            chartOption={{
                type: 'dataTable',
                data: [],
                columns: [
                    {
                        type: 'image' as 'image',
                        width: 32,
                        title: '',
                        key: 'favicon',
                        size: { width: 16, height: 16 },
                        imageUrl: (row: any) => `${process.env.REACT_APP_TURTLE_URL}/icon?site=${row.site}`
                    },
                    {
                        type: 'simple',
                        key: 'site',
                        title: 'サイト',
                        render: (row: any) => row.title,
                        render2: (row: any) => row.site,
                        color2: primaryColor,
                        link2: (row: any) => `https://${row.site}`
                    },
                    {
                        type: 'simple',
                        key: 'usersRate',
                        title: 'リーチ率',
                        align: 'right',
                        render: (row: any) => (row.usersRate as number).toPercent().toFixed(2) + ' %',
                        width: 100
                    }
                ]
            }}
        />
    );
};

export const LandingPage: React.FC<{
    site?: string;
    word?: string;
    befaft: number;
}> = ({ site, word, befaft }) => {
    const { extractid } = useExtractParam();

    const {
        data: _data,
        loading,
        error
    } = useGeneralLandingPagesQuery({
        variables: {
            extractid,
            befaft,
            site,
            word
        }
    });

    const data: Maybe<any[]> = React.useMemo(() => {
        return _data?.extract?.general.landingPages;
    }, [_data]);

    return (
        <DashboardCard
            wrapCard={false}
            loading={loading}
            data={data}
            error={error}
            chartHeight={400}
            chartOption={{
                type: 'dataTable',
                data: [],
                columns: [
                    {
                        type: 'image' as 'image',
                        width: 32,
                        title: '',
                        key: 'favicon',
                        size: { width: 16, height: 16 },
                        imageUrl: (row: any) => `${process.env.REACT_APP_TURTLE_URL}/icon?site=${row.site}`
                    },
                    {
                        type: 'simple',
                        key: 'landingPage',
                        title: 'ランディングページ',
                        render: (row: any) => row.title,
                        render2: (row: any) => row.landingPage,
                        link2: (row: any) => `https://${row.landingPage}`,
                        color2: primaryColor
                    },
                    {
                        type: 'simple',
                        key: 'usersRate',
                        title: 'リーチ率',
                        align: 'right',
                        render: (row: any) => (row.usersRate as number).toPercent().toFixed(2) + ' %',
                        width: 100
                    }
                ]
            }}
        />
    );
};

export const ReferralKeyword: React.FC<{
    site?: string;
    word?: string;
    befaft: number;
}> = ({ site, word, befaft }) => {
    const { extractid } = useExtractParam();

    const {
        data: _data,
        loading,
        error
    } = useGeneralWordsQuery({
        variables: {
            extractid,
            split: true,
            befaft,
            site,
            word
        }
    });

    const data: Maybe<any[]> = React.useMemo(() => {
        return _data?.extract?.general.words?.filter((n: any) => n.word !== word);
    }, [_data, word]);

    return (
        <DashboardCard
            wrapCard={false}
            title="流入キーワード"
            loading={loading}
            data={data}
            error={error}
            chartHeight={400}
            chartOption={{
                type: 'wordCloud',
                data: [],
                color: {
                    colors: [...bluePalette2]
                },
                sizeRange: [12, 32],
                textColumn: {
                    key: 'word',
                    valueGetter: (row: any) => row.word
                },
                sizeColumn: {
                    key: 'usersRate',
                    valueGetter: (row: any) => row.usersRate,
                    formatter: (value) => {
                        if (value == null) return '-';
                        return value.toPercent().toFixed(2) + ' %';
                    }
                },
                colorColumn: {
                    type: 'value',
                    key: 'usersRate',
                    valueGetter: (row: any) => row.usersRate,
                    formatter: (value) => {
                        if (value == null) return '-';
                        return value.toPercent().toFixed(2) + ' %';
                    }
                },
                legend: { show: false }
            }}
        />
    );
};

export const BeforeAfterPage: React.FunctionComponent<{}> = () => {
    const [active, setActive] = React.useState<{ site: string; befaft: number } | null>(null);

    return (
        <div style={{ height: '100%' }}>
            <div style={{ padding: 12, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: 12 }}>
                    <Filter />
                </div>

                <Modal
                    visible={!!active}
                    width={800}
                    onCancel={() => {
                        setActive(null);
                    }}
                    title="詳細"
                    footer={null}
                >
                    <div style={{ background: '#fff', padding: 12 }}>
                        {active && (
                            <Tabs type="line">
                                <Tabs.TabPane tab="ランディングページ" key="landingPage">
                                    <LandingPage site={active.site} befaft={active.befaft} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="検索キーワード" key="referralKeyword">
                                    <ReferralKeyword site={active.site} befaft={active.befaft} />
                                </Tabs.TabPane>
                                {/* <Tabs.TabPane tab="フロー（β版）" key="flow">
                                    <SiteSankey site={active.site} befaft={active.befaft} />
                                </Tabs.TabPane> */}
                            </Tabs>
                        )}
                    </div>
                </Modal>

                <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                        <DataTable {...{ beforeAfter: 1, active, setActive }} />
                    </div>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <CaretRightOutlined style={{ margin: 'auto', fontSize: 32 }} />
                    </div>
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                        <DataTable {...{ beforeAfter: 2, active, setActive }} />
                    </div>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <CaretRightOutlined style={{ margin: 'auto', fontSize: 32 }} />
                    </div>
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                        <DataTable {...{ beforeAfter: 3, active, setActive }} />
                    </div>
                </div>
            </div>
        </div>
    );
};
