import { bluePalette, colorPalette20, redPalette } from '@/chartTheme';
import { DashboardCard } from '@/components/DashboardCard';
import { ValidState, useAssetsQuery } from '@/graphql/generated';
import { Alert, Col, Row } from 'antd';
import { scaleThreshold } from 'd3';
import { ChartOptionUnion } from 'raccoon-chart';
import React from 'react';
import { makeStackChartOption } from '../Attribute/Cards';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';

const palette = scaleThreshold<number, string>()
    .range([...[...bluePalette].reverse(), ...redPalette])
    .domain([0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8]);

export const DISPOSABLE_INCOME: {
    [s: string]: string;
} = {
    1: '5千円未満',
    2: '5千円-1万円未満',
    3: '1万円-2万円未満',
    4: '2万円-3万円未満',
    5: '3万円-4万円未満',
    6: '4万円-5万円未満',
    7: '5万円-6万円未満',
    8: '6万円-7万円未満',
    9: '7万円-10万円未満',
    10: '10万円-15万円未満',
    11: '15万円-20万円未満',
    12: '20万円以上'
};

export const OWNED_ASSETS: {
    [s: string]: string;
} = {
    1: '0円',
    2: '30万円未満',
    3: '30万円-50万円未満',
    4: '50万円-100万円未満',
    5: '100万円-300万円未満',
    6: '300万円-500万円未満',
    7: '500万円-700万円未満',
    8: '700万円-1000万円未満',
    9: '1000万円-1500万円未満',
    10: '1500万円-2000万円未満',
    11: '2000万円-3000万円未満',
    12: '3000万円-5000万円未満',
    13: '5000万円-7000万円未満',
    14: '7000万円-1億円未満',
    15: '1億円以上'
    // 16: '答えたくない/わからない',
};

const DATA_202208 = {
    1: 'ゲームアプリへの課金',
    2: '映画の鑑賞',
    3: 'CD・DVD購入',
    4: 'イベント・グッズ購入',
    5: 'マンガの購入',
    6: '書籍の購入',
    7: 'スポーツ用品の購入',
    8: 'マッチングアプリ、出会い系アプリへの課金',
    9: '動画サービスへの課金',
    10: 'たばこの購入',
    11: 'パチンコ',
    12: '競馬',
    13: '国内旅行',
    15: 'ライブ、コンサートの鑑賞',
    16: 'スポーツ観戦',
    17: '食費（外食を除く）',
    18: '外食',
    19: 'おかし・デザートなど',
    20: 'お酒、アルコール飲料',
    21: '服・靴・かばん',
    22: '化粧品',
    23: 'お子様への教育費'
};

const SPENDING: {
    [version: number]: {
        [s: string]: string;
    };
} = {
    202011: {
        1: 'ゲームアプリへの課金',
        2: '映画の鑑賞',
        3: 'CD・DVD購入',
        4: 'イベント・グッズ購入',
        5: 'マンガの購入',
        6: '書籍の購入',
        7: 'スポーツ用品の購入',
        8: 'マッチングアプリ、出会い系アプリへの課金',
        9: '動画サービスへの課金',
        10: 'たばこの購入',
        11: 'パチンコ',
        12: '競馬',
        13: '国内旅行',
        14: '海外旅行',
        15: 'ライブ、コンサートの鑑賞',
        16: 'スポーツ観戦',
        17: '食費（外食を除く）',
        18: '外食',
        19: 'おかし・デザートなど',
        20: 'お酒、アルコール飲料',
        21: '服・靴・かばん',
        22: '化粧品',
        23: 'お子様への教育費'
    },
    202012: {
        1: 'ゲームアプリへの課金',
        2: '映画の鑑賞',
        3: 'CD・DVD購入',
        4: 'イベント・グッズ購入',
        5: 'マンガの購入',
        6: '書籍の購入',
        7: 'スポーツ用品の購入',
        8: 'マッチングアプリ、出会い系アプリへの課金',
        9: '動画サービスへの課金',
        10: 'たばこの購入',
        11: 'パチンコ',
        12: '競馬',
        13: '国内旅行',
        15: 'ライブ、コンサートの鑑賞',
        16: 'スポーツ観戦',
        17: '食費（外食を除く）',
        18: '外食',
        19: 'おかし・デザートなど',
        20: 'お酒、アルコール飲料',
        21: '服・靴・かばん',
        22: '化粧品',
        23: 'お子様への教育費'
    },
    202110: {
        1: 'ゲームアプリへの課金',
        2: '映画の鑑賞',
        3: 'CD・DVD購入',
        4: 'イベント・グッズ購入',
        5: 'マンガの購入',
        6: '書籍の購入',
        7: 'スポーツ用品の購入',
        8: 'マッチングアプリ、出会い系アプリへの課金',
        9: '動画サービスへの課金',
        10: 'たばこの購入',
        11: 'パチンコ',
        12: '競馬',
        13: '国内旅行',
        15: 'ライブ、コンサートの鑑賞',
        16: 'スポーツ観戦',
        17: '食費（外食を除く）',
        18: '外食',
        19: 'おかし・デザートなど',
        20: 'お酒、アルコール飲料',
        21: '服・靴・かばん',
        22: '化粧品',
        23: 'お子様への教育費'
    },
    202204: {
        1: 'ゲームアプリへの課金',
        2: '映画の鑑賞',
        3: 'CD・DVD購入',
        4: 'イベント・グッズ購入',
        5: 'マンガの購入',
        6: '書籍の購入',
        7: 'スポーツ用品の購入',
        8: 'マッチングアプリ、出会い系アプリへの課金',
        9: '動画サービスへの課金',
        10: 'たばこの購入',
        11: 'パチンコ',
        12: '競馬',
        13: '国内旅行',
        15: 'ライブ、コンサートの鑑賞',
        16: 'スポーツ観戦',
        17: '食費（外食を除く）',
        18: '外食',
        19: 'おかし・デザートなど',
        20: 'お酒、アルコール飲料',
        21: '服・靴・かばん',
        22: '化粧品',
        23: 'お子様への教育費'
    },
    202207: {
        1: 'ゲームアプリへの課金',
        2: '映画の鑑賞',
        3: 'CD・DVD購入',
        4: 'イベント・グッズ購入',
        5: 'マンガの購入',
        6: '書籍の購入',
        7: 'スポーツ用品の購入',
        8: 'マッチングアプリ、出会い系アプリへの課金',
        9: '動画サービスへの課金',
        10: 'たばこの購入',
        11: 'パチンコ',
        12: '競馬',
        13: '国内旅行',
        15: 'ライブ、コンサートの鑑賞',
        16: 'スポーツ観戦',
        17: '食費（外食を除く）',
        18: '外食',
        19: 'おかし・デザートなど',
        20: 'お酒、アルコール飲料',
        21: '服・靴・かばん',
        22: '化粧品',
        23: 'お子様への教育費'
    },
    202208: DATA_202208,
    202304: DATA_202208,
    202307: DATA_202208,
    202404: DATA_202208
};

const HEIGHT = 200;

export const DisposableIncomeCard: React.FunctionComponent<{
    title: string;
    loading: boolean;
    error: any;
    data?: {
        validState: ValidState;
        monitor: {
            value: string;
            usersRate: number;
        }[];
        target: {
            value: string;
            usersRate: number;
        }[];
    };
    fields: {
        [s: string]: string;
    };
}> = ({ title, error, loading, data, fields }) => {
    const chart = React.useMemo(() => {
        if (!data) {
            return;
        }

        if (data?.validState === 'INVALID') {
            return;
        }

        const chartData: { [s: string]: number[] } = {};
        Object.keys(fields).forEach((value) => {
            chartData[value] = [
                data.target.find((n) => n.value === value)?.usersRate || 0,
                data.monitor.find((n) => n.value === value)?.usersRate || 0
            ];
        });

        return makeStackChartOption({
            color: colorPalette20,
            stacks: Object.keys(fields),
            formatter: (s) => {
                return fields[s];
            },
            data: chartData
        });
    }, [fields, data]);

    return (
        <DashboardCard
            size="small"
            title={title}
            data={chart?.data}
            chartOption={chart}
            chartHeight={HEIGHT}
            loading={loading}
            error={error}
            warn={
                <>
                    {data?.validState === 'WARNING' && (
                        <Alert type="warning" message="サンプル数が少ないため表示されている値は参考値になります。" />
                    )}
                    {data?.validState === 'INVALID' && (
                        <Alert type="error" message="サンプル数が少ないため、表示できません。" />
                    )}
                </>
            }
        />
    );
};

export const SpendingCard2: React.FC<{
    loading: boolean;
    title: string;
    error: any;
    data?: {
        validState: ValidState;
        monitor: {
            spending: string;
            amount: number;
        }[];
        target: {
            spending: string;
            amount: number;
        }[];
    };
    fields: {
        [s: string]: string;
    };
}> = ({ error, loading, title, data, fields }) => {
    const table = React.useMemo(() => {
        if (!data) {
            return;
        }

        if (data?.validState === 'INVALID') {
            return;
        }

        const spendings = [...data.monitor]
            .filter((n) => Object.keys(fields).includes(n.spending))
            .sort((a, b) => b.amount - a.amount)
            .map(({ spending }) => {
                const targetAmount = data.target.find((n) => n.spending === spending)?.amount || 0;
                const monitorAmount = data.monitor.find((n) => n.spending === spending)?.amount || 0;
                return {
                    spending,
                    lift: (targetAmount / monitorAmount).toFixedNumber(2),
                    targetAmount,
                    monitorAmount
                };
            });

        const maxAmount = Math.max(...spendings.flatMap((n) => [n.monitorAmount, n.targetAmount]));
        return {
            type: 'dataTable',
            data: spendings,
            dataColumns: [
                {
                    type: 'category',
                    key: 'spending',
                    title: '設問',
                    formatter: (value) => {
                        return fields[value];
                    }
                },
                {
                    type: 'value',
                    key: 'monitorAmount',
                    title: 'ネット利用者全体',
                    formatter: (value) => {
                        if (value == null) return '-';
                        return value.toFixed(1) + ' 円';
                    }
                },
                {
                    type: 'value',
                    key: 'targetAmount',
                    title: '抽出対象者',
                    formatter: (value) => {
                        if (value == null) return '-';
                        return value.toFixed(1) + ' 円';
                    }
                },
                {
                    type: 'value',
                    key: 'lift',
                    title: '特徴値',
                    formatter: (value) => {
                        if (value == null) return '-';
                        return value.toFixed(2) + ' pt';
                    }
                }
            ],
            columns: [
                // {
                //     key: 'index',
                //     type: 'simple',
                //     align: 'center',
                //     width: 60,
                //     render: (row, rowIndex) => String(rowIndex + 1) + '.',
                //     title: '#'
                // },
                {
                    key: 'spending',
                    type: 'simple',
                    render: (row, rowIndex) => fields[row.spending],
                    title: '設問'
                },
                {
                    key: 'monitorAmount',
                    type: 'simple',
                    align: 'right',
                    width: 160,
                    render: (row, rowIndex) => row.monitorAmount.toFixed(1) + ' 円',
                    color: '#333',
                    background: '#dcfce7',
                    backgroundWidth: (row) => row.monitorAmount / maxAmount,
                    title: 'ネット利用者全体の平均支出'
                },
                {
                    key: 'targetAmount',
                    type: 'simple',
                    align: 'right',
                    width: 160,
                    render: (row, rowIndex) => row.targetAmount.toFixed(1) + ' 円',
                    color: '#333',
                    background: '#dbeafe',
                    backgroundWidth: (row) => row.targetAmount / maxAmount,
                    title: '対象者の平均支出'
                },
                {
                    key: 'lift',
                    type: 'simple',
                    align: 'right',
                    width: 80,
                    title: '特徴値',
                    render: (row, rowIndex) => row.lift.toFixed(2) + ' pt',
                    background: (row, rowIndex) => {
                        const color = palette(row.lift);
                        return color;
                    }
                }
            ]
        } as ChartOptionUnion;
    }, [data, fields]);

    return (
        <DashboardCard
            size="small"
            title={title}
            error={error}
            loading={loading}
            data={table?.data}
            chartHeight={table ? ((table?.data?.length ?? 0) + 1) * 40 : 200}
            chartOption={table}
            warn={
                <>
                    {data?.validState === 'WARNING' && (
                        <Alert type="warning" message="サンプル数が少ないため表示されている値は参考値になります。" />
                    )}
                    {data?.validState === 'INVALID' && (
                        <Alert type="error" message="サンプル数が少ないため、表示できません。" />
                    )}
                </>
            }
        />
    );
};

export const AssetsPage: React.FunctionComponent<{}> = () => {
    const {
        extractid,
        filter,
        clusterid,
        extract: { enqversion }
    } = useExtractParam();
    const { data, loading, error } = useAssetsQuery({
        variables: {
            extractid,
            filter,
            clusterid
        }
    });

    return (
        <div className="slim-scroll" style={{ height: '100%' }}>
            <div style={{ padding: 12 }}>
                <div style={{ marginBottom: 12 }}>
                    <Filter />
                </div>
                <Row gutter={12}>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <DisposableIncomeCard
                            title="1ヶ月に自由に使えるお金"
                            loading={loading}
                            error={error}
                            data={data?.extract?.disposableIncome}
                            fields={DISPOSABLE_INCOME}
                        />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <DisposableIncomeCard
                            title="保有資産"
                            loading={loading}
                            error={error}
                            data={data?.extract?.ownedAssets}
                            fields={OWNED_ASSETS}
                        />
                    </Col>
                    <Col span={24} style={{ marginBottom: 12 }}>
                        <SpendingCard2
                            title="1人あたり平均支出"
                            loading={loading}
                            error={error}
                            data={data?.extract?.spending}
                            fields={SPENDING[enqversion]}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};
