import { textColorSecondary } from '@/theme';
import useRequest from '@/utils/useRequest';
import { Alert, Button, Checkbox, Input, Select, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { format } from 'd3-format';
import React from 'react';

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

interface Props {
    visible: boolean;
    setVisible: (b: boolean) => void;
    onClose: (items: string[]) => void;
    onOpen: (setItems: (items: string[]) => void) => void;
}

const options = [
    {
        label: '商品名',
        value: 'item'
    },
    {
        label: 'ブランド',
        value: 'brand'
    },
    {
        label: 'カテゴリ',
        value: 'category'
    }
];

export const ItemSearchModal: React.FC<Props> = ({ visible, setVisible, onOpen, onClose }) => {
    const [value, setValue] = React.useState('');
    const { data, loading, api } = useRequest<any>('/api/items');
    const [items, setItems] = React.useState<string[]>([]);
    const [fields, setFields] = React.useState<string[]>(['item', 'brand', 'category']);

    React.useEffect(() => {
        if (visible) {
            onOpen(setItems);
        }
    }, [visible]);

    const targetFields = React.useMemo(() => {
        const targetFields: any = {};
        fields.forEach((field) => {
            targetFields[field] = true;
        });
        return targetFields;
    }, [fields]);

    // React.useEffect(() => {
    //     if (!value) {
    //         return;
    //     }

    // }, [value, fetch]);

    return (
        <>
            <Modal
                width={1200}
                title="商品検索"
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                }}
                onOk={() => {
                    onClose(items);
                    setVisible(false);
                }}
                okText="OK"
                cancelText="キャンセル"
            >
                <div style={{ display: 'flex' }}>
                    <Input
                        value={value}
                        onPressEnter={() => {
                            api({
                                fields: targetFields,
                                pattern: value
                            });
                        }}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                    />
                    <Button
                        type="primary"
                        size="middle"
                        style={{ width: 120 }}
                        onClick={() => {
                            api({
                                fields: targetFields,
                                pattern: value
                            });
                        }}
                    >
                        検索
                    </Button>
                </div>
                <div style={{ display: 'flex', marginBottom: 12 }}>
                    <CheckboxGroup
                        options={options}
                        value={fields}
                        onChange={(value) => {
                            setFields(value as string[]);
                        }}
                    />
                </div>
                <div>
                    <Alert message={'商品の並びはアクセス数降順です。'} />
                    <Table
                        scroll={{ y: 400 }}
                        pagination={false}
                        tableLayout="fixed"
                        loading={loading}
                        dataSource={data || []}
                        rowKey="asin"
                        columns={[
                            {
                                key: 'select',
                                dataIndex: 'asin',
                                width: 80,
                                align: 'center',
                                render(value, record) {
                                    return (
                                        <Checkbox
                                            disabled={items.length > 19}
                                            checked={items.includes(value)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setItems(
                                                        [...items, value].filter((x, i, self) => i === self.indexOf(x))
                                                    );
                                                } else {
                                                    setItems([...items].filter((x) => x !== value));
                                                }
                                            }}
                                        />
                                    );
                                }
                            },
                            {
                                key: 'itemname',
                                dataIndex: 'itemname',
                                title: '商品名',
                                render: (value, record) => {
                                    return (
                                        <div
                                            style={{
                                                overflow: 'hidden',
                                                width: '100%'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    color: textColorSecondary,
                                                    fontSize: 12
                                                }}
                                            >
                                                {[record.cat1, record.cat2, record.cat3].filter((n) => n).join(' > ')}
                                            </div>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={`https://amazon.co.jp/dp/${record.asin}`}
                                                >
                                                    {value}
                                                </a>
                                            </div>
                                        </div>
                                    );
                                }
                            },
                            {
                                key: 'brand',
                                dataIndex: 'brand',
                                title: 'ブランド',
                                width: 240
                            },
                            {
                                key: 'listPrice',
                                dataIndex: 'listPrice',
                                title: '価格',
                                align: 'right',
                                width: 160,
                                render(value) {
                                    if (value > 0) {
                                        return <span>￥{format(',')(value)}</span>;
                                    }

                                    return <span>-</span>;
                                }
                            }
                        ]}
                    />
                </div>
            </Modal>
            <Button
                onClick={() => {
                    setVisible(true);
                }}
            >
                商品検索
            </Button>
        </>
    );
};
