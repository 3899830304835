import React, { useCallback, useState } from 'react';
import styles from './index.module.less';

export const Appicon: React.FunctionComponent<{
    app?: string;
    style?: React.CSSProperties;
}> = ({ app, style = {} }) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const onLoad = useCallback(() => {
        setLoading(false);
    }, []);

    const onError = useCallback(() => {
        setLoading(false);
        setError(true);
    }, []);

    return (
        <div className={styles.appiconWrap} style={style}>
            {loading && <div className={styles.empty} />}
            <div style={{ display: loading ? 'none' : 'inline-block' }}>
                {error ? (
                    <div className={styles.empty} />
                ) : (
                    <img
                        key={app}
                        className={styles.appicon}
                        alt={app}
                        src={`${process.env.REACT_APP_TURTLE_URL}/icon?app=${app}`}
                        onLoad={onLoad}
                        onError={onError}
                    />
                )}
            </div>
        </div>
    );
};

export default Appicon;
