export const primaryColor = '#3b82f6';

export const geekblue = '#3b82f6';
export const lightgeekblue = '#bfdbfe';
export const cyan = '#22c55e';
export const lightcyan = '#bbf7d0';
export const grey = '#6b7280';
export const lightgrey = '#e5e7eb';
export const yellow = '#eab308';
export const lightyellow = '#fef08a';
export const red = '#ef4444';
export const lightred = '#fecaca';
export const blue = '#06b6d4';
export const lightblue = '#a5f3fc';
export const purple = '#a855f7';
export const lightpurple = '#e9d5ff';
export const orange = '#f97316';
export const lightorange = '#fed7aa';
export const green = '#84cc16';
export const lightgreen = '#d9f99d';
export const magenta = '#ec4899';
export const lightmagenta = '#fbcfe8';

export const bluePalette: readonly string[] = ['#dbeafe', '#93c5fd', '#3b82f6', '#1d4ed8', '#1e3a8a'];
export const greenPalette: readonly string[] = ['#dcfce7', '#86efac', '#22c55e', '#15803d', '#14532d'];
export const redPalette: readonly string[] = ['#fee2e2', '#fca5a5', '#ef4444', '#b91c1c', '#7f1d1d'];

export const bluePalette2: readonly string[] = ['#93c5fd', '#3b82f6', '#1d4ed8', '#1e3a8a'];

export const colors: {
    [s: string]: string;
} = {
    geekblue,
    cyan,
    grey,
    yellow,
    red,
    blue,
    purple,
    orange,
    green,
    magenta,
    lightgeekblue,
    lightcyan,
    lightgrey,
    lightyellow,
    lightred,
    lightblue,
    lightpurple,
    lightorange,
    lightgreen,
    lightmagenta
};

export const colorPalette: string[] = [geekblue, cyan, grey, yellow, red, blue, purple, orange, green, magenta];

export const colorPalette20: string[] = [
    geekblue,
    lightgeekblue,
    cyan,
    lightcyan,
    grey,
    lightgrey,
    yellow,
    lightyellow,
    red,
    lightred,
    blue,
    lightblue,
    purple,
    lightpurple,
    orange,
    lightorange,
    green,
    lightgreen,
    magenta,
    lightmagenta
];

export const fontFamily = `'Roboto','Noto Sans JP', sans-serif`;
