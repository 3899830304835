import Favicon from '@/components/Favicon';
import { SiteCategorySelect } from '@/components/SiteCategorySelect';
import { ExpandRowAttributes } from '@/components/tableComponents/Attributes';
import { ExpandRowLandingPages } from '@/components/tableComponents/LandingPages';
import { ExpandRowTrend } from '@/components/tableComponents/Trend';
import { ExpandRowWords } from '@/components/tableComponents/Words';
import { ExtractSite, useSiteRankingQuery } from '@/graphql/generated';
import { downloadFile } from '@/utils/downloadFile';
import { logger } from '@/utils/logger';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Table, Tabs } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';
import styles from './index.module.less';

const { TabPane } = Tabs;

export const SiteRankingPage: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const [siteCategories, setSiteCategories] = React.useState<string[]>([]);
    const [validSiteCategories, setValidSiteCategories] = React.useState<string[]>([]);

    const { data, loading, error } = useSiteRankingQuery({
        variables: {
            extractid,
            siteCategories: validSiteCategories,
            filter,
            clusterid
        }
    });

    const [expandedKeys, setExpandedKeys] = React.useState<string[]>([]);

    const columns: ColumnProps<ExtractSite>[] = [
        {
            title: 'No.',
            dataIndex: 'index',
            width: 80,
            align: 'center',
            render(_, record, index) {
                return <span>{index + 1}.</span>;
            }
        },
        {
            title: '',
            dataIndex: 'favicon',
            width: 40,
            render(_, record) {
                return <Favicon site={record.site} style={{ margin: 'auto' }} />;
            }
        },
        {
            title: 'サイト',
            dataIndex: 'site',
            render(_, record) {
                return (
                    <div style={{ lineHeight: 1 }}>
                        <div className={styles.title}>{record.title}</div>
                        <div className={styles.site}>
                            <a rel="noreferrer" target="_blank" href={`https://${record.site}`}>
                                {record.site}
                            </a>
                        </div>
                    </div>
                );
            }
        },
        {
            title: 'サイトカテゴリ',
            dataIndex: 'usersRate',
            width: 180,
            render(_, record) {
                return <span>{record.siteCategory}</span>;
            }
        },
        {
            title: 'リーチ率',
            dataIndex: 'usersRate',
            align: 'right',
            width: 120,
            render(_, record) {
                return <span>{record.usersRate.toFixed(2)} %</span>;
            }
        },
        {
            title: '特徴値',
            dataIndex: 'score',
            align: 'right',
            width: 120,
            render(_, record) {
                return <span>{record.score.toFixed(2)} pt</span>;
            }
        }
    ];

    return (
        <div className="slim-scroll" style={{ height: '100%' }}>
            <div style={{ padding: 12 }}>
                <div style={{ marginBottom: 12 }}>
                    <Filter />

                    <Button
                        style={{ marginLeft: 12 }}
                        onClick={() => {
                            logger({
                                url: window.location.pathname,
                                action: 'DOWNLOAD',
                                extractid,
                                actionParam: {
                                    filter,
                                    clusterid,
                                    type: 'site'
                                }
                            });

                            downloadFile({
                                fileName: `サイトランキング - ${extractid}`,
                                headers: ['サイト', 'タイトル', 'カテゴリ', 'リーチ率', '特徴値'],
                                data:
                                    data?.extract?.sites.map(({ site, title, siteCategory, usersRate, score }) => {
                                        return [site, title, siteCategory, usersRate.toFixed(2), score.toFixed(2)];
                                    }) || []
                            });
                        }}
                    >
                        <DownloadOutlined />
                        ダウンロード
                    </Button>
                </div>
                <div style={{ marginBottom: 12 }}>
                    <span style={{ fontSize: 12, marginRight: 4 }}>サイトカテゴリ: </span>
                    <SiteCategorySelect
                        style={{ width: 400 }}
                        value={siteCategories}
                        onChange={(v) => {
                            setSiteCategories(v);
                        }}
                    />
                    <Button
                        type="primary"
                        style={{ marginLeft: 12 }}
                        onClick={() => {
                            setValidSiteCategories(siteCategories);
                        }}
                    >
                        適用
                    </Button>
                    <Button
                        style={{ marginLeft: 12 }}
                        onClick={() => {
                            setValidSiteCategories([]);
                            setSiteCategories([]);
                        }}
                    >
                        クリア
                    </Button>
                </div>
                <Card size="small" loading={loading}>
                    <Table
                        bordered
                        className={styles.table}
                        tableLayout="fixed"
                        rowKey="site"
                        size="small"
                        dataSource={data?.extract?.sites || []}
                        columns={columns}
                        pagination={false}
                        expandable={{
                            expandedRowKeys: expandedKeys,
                            onExpandedRowsChange: (keys) => {
                                setExpandedKeys([keys[keys.length - 1] as string]);
                            },
                            expandedRowRender: (record) => (
                                <div>
                                    <Tabs type="card">
                                        <TabPane tab="推移" key="trend">
                                            <ExpandRowTrend site={record.site} />
                                        </TabPane>
                                        <TabPane tab="属性" key="genderAgeBracket">
                                            <ExpandRowAttributes site={record.site} />
                                        </TabPane>
                                        <TabPane tab="ランディングページ" key="landingPage">
                                            <ExpandRowLandingPages site={record.site} />
                                        </TabPane>
                                        <TabPane tab="流入キーワード" key="inflowKeyword">
                                            <ExpandRowWords site={record.site} />
                                        </TabPane>
                                    </Tabs>
                                </div>
                            )
                        }}
                    />
                </Card>
            </div>
        </div>
    );
};
