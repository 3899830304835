import { Col, Row } from 'antd';
import React from 'react';
import { Filter } from '../FilterModal';
import {
    ChildCard,
    FamilyCard,
    GenderAgeBracketCard,
    HomeIncomeCard,
    JobCard,
    LivingAreaCard,
    LivingStyleCard,
    MarriageCard
} from './Cards';

const AttributePage: React.FunctionComponent<{}> = () => {
    return (
        <div className="slim-scroll" style={{ height: '100%' }}>
            <div style={{ padding: 12 }}>
                <div style={{ marginBottom: 12 }}>
                    <Filter />
                </div>
                <Row gutter={12}>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <GenderAgeBracketCard />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <LivingAreaCard />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <MarriageCard />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <ChildCard />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <JobCard />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <HomeIncomeCard />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <FamilyCard />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <LivingStyleCard />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default AttributePage;
