import { DashboardCard } from '@/components/DashboardCard';
import { useShoppingQuery, ValidState } from '@/graphql/generated';
import { Alert, Col, Row } from 'antd';
import { scaleThreshold } from 'd3';
import { ChartOptionUnion } from 'raccoon-chart';
import React from 'react';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';

const palette = scaleThreshold<number, string>()
    .range(['#2563eb', '#60a5fa', '#bfdbfe', '#fecaca', '#f87171', '#dc2626'])
    .domain([-10, -5, 0, 5, 10]);

const DATA_202208 = {
    channel: {
        1: '百貨店、デパート',
        2: '総合スーパー（衣食住を扱う大型店）',
        3: 'スーパーマーケット（食料品や日用品のみを扱う店）',
        4: 'ドラッグストア',
        5: 'コンビニエンスストア',
        6: 'ホームセンター',
        7: 'ディスカウントストア',
        8: 'バラエティショップ',
        9: 'ショッピングモール',
        10: '家電量販店',
        11: '100円ショップ',
        12: 'Amazon',
        13: '楽天市場',
        14: 'オークション・フリーマーケットサイト/アプリ',
        15: 'その他のインターネットショッピングサイト/アプリ',
        16: '通信販売（カタログ、テレビなどネット以外）'
    },
    behavior: {
        1: '広告で紹介されている商品をよく購入する',
        2: '店頭でポップなどで紹介されている商品をよく購入する',
        3: '店頭でたくさん並んでいる商品をよく購入する',
        4: '季節限定商品やエリア限定商品をよく購入する',
        5: '新商品をよく購入する',
        6: '割引をされている商品をよく購入する',
        7: '増量されている商品をよく購入する',
        8: 'レジの横に置いている商品をよく購入する',
        9: 'タイムセール商品をよく購入する',
        10: '目に入りやすいところに置かれている商品をよく購入する',
        11: '手の届きやすいところに置かれている商品をよく購入する',
        12: '店員が薦めている商品をよく購入する',
        13: 'ナンバー1と書かれている商品をよく購入する'
    },
    measure: {
        1: '全員5%割引',
        2: '抽選で10人に1人は50%割引',
        3: 'SNSで公式アカウントをフォローすると10%割引',
        4: '全員に10%ポイント還元',
        5: '2個買うと、2つ目は15%割引',
        6: '5,000円以上購入すると500円の送料無料',
        7: '10回購入/利用すると、1回無料',
        8: '購入者全員に100円相当の非売品のアメニティをプレゼント',
        9: '次回購入/利用時に10%割引',
        10: '抽選で10回に1回は50%割引',
        11: '5,000円以上購入すると500円相当の非売品のアメニティをプレゼント',
        12: 'SNSでシェアすると10%割引'
    },
    point: {
        1: 'Tポイント',
        2: 'Ponta',
        3: 'リクルートポイント',
        4: '楽天スーパーポイント',
        5: 'dポイント',
        6: 'nanaco（ナナコ）',
        7: 'WAONポイント',
        // 8: '永久不滅ポイント',
        9: 'PayPayボーナス',
        10: 'LINEポイント',
        11: 'Amazonポイント',
        12: 'ANAマイル',
        13: 'JALマイル'
    }
};

const FIELDS: {
    [enqversion: number]: {
        [s: string]: {
            [s: string]: string;
        };
    };
} = {
    202404: DATA_202208,
    202307: DATA_202208,
    202304: DATA_202208,
    202208: DATA_202208,
    202207: {
        channel: {
            1: '百貨店、デパート',
            2: '総合スーパー（衣食住を扱う大型店）',
            3: 'スーパーマーケット（食料品や日用品のみを扱う店）',
            4: 'ドラッグストア',
            5: 'コンビニエンスストア',
            6: 'ホームセンター',
            7: 'ディスカウントストア',
            8: 'バラエティショップ',
            9: 'ショッピングモール',
            10: '家電量販店',
            11: '100円ショップ',
            12: 'Amazon',
            13: '楽天市場',
            14: 'オークション・フリーマーケットサイト/アプリ',
            15: 'その他のインターネットショッピングサイト/アプリ',
            16: '通信販売（カタログ、テレビなどネット以外）'
        },
        behavior: {
            1: '広告で紹介されている商品をよく購入する',
            2: '店頭でポップなどで紹介されている商品をよく購入する',
            3: '店頭でたくさん並んでいる商品をよく購入する',
            4: '季節限定商品やエリア限定商品をよく購入する',
            5: '新商品をよく購入する',
            6: '割引をされている商品をよく購入する',
            7: '増量されている商品をよく購入する',
            8: 'レジの横に置いている商品をよく購入する',
            9: 'タイムセール商品をよく購入する',
            10: '目に入りやすいところに置かれている商品をよく購入する',
            11: '手の届きやすいところに置かれている商品をよく購入する',
            12: '店員が薦めている商品をよく購入する',
            13: 'ナンバー1と書かれている商品をよく購入する'
        },
        measure: {
            1: '全員5%割引',
            2: '抽選で10人に1人は50%割引',
            3: 'SNSで公式アカウントをフォローすると10%割引',
            4: '全員に10%ポイント還元',
            5: '2個買うと、2つ目は15%割引',
            6: '5,000円以上購入すると500円の送料無料',
            7: '10回購入/利用すると、1回無料',
            8: '購入者全員に100円相当の非売品のアメニティをプレゼント',
            9: '次回購入/利用時に10%割引',
            10: '抽選で10回に1回は50%割引',
            11: '5,000円以上購入すると500円相当の非売品のアメニティをプレゼント',
            12: 'SNSでシェアすると10%割引'
        },
        point: {
            1: 'Tポイント',
            2: 'Ponta',
            3: 'リクルートポイント',
            4: '楽天スーパーポイント',
            5: 'dポイント',
            6: 'nanaco（ナナコ）',
            7: 'WAONポイント',
            8: '永久不滅ポイント',
            9: 'PayPayボーナス',
            10: 'LINEポイント',
            11: 'Amazonポイント',
            12: 'ANAマイル',
            13: 'JALマイル'
        }
    },
    202204: {
        channel: {
            1: '百貨店、デパート',
            2: '総合スーパー（衣食住を扱う大型店）',
            3: 'スーパーマーケット（食料品や日用品のみを扱う店）',
            4: 'ドラッグストア',
            5: 'コンビニエンスストア',
            6: 'ホームセンター',
            7: 'ディスカウントストア',
            8: 'バラエティショップ',
            9: 'ショッピングモール',
            10: '家電量販店',
            11: '100円ショップ',
            12: 'Amazon',
            13: '楽天市場',
            14: 'オークション・フリーマーケットサイト/アプリ',
            15: 'その他のインターネットショッピングサイト/アプリ',
            16: '通信販売（カタログ、テレビなどネット以外）'
        },
        behavior: {
            1: '広告で紹介されている商品をよく購入する',
            2: '店頭でポップなどで紹介されている商品をよく購入する',
            3: '店頭でたくさん並んでいる商品をよく購入する',
            4: '季節限定商品やエリア限定商品をよく購入する',
            5: '新商品をよく購入する',
            6: '割引をされている商品をよく購入する',
            7: '増量されている商品をよく購入する',
            8: 'レジの横に置いている商品をよく購入する',
            9: 'タイムセール商品をよく購入する',
            10: '目に入りやすいところに置かれている商品をよく購入する',
            11: '手の届きやすいところに置かれている商品をよく購入する',
            12: '店員が薦めている商品をよく購入する',
            13: 'ナンバー1と書かれている商品をよく購入する'
        },
        measure: {
            1: '全員5%割引',
            2: '抽選で10人に1人は50%割引',
            3: 'SNSで公式アカウントをフォローすると10%割引',
            4: '全員に10%ポイント還元',
            5: '2個買うと、2つ目は15%割引',
            6: '5,000円以上購入すると500円の送料無料',
            7: '10回購入/利用すると、1回無料',
            8: '購入者全員に100円相当の非売品のアメニティをプレゼント',
            9: '次回購入/利用時に10%割引',
            10: '抽選で10回に1回は50%割引',
            11: '5,000円以上購入すると500円相当の非売品のアメニティをプレゼント',
            12: 'SNSでシェアすると10%割引'
        },
        point: {
            1: 'Tポイント',
            2: 'Ponta',
            3: 'リクルートポイント',
            4: '楽天スーパーポイント',
            5: 'dポイント',
            6: 'nanaco（ナナコ）',
            7: 'WAONポイント',
            8: '永久不滅ポイント'
            // 9: 'その他',
            // 10: 'ポイントは意識的に貯めていない',
        }
    },
    202110: {
        channel: {
            1: '百貨店、デパート',
            2: '総合スーパー（衣食住を扱う大型店）',
            3: 'スーパーマーケット（食料品や日用品のみを扱う店）',
            4: 'ドラッグストア',
            5: 'コンビニエンスストア',
            6: 'ホームセンター',
            7: 'ディスカウントストア',
            8: 'バラエティショップ',
            9: 'ショッピングモール',
            10: '家電量販店',
            11: '100円ショップ',
            12: 'Amazon',
            13: '楽天市場',
            14: 'オークション・フリーマーケットサイト/アプリ',
            15: 'その他のインターネットショッピングサイト/アプリ',
            16: '通信販売（カタログ、テレビなどネット以外）'
        },
        behavior: {
            1: '広告で紹介されている商品をよく購入する',
            2: '店頭でポップなどで紹介されている商品をよく購入する',
            3: '店頭でたくさん並んでいる商品をよく購入する',
            4: '季節限定商品やエリア限定商品をよく購入する',
            5: '新商品をよく購入する',
            6: '割引をされている商品をよく購入する',
            7: '増量されている商品をよく購入する',
            8: 'レジの横に置いている商品をよく購入する',
            9: 'タイムセール商品をよく購入する',
            10: '目に入りやすいところに置かれている商品をよく購入する',
            11: '手の届きやすいところに置かれている商品をよく購入する',
            12: '店員が薦めている商品をよく購入する',
            13: 'ナンバー1と書かれている商品をよく購入する'
        },
        measure: {
            1: '全員5%割引',
            2: '抽選で10人に1人は50%割引',
            3: 'SNSで公式アカウントをフォローすると10%割引',
            4: '全員に10%ポイント還元',
            5: '2個買うと、2つ目は15%割引',
            6: '5,000円以上購入すると500円の送料無料',
            7: '10回購入/利用すると、1回無料',
            8: '購入者全員に100円相当の非売品のアメニティをプレゼント',
            9: '次回購入/利用時に10%割引',
            10: '抽選で10回に1回は50%割引',
            11: '5,000円以上購入すると500円相当の非売品のアメニティをプレゼント',
            12: 'SNSでシェアすると10%割引'
        },
        point: {
            1: 'Tポイント',
            2: 'Ponta',
            3: 'リクルートポイント',
            4: '楽天スーパーポイント',
            5: 'dポイント',
            6: 'nanaco（ナナコ）',
            7: 'WAONポイント',
            8: '永久不滅ポイント'
            // 9: 'その他',
            // 10: 'ポイントは意識的に貯めていない',
        }
    },
    202012: {
        channel: {
            1: '百貨店、デパート',
            2: '総合スーパー（衣食住を扱う大型店）',
            3: 'スーパーマーケット（食料品や日用品のみを扱う店）',
            4: 'ドラッグストア',
            5: 'コンビニエンスストア',
            6: 'ホームセンター',
            7: 'ディスカウントストア',
            8: 'バラエティショップ',
            9: 'ショッピングモール',
            10: '家電量販店',
            11: '100円ショップ',
            12: 'Amazon',
            13: '楽天市場',
            14: 'オークション・フリーマーケットサイト/アプリ',
            15: 'その他のインターネットショッピングサイト/アプリ',
            16: '通信販売（カタログ、テレビなどネット以外）'
        },
        behavior: {
            1: '広告で紹介されている商品をよく購入する',
            2: '店頭でポップなどで紹介されている商品をよく購入する',
            3: '店頭でたくさん並んでいる商品をよく購入する',
            4: '季節限定商品やエリア限定商品をよく購入する',
            5: '新商品をよく購入する',
            6: '割引をされている商品をよく購入する',
            7: '増量されている商品をよく購入する',
            8: 'レジの横に置いている商品をよく購入する',
            9: 'タイムセール商品をよく購入する',
            10: '目に入りやすいところに置かれている商品をよく購入する',
            11: '手の届きやすいところに置かれている商品をよく購入する',
            12: '店員が薦めている商品をよく購入する',
            13: 'ナンバー1と書かれている商品をよく購入する'
        },
        measure: {
            1: '全員5%割引',
            2: '抽選で10人に1人は50%割引',
            3: 'SNSで公式アカウントをフォローすると10%割引',
            4: '全員に10%ポイント還元',
            5: '2個買うと、2つ目は15%割引',
            6: '5,000円以上購入すると500円の送料無料',
            7: '10回購入/利用すると、1回無料',
            8: '購入者全員に100円相当の非売品のアメニティをプレゼント',
            9: '次回購入/利用時に10%割引',
            10: '抽選で10回に1回は50%割引',
            11: '5,000円以上購入すると500円相当の非売品のアメニティをプレゼント',
            12: 'SNSでシェアすると10%割引'
        },
        point: {
            1: 'Tポイント',
            2: 'Ponta',
            3: 'リクルートポイント',
            4: '楽天スーパーポイント',
            5: 'dポイント',
            6: 'nanaco（ナナコ）',
            7: 'WAONポイント',
            8: '永久不滅ポイント'
            // 9: 'その他',
            // 10: 'ポイントは意識的に貯めていない',
        }
    },
    202011: {
        channel: {
            1: '百貨店、デパート',
            2: '総合スーパー（衣食住を扱う大型店）',
            3: 'スーパーマーケット（食料品や日用品のみを扱う店）',
            4: 'ドラッグストア',
            5: 'コンビニエンスストア',
            6: 'ホームセンター',
            7: 'ディスカウントストア',
            8: 'バラエティショップ',
            9: 'ショッピングモール',
            10: '家電量販店',
            11: '100円ショップ',
            12: 'Amazon',
            13: '楽天市場',
            14: 'オークション・フリーマーケットサイト/アプリ',
            15: 'その他のインターネットショッピングサイト/アプリ',
            16: '通信販売（カタログ、テレビなどネット以外）'
            // 17: 'その他',
            // 18: 'わからない／覚えていない',
        },
        behavior: {
            1: '広告で紹介されている商品をよく購入する',
            2: '店頭でポップなどで紹介されている商品をよく購入する',
            3: '店頭でたくさん並んでいる商品をよく購入する',
            4: '季節限定商品やエリア限定商品をよく購入する',
            5: '新商品をよく購入する',
            6: '割引をされている商品をよく購入する',
            7: '増量されている商品をよく購入する',
            8: 'レジの横に置いている商品をよく購入する',
            9: 'タイムセール商品をよく購入する',
            10: '目に入りやすいところに置かれている商品をよく購入する',
            11: '手の届きやすいところに置かれている商品をよく購入する',
            12: '店員が薦めている商品をよく購入する',
            13: '1番人気と書かれている商品をよく購入する'
            // 14: 'あてはまるものはない',
        },
        measure: {
            1: '全員5%割引',
            2: '抽選で10人に1人は50%割引',
            3: 'SNSで公式アカウントをフォローすると10%割引',
            4: '全員に10%ポイント還元',
            5: '2個買うと、2つ目は15%割引',
            6: '5,000円以上購入すると送料無料',
            7: '10回購入/利用すると、1回無料',
            8: '購入者全員に非売品のアメニティをプレゼント',
            9: '次回購入/利用時に10%割引'
            // 10: 'あてはまるものはない',
        },
        point: {
            1: 'Tポイント',
            2: 'Ponta',
            3: 'リクルートポイント',
            4: '楽天スーパーポイント',
            5: 'dポイント',
            6: 'nanaco（ナナコ）',
            7: 'WAONポイント',
            8: '永久不滅ポイント'
            // 9: 'その他',
            // 10: 'ポイントは意識的に貯めていない',
        }
    }
};

export const ShoppingCard: React.FC<{
    loading: boolean;
    title: string;
    error: any;
    data?: {
        validState: ValidState;
        monitor: {
            value: string;
            usersRate: number;
        }[];
        target: {
            value: string;
            usersRate: number;
        }[];
    };
    fields: {
        [s: string]: string;
    };
}> = ({ error, loading, title, data, fields }) => {
    const table = React.useMemo(() => {
        if (!data) {
            return;
        }

        if (data?.validState === 'INVALID') {
            return;
        }

        const values = [...data.monitor]
            .filter((n) => Object.keys(fields).includes(n.value))
            .sort((a, b) => b.usersRate - a.usersRate)
            .map(({ value }) => {
                const targetUsersRate = data.target.find((n) => n.value === value)?.usersRate || 0;
                const monitorUsersRate = data.monitor.find((n) => n.value === value)?.usersRate || 0;
                return {
                    value,
                    lift: (targetUsersRate - monitorUsersRate).toFixedNumber(2),
                    targetUsersRate,
                    monitorUsersRate
                };
            });

        return {
            type: 'dataTable',
            data: values,
            dataColumns: [
                {
                    type: 'category',
                    key: 'value',
                    title: '設問',
                    formatter: (value) => {
                        return fields[value];
                    }
                },
                {
                    type: 'value',
                    key: 'monitorUsersRate',
                    title: 'ネット利用者全体',
                    formatter: (value) => {
                        if (value == null) return '-';
                        return value.toFixed(1) + ' %';
                    }
                },
                {
                    type: 'value',
                    key: 'targetUsersRate',
                    title: '抽出対象者',
                    formatter: (value) => {
                        if (value == null) return '-';
                        return value.toFixed(1) + ' %';
                    }
                },
                {
                    type: 'value',
                    key: 'lift',
                    title: 'スコア',
                    formatter: (value) => {
                        if (value == null) return '-';
                        return value.toFixed(2) + ' pt';
                    }
                }
            ],
            columns: [
                // {
                //     key: 'index',
                //     type: 'simple',
                //     align: 'center',
                //     width: 60,
                //     render: (row, rowIndex) => String(rowIndex + 1) + '.',
                //     title: '#'
                // },
                {
                    key: 'value',
                    type: 'simple',
                    render: (row, rowIndex) => fields[row.value],
                    title: '設問'
                },
                {
                    key: 'monitorUsersRate',
                    type: 'simple',
                    align: 'right',
                    width: 120,
                    render: (row, rowIndex) => row.monitorUsersRate.toFixed(1) + ' %',
                    color: '#333',
                    background: '#dcfce7',
                    backgroundWidth: (row) => row.monitorUsersRate / 100,
                    title: 'ネット利用者全体'
                },
                {
                    key: 'targetUsersRate',
                    type: 'simple',
                    align: 'right',
                    width: 120,
                    render: (row, rowIndex) => row.targetUsersRate.toFixed(1) + ' %',
                    color: '#333',
                    background: '#dbeafe',
                    backgroundWidth: (row) => row.targetUsersRate / 100,
                    title: '対象者'
                },
                {
                    key: 'lift',
                    type: 'simple',
                    align: 'right',
                    width: 80,
                    title: 'スコア',
                    render: (row, rowIndex) => row.lift.toFixed(2) + ' pt',
                    background: (row, rowIndex) => {
                        const color = palette(row.lift);
                        return color;
                    }
                }
            ]
        } as ChartOptionUnion;
    }, [data, fields]);

    return (
        <DashboardCard
            size="small"
            title={title}
            error={error}
            loading={loading}
            data={table?.data}
            chartHeight={table ? ((table?.data?.length ?? 0) + 1) * 40 : 200}
            chartOption={table}
            warn={
                <>
                    {data?.validState === 'WARNING' && (
                        <Alert type="warning" message="サンプル数が少ないため表示されている値は参考値になります。" />
                    )}
                    {data?.validState === 'INVALID' && (
                        <Alert type="error" message="サンプル数が少ないため、表示できません。" />
                    )}
                </>
            }
        />
    );
};

export const ShoppingPage: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid, enqversion } = useExtractParam();
    const { data, loading, error } = useShoppingQuery({
        variables: { extractid, filter, clusterid }
    });

    return (
        <div className="slim-scroll" style={{ height: '100%' }}>
            <div style={{ padding: 12 }}>
                <div style={{ marginBottom: 12 }}>
                    <Filter />
                </div>
                <Row gutter={12}>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <ShoppingCard
                            title="購入チャネル"
                            loading={loading}
                            error={error}
                            data={data?.extract?.channel}
                            fields={FIELDS[enqversion].channel}
                        />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <ShoppingCard
                            title="買い物時の行動"
                            loading={loading}
                            error={error}
                            data={data?.extract?.behavior}
                            fields={FIELDS[enqversion].behavior}
                        />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <ShoppingCard
                            title="魅力的な施策"
                            loading={loading}
                            error={error}
                            data={data?.extract?.measure}
                            fields={FIELDS[enqversion].measure}
                        />
                    </Col>
                    <Col span={12} style={{ marginBottom: 12 }}>
                        <ShoppingCard
                            title="よく利用するポイント"
                            loading={loading}
                            error={error}
                            data={data?.extract?.point}
                            fields={FIELDS[enqversion].point}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};
