import {
    bluePalette,
    colorPalette,
    colorPalette20,
    cyan,
    geekblue,
    grey,
    lightgeekblue,
    lightgrey,
    lightyellow,
    red,
    redPalette,
    yellow
} from '@/chartTheme';
import { DashboardCard } from '@/components/DashboardCard';
import { RaccoonChart } from '@/components/RaccoonChart';
import { CHILD, FAMILY, GENDER_AGE_BRACKET, HOME_INCOME, JOB, LIVING_AREA, LIVING_STYLE, MARRIAGE } from '@/constants';
import {
    useChildDataQuery,
    useFamilyDataQuery,
    useGenderAgeBracketsQuery,
    useHomeIncomeDataQuery,
    useJobsQuery,
    useLivingAreaDataQuery,
    useLivingStyleDataQuery,
    useMarriageDataQuery
} from '@/graphql/generated';
import { Alert } from 'antd';
import { scaleLinear } from 'd3';
import { CategoryColumnWithType, ChartOptionUnion, ValueColumnWithType } from 'raccoon-chart';
import React from 'react';
import { useExtractParam } from '../Context';

const TARGET: { [s: string]: string } = {
    monitor: 'ネット利用者全体',
    target: '対象者'
};

const HEIGHT = 200;

export const StackBarChart: React.FC<{
    color?: string[];
    stacks: string[];
    data: { [stack: string]: number[] };
    formatter: (s: string) => string;
}> = ({ color, stacks, data, formatter }) => {
    const chartData: any[] = [];
    stacks.forEach((stack) => {
        chartData.push({
            type: 'target',
            key: stack,
            value: data[stack][0]
        });

        chartData.push({
            type: 'monitor',
            key: stack,
            value: data[stack][1]
        });
    });

    return (
        <RaccoonChart
            height={HEIGHT}
            data={chartData}
            chartOption={{
                data: [],
                color: color
                    ? {
                          colors: color
                      }
                    : undefined,
                type: 'bar',
                stack: true,
                percent: true,
                layout: 'horizontal',
                xColumn: {
                    key: 'type',
                    title: '対象',
                    formatter: (s: string) => {
                        return TARGET[s];
                    }
                },
                seriesColumn: {
                    key: 'key',
                    title: '系列',
                    formatter
                },
                yColumn: {
                    key: 'value',
                    title: 'リーチ率',
                    min: 0
                }
            }}
        />
    );
};

const usersColumn: ValueColumnWithType = {
    type: 'value',
    key: 'value',
    title: 'リーチ率',
    min: 0,
    max: 100,
    formatter: (value) => {
        if (value == null) return '-';
        return value.toFixed(1) + ' %';
    },
    axisFormatter: (value) => {
        if (value == null) return '-';
        return value.toFixed(0) + ' %';
    }
};

const targetColumn: CategoryColumnWithType = {
    type: 'category',
    key: 'type',
    title: '対象',
    formatter: (s: string) => {
        return TARGET[s];
    }
};

export const makeStackChartOption = ({
    color,
    stacks,
    data,
    formatter
}: {
    color?: string[];
    stacks: string[];
    data: { [stack: string]: number[] };
    formatter: (s: string) => string;
}): ChartOptionUnion => {
    const seriesColumn: CategoryColumnWithType = {
        type: 'category',
        key: 'key',
        title: '系列',
        formatter
    };

    const chartData: any[] = [];
    stacks.forEach((stack) => {
        chartData.push({
            type: 'target',
            key: stack,
            value: data[stack][0]
        });

        chartData.push({
            type: 'monitor',
            key: stack,
            value: data[stack][1]
        });
    });

    return {
        type: 'bar',
        data: chartData,
        color: color
            ? {
                  colors: color
              }
            : undefined,
        stack: true,
        layout: 'horizontal',
        xColumn: targetColumn,
        seriesColumn,
        yColumn: usersColumn,
        dataColumns: [targetColumn, seriesColumn, usersColumn]
    } as ChartOptionUnion;
};

export const GenderAgeBracketCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useGenderAgeBracketsQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart: ChartOptionUnion = React.useMemo(() => {
        const chartData: { [s: string]: number[] } = {};
        Object.keys(GENDER_AGE_BRACKET).forEach((genderAgeBracket) => {
            chartData[genderAgeBracket] = ['target', 'monitor'].map((type) => {
                const find = data?.extract?.genderAgeBrackets.find(
                    (n) => n.type === type && genderAgeBracket === `${n.gender}-${n.ageBracket}`
                );

                return find?.usersRate || 0;
            });
        });

        return makeStackChartOption({
            color: [...[...bluePalette], ...[...redPalette]],
            stacks: Object.keys(GENDER_AGE_BRACKET),
            data: chartData,
            formatter: (name) => {
                return GENDER_AGE_BRACKET[name || ''];
            }
        });
    }, [data]);

    return (
        <DashboardCard
            size="small"
            title="性年代"
            data={chart?.data}
            chartOption={chart}
            chartHeight={HEIGHT}
            loading={loading}
            error={error}
        />
    );
};

export const JobCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useJobsQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart = React.useMemo(() => {
        const chartData: { [s: string]: number[] } = {};
        Object.keys(JOB).forEach((job) => {
            chartData[job] = ['target', 'monitor'].map((type) => {
                const find = data?.extract?.jobs.find((n) => n.type === type && job === n.job);

                return find?.usersRate || 0;
            });
        });

        return makeStackChartOption({
            color: colorPalette20,
            stacks: Object.keys(JOB),
            data: chartData,
            formatter: (name) => {
                return JOB[name || ''];
            }
        });
    }, [data]);

    return (
        <DashboardCard
            size="small"
            title="職業"
            data={chart?.data}
            chartOption={chart}
            chartHeight={HEIGHT}
            loading={loading}
            error={error}
        />
    );
};

export const MarriageCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useMarriageDataQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart = React.useMemo(() => {
        const chartData: { [s: string]: number[] } = {};
        Object.keys(MARRIAGE).forEach((marriage) => {
            chartData[marriage] = ['target', 'monitor'].map((type) => {
                const find = data?.extract?.marriageData.find((n) => n.type === type && marriage === n.marriage);

                return find?.usersRate || 0;
            });
        });

        return makeStackChartOption({
            color: [geekblue, cyan],
            stacks: Object.keys(MARRIAGE),
            data: chartData,
            formatter: (name) => {
                return MARRIAGE[name || ''];
            }
        });
    }, [data]);

    return (
        <DashboardCard
            size="small"
            title="未既婚"
            data={chart?.data}
            chartOption={chart}
            chartHeight={HEIGHT}
            loading={loading}
            error={error}
        />
    );
};

export const ChildCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useChildDataQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart = React.useMemo(() => {
        const chartData: { [s: string]: number[] } = {};
        Object.keys(CHILD).forEach((child) => {
            chartData[child] = ['target', 'monitor'].map((type) => {
                const find = data?.extract?.childData.find((n) => n.type === type && child === n.child);

                return find?.usersRate || 0;
            });
        });

        return makeStackChartOption({
            color: [geekblue, cyan],
            stacks: Object.keys(CHILD),
            data: chartData,
            formatter: (name) => {
                return CHILD[name || ''];
            }
        });
    }, [data]);

    return (
        <DashboardCard
            size="small"
            title="子供有無"
            data={chart?.data}
            chartOption={chart}
            chartHeight={HEIGHT}
            loading={loading}
            error={error}
        />
    );
};

export const LivingAreaCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useLivingAreaDataQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart = React.useMemo(() => {
        const chartData: { [s: string]: number[] } = {};
        Object.keys(LIVING_AREA).forEach((livingArea) => {
            chartData[livingArea] = ['target', 'monitor'].map((type) => {
                const find = data?.extract?.livingAreaData.find((n) => n.type === type && livingArea === n.livingArea);

                return find?.usersRate || 0;
            });
        });

        return makeStackChartOption({
            color: colorPalette,
            stacks: Object.keys(LIVING_AREA),
            data: chartData,
            formatter: (name) => {
                return LIVING_AREA[name || ''];
            }
        });
    }, [data]);

    return (
        <DashboardCard
            size="small"
            title="居住地域"
            data={chart?.data}
            chartOption={chart}
            chartHeight={HEIGHT}
            loading={loading}
            error={error}
        />
    );
};

export const HomeIncomeCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useHomeIncomeDataQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart = React.useMemo(() => {
        const chartData: { [s: string]: number[] } = {};
        Object.keys(HOME_INCOME).forEach((homeIncome) => {
            chartData[homeIncome] = ['target', 'monitor'].map((type) => {
                const find = data?.extract?.homeIncomeData.find((n) => n.type === type && homeIncome === n.homeIncome);

                return find?.usersRate || 0;
            });
        });

        return makeStackChartOption({
            color: colorPalette20,
            stacks: Object.keys(HOME_INCOME),
            data: chartData,
            formatter: (name) => {
                return HOME_INCOME[name || ''];
            }
        });
    }, [data]);

    return (
        <DashboardCard
            size="small"
            title="世帯年収"
            data={chart?.data}
            chartOption={chart}
            chartHeight={HEIGHT}
            loading={loading}
            error={error}
        />
    );
};

export const FamilyCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useFamilyDataQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart: ChartOptionUnion | undefined = React.useMemo(() => {
        if (!data?.extract?.familyData) {
            return;
        }

        if (data?.extract?.familyData.validState === 'INVALID') {
            return;
        }

        const palette = scaleLinear<string>()
            .range([geekblue, lightgeekblue, cyan, grey, lightgrey, yellow, lightyellow, red])
            .domain([0, 1, 2, 3, 4, 5, 8, 9]);

        const chartData: { [s: string]: number[] } = {};
        Object.keys(FAMILY).forEach((value) => {
            chartData[value] = [
                data?.extract?.familyData.target.find((n) => n.value === value)?.usersRate || 0,
                data?.extract?.familyData.monitor.find((n) => n.value === value)?.usersRate || 0
            ];
        });

        return makeStackChartOption({
            color: new Array(10).fill(0).map((_, i) => {
                return palette(i);
            }),
            stacks: Object.keys(FAMILY),
            data: chartData,
            formatter: (name) => {
                return FAMILY[name || ''];
            }
        });
    }, [data]);

    return (
        <DashboardCard
            size="small"
            title="同居家族"
            data={chart?.data}
            chartOption={chart}
            chartHeight={HEIGHT}
            loading={loading}
            error={error}
            warn={
                <>
                    {data?.extract?.familyData.validState === 'WARNING' && (
                        <Alert type="warning" message="サンプル数が少ないため表示されている値は参考値になります。" />
                    )}
                    {data?.extract?.familyData.validState === 'INVALID' && (
                        <Alert type="error" message="サンプル数が少ないため、表示できません。" />
                    )}
                </>
            }
        />
    );
};

export const LivingStyleCard: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useLivingStyleDataQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart = React.useMemo(() => {
        if (!data?.extract?.livingStyleData) {
            return;
        }

        if (data?.extract?.livingStyleData.validState === 'INVALID') {
            return;
        }

        const chartData: { [s: string]: number[] } = {};
        Object.keys(LIVING_STYLE).forEach((value) => {
            chartData[value] = [
                data?.extract?.livingStyleData.target.find((n) => n.value === value)?.usersRate || 0,
                data?.extract?.livingStyleData.monitor.find((n) => n.value === value)?.usersRate || 0
            ];
        });

        return makeStackChartOption({
            color: colorPalette,
            stacks: Object.keys(LIVING_STYLE),
            data: chartData,
            formatter: (name) => {
                return LIVING_STYLE[name || ''];
            }
        });
    }, [data]);

    return (
        <DashboardCard
            size="small"
            title="居住形態"
            data={chart?.data}
            chartOption={chart}
            chartHeight={HEIGHT}
            loading={loading}
            error={error}
            warn={
                <>
                    {data?.extract?.livingStyleData.validState === 'WARNING' && (
                        <Alert type="warning" message="サンプル数が少ないため表示されている値は参考値になります。" />
                    )}
                    {data?.extract?.livingStyleData.validState === 'INVALID' && (
                        <Alert type="error" message="サンプル数が少ないため、表示できません。" />
                    )}
                </>
            }
        />
    );
};
