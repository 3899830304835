const theme = {
    // primaryColor: '#0375c1',
    primaryColor: '#3b82f6',
    linkColor: '#3b82f6',
    successColor: '#22c55e',
    warningColor: '#eab308',
    errorColor: '#ef4444',
    // primaryColor: '#5B8FF9',
    // linkColor: '#5B8FF9',
    // successColor: '#5AD8A6',
    // warningColor: '#F6BD16',
    // errorColor: '#E86452',
    // red: '#c34b67',
    // orange: '#db821b',
    // yellow: '#e8cb27',
    // olive: '#acbe34',
    // green: '#24a584',
    // blue: '#2c8dd6',
    // violet: '#6842b9',
    // purple: '#a054b9',
    // pink: '#de61a7',
    // brown: '#986c50',
    // gray: '#aaaaaa',

    // mainColor: '#666666',
    bgColor: '#f1f5f9',
    // borderColor: '#dddddd',
    // headerBgColor: '#2674ba',
    // headerColor: '#ffffff',
    // siderBgColor: '#001326',
    // siderColor: '#ffffff',
    // linkColor: '#42b850',
    // headingColor: '#666',
    // textColor: '#666',
    // disabledColor: '#e5e7eb',
    borderColorBase: '#d1d5db',
    headingColor: '#111827',
    textColor: '#374151',
    textColorSecondary: '#6b7280',
    textColorDark: '#fff',
    textColorSecondaryDark: 'rgba(255, 255, 255, 0.65)',
    layoutHeaderHeight: '40px',
    // layoutHeaderBackground: 'linear-gradient(135deg, #4ca4df 0%, #0375c1 100%)',
    layoutHeaderBackground: '#0369a1',
    layoutHeaderColor: '#fff',
    layoutBodyBackground: '#f1f5f9',
    layoutSiderBackground: '#1e293b',
    modalContentBg: '#f1f5f9',
    menuDarkBg: '#1e293b',
    menuDarkInlineSubmenuBg: '#1e293b',
    // menuDarkItemActiveBg: 'rgba(255,255,255,0.2)',
    fontFamily: "'Roboto','Noto Sans JP', sans-serif"
};

// export default theme;
module.exports = theme;
