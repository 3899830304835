import { cyan, geekblue } from '@/chartTheme';
import { DashboardCard } from '@/components/DashboardCard';
import { useGetTrendQuery } from '@/graphql/generated';
import { makeYws, parseYm } from '@/utils/parseYm';
import { CategoryColumnWithType, ChartOptionUnion, ValueColumnWithType } from 'raccoon-chart';
import React from 'react';
import { useExtractParam } from '../../Context';

export const logType2Text: {
    [s: string]: string;
} = {
    browser: 'ブラウザ',
    app: 'アプリ'
};

export const TrendCard: React.FC<{}> = () => {
    const {
        extractid,
        filter,
        clusterid,
        extract: { startYm, endYm }
    } = useExtractParam();
    const { data, loading, error } = useGetTrendQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart: ChartOptionUnion | undefined = React.useMemo(() => {
        if (!data?.extract) {
            return undefined;
        }

        const yws = makeYws(parseYm(startYm), parseYm(endYm));

        const map = new Map<string, string>();

        yws.forEach((yw) => {
            map.set(yw.format('GGGG-WW'), yw.format('YYYY-MM-DD'));
        });

        const dateColumn: CategoryColumnWithType = {
            type: 'category',
            key: 'date',
            title: '日付(週頭)',
            domain: yws.map((yw) => yw.format('GGGG-WW')),
            formatter: (date: string) => {
                return map.get(date) || '';
            }
        };

        const sessionColumn: ValueColumnWithType = {
            type: 'value',
            key: 'sessionsRate',
            title: 'セッション数比率',
            formatter: (value) => {
                if (value == null) return '-';
                return value.toPercent().toFixed(1) + ' %';
            },
            axisFormatter: (value) => {
                if (value == null) return '-';
                return value.toPercent().toFixed(0) + ' %';
            },
            min: 0
        };

        const logTypeColumn: CategoryColumnWithType = {
            type: 'category',
            key: 'type',
            title: 'ログタイプ',
            domain: ['browser', 'app'],
            formatter: (type: string) => {
                return logType2Text[type] || '';
            }
        };

        return {
            data: [],
            type: 'line',
            smooth: true,
            color: { colors: [geekblue, cyan] },
            stack: true,
            xColumn: dateColumn,
            yColumn: sessionColumn,
            seriesColumn: logTypeColumn,
            xAxis: {
                showTitle: true
            },
            yAxis: {
                showTitle: true
            },
            dataColumns: [logTypeColumn, dateColumn, sessionColumn]
        } as ChartOptionUnion;
    }, [data, endYm, startYm]);

    return (
        <DashboardCard
            error={error}
            data={data?.extract?.trend}
            size="small"
            loading={loading}
            title="周辺セッション数推移"
            chartOption={chart}
            chartHeight={400}
        />
    );
};
