import { useGeneralLandingPagesQuery } from '@/graphql/generated';
import { useExtractParam } from '@/pages/Extract/Context';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import styles from './index.module.less';

export const ExpandRowLandingPages: React.FC<{
    site?: string;
    word?: string;
}> = ({ site, word }) => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useGeneralLandingPagesQuery({
        variables: {
            extractid,
            site,
            word,
            filter,
            clusterid
        }
    });

    const columns: ColumnProps<any>[] = [
        {
            title: 'No.',
            dataIndex: 'index',
            width: 80,
            align: 'center',
            render(_, record, index) {
                return <span>{index + 1}.</span>;
            }
        },
        {
            title: 'ランディングページ',
            dataIndex: 'page',
            render(_, record) {
                return (
                    <div style={{ lineHeight: 1 }}>
                        <div className={styles.title}>{record.title}</div>
                        <div className={styles.page}>
                            <a rel="noreferrer" target="_blank" href={`https://${record.landingPage}`}>
                                {record.landingPage}
                            </a>
                        </div>
                    </div>
                );
            }
        },
        {
            title: 'リーチ率',
            dataIndex: 'usersRate',
            align: 'right',
            width: 120,
            render(_, record) {
                return <span>{record.usersRate.toPercent().toFixed(2)} %</span>;
            }
        }
    ];

    return (
        <>
            <Table
                bordered
                tableLayout="fixed"
                style={{ width: '100%' }}
                scroll={{ y: 400 }}
                loading={loading}
                rowKey="page"
                size="small"
                dataSource={data?.extract?.general.landingPages || []}
                columns={columns}
                pagination={false}
            />
        </>
    );
};
