export const LIVING_STYLE: {
    [s: string]: string;
} = {
    1: '一戸建て（持ち家）',
    2: '一戸建て（賃貸）',
    3: 'マンション（持ち家）',
    4: 'マンション（賃貸）',
    5: 'アパート（賃貸）',
    6: '社宅・寮など',
    7: 'その他'
};
