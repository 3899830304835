import { DashboardCard } from '@/components/DashboardCard';
import { useGetWeekdayHourQuery } from '@/graphql/generated';
import { percentFormatters } from '@/server/utils/chartUtils';
import { CategoryColumnWithType, ChartOptionUnion, ValueColumnWithType } from 'raccoon-chart';
import React from 'react';
import { useExtractParam } from '../../Context';

const DAY_OF_WEEK: { [s: string]: string } = {
    1: '日',
    2: '月',
    3: '火',
    4: '水',
    5: '木',
    6: '金',
    7: '土'
};

const weekdayColumn: CategoryColumnWithType = {
    type: 'category',
    key: 'weekday',
    title: '曜日',
    domain: new Array(7).fill(0).map((_, i) => String(i + 1)),
    formatter: (weekday: string) => {
        return DAY_OF_WEEK[weekday];
    }
};

const hourColumn: CategoryColumnWithType = {
    type: 'category',
    key: 'hour',
    title: '時間',
    domain: new Array(24)
        .fill(0)
        .map((_, i) => String(i))
        .reverse(),
    formatter: (hour: string) => {
        return `${hour}`;
    }
};

const sessionColumn: ValueColumnWithType = {
    type: 'value',
    key: 'sessionsRate',
    title: 'セッション数比率',
    ...percentFormatters,
    min: 0
};

export const TimeCard: React.FC<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useGetWeekdayHourQuery({
        variables: { extractid, filter, clusterid }
    });

    const chart: ChartOptionUnion = React.useMemo(() => {
        return {
            data: [],
            error,
            type: 'heatMap',
            color: {
                colors: [
                    '#eff6ff',
                    '#dbeafe',
                    '#bfdbfe',
                    '#93c5fd',
                    '#60a5fa',
                    '#3b82f6',
                    '#2563eb',
                    '#1d4ed8',
                    '#1e40af',
                    '#1e3a8a'
                ]
            },
            xColumn: weekdayColumn,
            yColumn: hourColumn,
            colorColumn: sessionColumn,
            dataColumns: [weekdayColumn, hourColumn, sessionColumn],
            xAxis: {
                showTitle: true
            },
            yAxis: {
                showTitle: true
            },
            legend: {
                position: 'bottom',
                show: false
            }
        };
    }, [error]);

    return (
        <DashboardCard
            error={error}
            size="small"
            data={data?.extract?.weekdayHour}
            loading={loading}
            title="曜日・時間別"
            chartOption={chart}
            chartHeight={400}
        />
    );
};
