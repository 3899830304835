export const SPORTS_WATCH: {
    [s: string]: string;
} = {
    1: '海外プロ野球',
    2: '国内プロ野球',
    3: '高校野球',
    4: '海外サッカー',
    5: '国内サッカー',
    6: 'サッカー日本代表',
    7: 'ラグビー',
    8: 'アメリカンフットボール',
    9: 'バスケットボール',
    10: 'バレーボール',
    11: 'ソフトボール',
    12: 'テニス',
    13: '卓球',
    14: 'プロボウリング',
    15: 'ゴルフ',
    16: 'モータースポーツ',
    17: 'スキー競技',
    18: 'スノーボード競技',
    19: 'フィギュアスケート',
    20: 'スピードスケート',
    21: 'マラソン',
    22: '陸上競技',
    23: '水泳',
    24: '自転車競技',
    25: 'プロレス',
    26: 'ボクシング',
    27: '格闘技',
    28: '大相撲',
    29: '体操・新体操',
};

export const SPORTS_PLAY: {
    [s: string]: string;
} = {
    1: '野球',
    2: 'サッカー・フットサル',
    3: 'バスケットボール',
    4: 'バレーボール',
    5: 'ソフトボール',
    6: 'テニス',
    7: '卓球',
    8: 'ゴルフ',
    9: 'スキー',
    10: 'スノーボード',
    11: 'アイススケート',
    12: 'ジョギング・マラソン',
    13: 'ウォーキング',
    14: '水泳',
    15: '自転車',
    16: 'ボウリング',
    17: 'スポーツジムでのトレーニング、エクササイズ',
    18: 'サーフィン・ウインドサーフィン',
    19: 'ダイビング・スキューバダイビング',
    20: 'ダンス',
    21: 'バドミントン',
    22: 'ヨガ・ホットヨガ',
};