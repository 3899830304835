import axios from 'axios';
import { tryToGetToken } from '@/utils/auth';

export const request = axios.create({
    baseURL: '/api',
});

request.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

request.interceptors.response.use(
    (response) => {
        const res = response.data;
        return res;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 401) {
                tryToGetToken();
                return Promise.reject(error);
            }
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
    }
);

export default request;
