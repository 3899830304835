import { DashboardCard } from '@/components/DashboardCard';
import { useBeforeAfterWordQuery } from '@/graphql/generated';
import { CaretRightOutlined } from '@ant-design/icons';
import { Modal, Tabs } from 'antd';
import { CategoryColumnWithType, ChartOptionUnion } from 'raccoon-chart';
import React from 'react';
import { LandingPage, ReferralKeyword, SiteList } from '.';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';

const DataTable: React.FC<{
    beforeAfter: number;
    active: {
        word: string;
        befaft: number;
    } | null;
    setActive: React.Dispatch<
        React.SetStateAction<{
            word: string;
            befaft: number;
        } | null>
    >;
}> = ({ beforeAfter, active, setActive }) => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useBeforeAfterWordQuery({
        variables: {
            extractid,
            filter,
            clusterid,
            beforeAfter,
            limit: 100
        }
    });

    const title = React.useMemo(() => {
        switch (beforeAfter) {
            case 1:
                return '前半';
            case 2:
                return '中間';
            case 3:
                return '後半';
        }
    }, [beforeAfter]);

    const columns = React.useMemo(() => {
        return [
            {
                type: 'simple' as 'simple',
                width: 40,
                key: 'index',
                title: '#',
                align: 'center' as 'center',
                render: (row: any, rowIndex: number) => {
                    return `${rowIndex + 1}.`;
                }
            },
            {
                type: 'svg',
                svg: `
                    <svg
                        stroke="#333"
                        fill="#333"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        height="12"
                        width="12"
                    >
                        <path d="M881 442.4H519.7v148.5h206.4c-8.9 48-35.9 88.6-76.6 115.8-34.4 23-78.3 36.6-129.9 36.6-99.9 0-184.4-67.5-214.6-158.2-7.6-23-12-47.6-12-72.9s4.4-49.9 12-72.9c30.3-90.6 114.8-158.1 214.7-158.1 56.3 0 106.8 19.4 146.6 57.4l110-110.1c-66.5-62-153.2-100-256.6-100-149.9 0-279.6 86-342.7 211.4-26 51.8-40.8 110.4-40.8 172.4S151 632.8 177 684.6C240.1 810 369.8 896 519.7 896c103.6 0 190.4-34.4 253.8-93 72.5-66.8 114.4-165.2 114.4-282.1 0-27.2-2.4-53.3-6.9-78.5z"></path>
                    </svg>
                `,
                size: { width: 12, height: 12 },
                width: 24,
                onClick: (row: any) => {
                    window.open(`https://www.google.com/search?q=${row.word}`, '_blank');
                }
            },
            {
                type: 'simple' as 'simple',
                key: 'chname',
                title: 'キーワード',
                align: 'left' as 'left',
                render: (row: any) => {
                    return row.word;
                }
            },
            {
                type: 'simple' as 'simple',
                width: 72,
                key: 'usersRate',
                title: 'リーチ率',
                align: 'right' as 'right',
                render: (row: { usersRate: number }) => {
                    return row.usersRate.toPercent().toFixed(2) + ' %';
                }
            },
            // {
            //     type: 'simple' as 'simple',
            //     width: 72,
            //     key: 'appearRate',
            //     title: '出現率',
            //     align: 'right' as 'right',
            //     render: (row: { cnt: number }) => {
            //         return row.cnt.toPercent().toFixed(2) + ' %';
            //     }
            // },
            {
                type: 'simple' as 'simple',
                width: 72,
                key: 'score',
                title: 'スコア',
                align: 'right' as 'right',
                render: (row: { score: number }) => {
                    return row.score.toPercent().toFixed(1) + ' pt';
                }
            },
            {
                type: 'svg',
                svg: `
                    <svg
                        stroke="#333"
                        fill="#333"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="12"
                        width="12"
                    >
                        <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
                        <path d="M11 11h2v6h-2zm0-4h2v2h-2z"></path>
                    </svg>
                `,
                size: { width: 12, height: 12 },
                width: 48,
                onClick: (row: any) => {
                    setActive({
                        word: row.word,
                        befaft: beforeAfter
                    });
                }
            }
        ];
    }, [beforeAfter, setActive]);

    const chart = React.useMemo(() => {
        return {
            type: 'dataTable',
            data: [],
            dataColumns: [
                { type: 'category', key: 'word', title: 'キーワード' } as CategoryColumnWithType,
                {
                    type: 'value',
                    key: 'usersRate',
                    title: 'リーチ率',
                    formatter: (usersRate: number) => {
                        return usersRate.toPercent().toFixed(2) + ' %';
                    }
                },
                {
                    type: 'value',
                    key: 'score',
                    title: 'スコア',
                    formatter: (score: number) => {
                        return score.toPercent().toFixed(1) + ' pt';
                    }
                }
            ],
            columns
        } as ChartOptionUnion;
    }, [columns]);

    return (
        <DashboardCard
            size="small"
            title={title}
            height={'100%'}
            loading={loading}
            data={data?.extract?.beforeAfterWord}
            error={error}
            chartOption={chart}
        />
    );
};

export const BeforeAfterWordPage: React.FunctionComponent<{}> = () => {
    const [active, setActive] = React.useState<{ word: string; befaft: number } | null>(null);

    return (
        <div style={{ height: '100%' }}>
            <div style={{ padding: 12, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ marginBottom: 12 }}>
                    <Filter />
                </div>

                <Modal
                    visible={!!active}
                    width={800}
                    onCancel={() => {
                        setActive(null);
                    }}
                    title="詳細"
                    footer={null}
                >
                    <div style={{ background: '#fff', padding: 12 }}>
                        {active && (
                            <Tabs type="line">
                                <Tabs.TabPane tab="流入サイト" key="site">
                                    <SiteList word={active.word} befaft={active.befaft} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="ランディングページ" key="landingPage">
                                    <LandingPage word={active.word} befaft={active.befaft} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="掛け合わせワード" key="referralKeyword">
                                    <ReferralKeyword word={active.word} befaft={active.befaft} />
                                </Tabs.TabPane>
                                {/* <Tabs.TabPane tab="フロー（β）" key="flow">
                                    <SiteSankey word={active.word} befaft={active.befaft} />
                                </Tabs.TabPane> */}
                            </Tabs>
                        )}
                    </div>
                </Modal>

                <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                        <DataTable {...{ beforeAfter: 1, active, setActive }} />
                    </div>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <CaretRightOutlined style={{ margin: 'auto', fontSize: 32 }} />
                    </div>
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                        <DataTable {...{ beforeAfter: 2, active, setActive }} />
                    </div>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <CaretRightOutlined style={{ margin: 'auto', fontSize: 32 }} />
                    </div>
                    <div style={{ flex: 1, overflow: 'hidden' }}>
                        <DataTable {...{ beforeAfter: 3, active, setActive }} />
                    </div>
                </div>
            </div>
        </div>
    );
};
