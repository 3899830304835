import useRequest from '@/utils/useRequest';
import { Select, Spin } from 'antd';
import React from 'react';

const { Option } = Select;

interface Props {
    disabled?: boolean;
    value?: string[];
    onChange?: (value: string[]) => void;
    style?: React.CSSProperties;
}

export const AppCategorySelect: React.FC<Props> = ({ disabled, value, onChange, style }) => {
    const { data, loading, api } = useRequest<any[]>('/api/appCategory');

    React.useEffect(() => {
        api({});
    }, [value, api]);

    return (
        <Select
            size="middle"
            mode="multiple"
            style={style}
            value={value}
            onChange={onChange}
            listItemHeight={40}
            loading={loading}
            showSearch
            placeholder="アプリカテゴリ"
            disabled={disabled}
            defaultActiveFirstOption={false}
            allowClear
            notFoundContent={null}
        >
            {loading && (
                <div style={{ textAlign: 'center', pointerEvents: 'none' }}>
                    <Spin spinning={true} size="small" />
                </div>
            )}
            {data?.map((n) => {
                return (
                    <Option key={n.appCategory} value={n.appCategory}>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            {n.appCategory}
                        </div>
                    </Option>
                );
            })}
        </Select>
    );
};
