import Favicon from '@/components/Favicon';
import { ExtractClusterSite } from '@/graphql/generated';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import styles from './index.module.less';

export const SiteCard: React.FC<{
    loading?: boolean;
    title: string;
    sites: ExtractClusterSite[];
    color: string;
}> = ({ loading, title, sites, color }) => {
    const table = React.useMemo(() => {
        const columns: ColumnProps<ExtractClusterSite>[] = [
            {
                title: '',
                dataIndex: 'favicon',
                width: 40,
                render(_, record) {
                    return <Favicon site={record.site} style={{ margin: 'auto' }} />;
                }
            },
            {
                title: 'サイト',
                dataIndex: 'site',
                render(_, record) {
                    return (
                        <div style={{ lineHeight: 1 }}>
                            <div className={styles.title}>{record.title}</div>
                            <div className={styles.site}>
                                <a rel="noreferrer" target="_blank" href={`https://${record.site}`}>
                                    {record.site}
                                </a>
                            </div>
                        </div>
                    );
                }
            },
            {
                title: '特徴値',
                dataIndex: 'score',
                align: 'right',
                width: 120,
                render(_, record) {
                    return <span>{record.score.toFixed(2)} pt</span>;
                }
            }
        ];

        return (
            <Table
                size="small"
                tableLayout="fixed"
                columns={columns}
                dataSource={sites}
                pagination={false}
                rowKey="site"
            />
        );
    }, [sites]);

    return table;
};
