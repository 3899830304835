import { textColorSecondary } from '@/theme';
import useRequest from '@/utils/useRequest';
import { Spin } from 'antd';
import React from 'react';

interface Props {
    disabled?: boolean;
    value?: string;
    onChange?: (value: string) => void;
    onDelete: () => void;
}

export const ItemSearch: React.FC<Props> = ({ disabled, value, onChange, onDelete }) => {
    const { data, loading, api } = useRequest<any>('/api/item');

    React.useEffect(() => {
        if (!value) {
            return;
        }

        api({
            asin: value
        });
    }, [value, api]);

    return (
        <div>
            <div
                style={{
                    overflow: 'hidden',
                    width: '100%',
                    padding: 12,
                    border: '1px solid #eee',
                    position: 'relative'
                }}
            >
                <Spin spinning={loading}>
                    {value && (
                        <>
                            <div
                                style={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    color: textColorSecondary,
                                    fontSize: 12
                                }}
                            >
                                {[data?.cat1, data?.cat2, data?.cat3].filter((n) => n).join(' > ')}
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                <a target="_blank" rel="noreferrer" href={`https://amazon.co.jp/dp/${value}`}>
                                    {data?.itemname}
                                </a>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                ASIN: {value}
                            </div>
                        </>
                    )}
                </Spin>
            </div>
        </div>
    );
};
