import moment from 'moment';

export const parseYm = (ym: string) => {
    const year = ym.substr(0, 4);
    const month = ym.substr(4, 2);

    return moment({
        year: Number(year),
        month: Number(month) - 1,
        date: 1
    });
};

export const makeYms = (startYm: moment.Moment, endYm: moment.Moment) => {
    const yms = [];
    const m = startYm.clone();
    const end = endYm.clone().add(1, 'months');
    while (m < end) {
        yms.push(m.clone());
        m.add(1, 'months');
    }

    return yms;
};

export const makeYws = (start: moment.Moment, end: moment.Moment) => {
    const yws = [];
    const m = start.clone().add(-start.weekday() + 1, 'days');
    const e = end.clone().add(1, 'month');
    while (m < e) {
        yws.push(m.clone());
        m.add(1, 'week');
    }

    return yws;
};

export const makeYmds = (startYm: moment.Moment, endYm: moment.Moment) => {
    const ymds = [];
    const m = startYm.clone();
    const end = endYm.clone().add(1, 'months');
    while (m < end) {
        ymds.push(m.clone());
        m.add(1, 'days');
    }

    return ymds;
};
