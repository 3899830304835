import Layout from '@/layouts/BasicLayout';
import ExtractPage from '@/pages/Extract';
import { HomePage } from '@/pages/Home';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import './App.less';
import { AuthProvider } from './contexts/Auth';
import { GlobalProvider } from './contexts/Global';
import { logger } from './utils/logger';

const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

Spin.setDefaultIndicator(antIcon);

// for pagevisit
const PageVisitMeasure = () => {
    const location = useLocation();
    const routeMatch = useRouteMatch<{ extractid?: string }>();

    React.useEffect(() => {
        logger({
            url: location.pathname,
            action: 'PAGE_VISIT',
            extractid: routeMatch.params.extractid
        });
    }, [location, routeMatch]);

    return <></>;
};

function App() {
    // const location =
    // const routeMatch = useRouteMatch();

    const redirect = React.useCallback(() => {
        return <Redirect to={{ pathname: '/browsingstory' }} />;
    }, []);

    return (
        <BrowserRouter>
            <AuthProvider>
                <GlobalProvider>
                    <PageVisitMeasure />
                    <Layout>
                        <React.Suspense fallback={<div />}>
                            <Switch>
                                <Route exact path="/browsingstory" component={HomePage} />
                                <Route path="/browsingstory/:extractid" component={ExtractPage} />
                                <Route component={redirect} />
                            </Switch>
                        </React.Suspense>
                    </Layout>
                </GlobalProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
