import {
    ExtractInfoQuery,
    useClustersLazyQuery,
    useExtractInfoLazyQuery,
    useUsersLazyQuery
} from '@/graphql/generated';
import { Alert } from 'antd';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

export type Params = {
    extractid: number;
};

export type Filter = any | null;

export const Context = React.createContext<{
    extractid: number;
    enqversion: number;
    extract: Exclude<ExtractInfoQuery['extract'], null | undefined>;
    filter: Filter;
    setFilter: (f: Filter) => void;
    clusterid: number | null;
    setClusterid: (clusterid: number | null) => void;
    showAsText: boolean;
    setShowAsText: (b: boolean) => void;
    refetch: any;
    users: number[] | null;
    clusters:
        | {
              clusterid: number;
              name: string;
              users: number;
          }[]
        | null;
    fetchClusters: () => void;
}>(undefined!);

export const useExtractParam = () => {
    return React.useContext(Context);
};

export const Provider: React.FunctionComponent<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [fetch, { data, loading, error, refetch }] = useExtractInfoLazyQuery();
    const [fetchUsers, { data: usersData }] = useUsersLazyQuery();
    const [fetchClusters, { data: clusterData }] = useClustersLazyQuery();

    const [extractid, setExtractid] = React.useState<number | null>(null);
    const [filter, setFilter] = React.useState<Filter>(null);
    const [clusterid, setClusterid] = React.useState<number | null>(null);
    const routeMatch = useRouteMatch<{ extractid?: string }>();
    const [showAsText, setShowAsText] = React.useState<boolean>(false);

    const _fetchClusters = React.useCallback(() => {
        if (extractid) {
            fetchClusters({ variables: { extractid } });
        }
    }, [fetchClusters, extractid]);

    React.useEffect(() => {
        if (!routeMatch.params.extractid) {
            return;
        }

        const extractid = Number(routeMatch.params.extractid);
        setExtractid(extractid);
    }, []);

    React.useEffect(() => {
        if (extractid) {
            fetch({ variables: { extractid } });
        }
    }, [extractid, fetch, fetchUsers]);

    React.useEffect(() => {
        if (extractid) {
            fetchClusters({ variables: { extractid } });
        }
    }, [extractid, fetchClusters]);

    React.useEffect(() => {
        if (extractid) {
            fetchUsers({ variables: { extractid, filter, clusterid } });
        }
    }, [clusterid, extractid, fetchUsers, filter]);

    if (loading) {
        <div>Loading...</div>;
    }

    if (data) {
        if (!data.extract) {
            return (
                <div style={{ padding: 12 }}>
                    <Alert type="error" message="レポートが存在していないか、閲覧権限がありません。" />
                </div>
            );
        }

        return (
            <Context.Provider
                value={{
                    extractid: data.extract.extractid,
                    extract: data.extract,
                    enqversion: data.extract.enqversion,
                    filter,
                    setFilter,
                    clusterid,
                    setClusterid,
                    showAsText,
                    setShowAsText,
                    refetch,
                    users: usersData?.extract?.users ?? null,
                    clusters: clusterData?.extract?.cluster.clusters ?? null,
                    fetchClusters: _fetchClusters
                }}
            >
                {children}
            </Context.Provider>
        );
    }

    return <></>;
};
