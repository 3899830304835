import { RaccoonChart } from '@/components/RaccoonChart';
import { primaryColor, textColorSecondary } from '@/theme';
import { BarChartOutlined, CopyOutlined, DownloadOutlined, FileTextOutlined } from '@ant-design/icons';
import { CardProps, message } from 'antd';
import { ChartOptionUnion, RaccoonChart as RChart } from 'raccoon-chart';
import React from 'react';
import styles from './index.module.less';

export interface DashboardCardProps extends CardProps {
    data?: any[] | null;
    loading?: boolean;
    error?: any;
    warn?: React.ReactNode;
    chartHeight?: string | number;
    chartOption?: ChartOptionUnion;
    text?: string;
    height?: string | number;
    wrapCard?: boolean;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
    children,
    data,
    loading,
    error,
    chartOption,
    chartHeight,
    extra,
    text,
    warn,
    title,
    height,
    wrapCard = true,
    ...props
}) => {
    const chart = React.useRef<RChart | null>(null);

    const [showType, setShowType] = React.useState<'chart' | 'text'>('chart');

    const content = React.useMemo(() => {
        return (
            <div style={{ background: '#fff', width: '100%', flex: 1, overflow: 'hidden' }}>
                <RaccoonChart
                    showType={showType}
                    chart={chart}
                    data={data ?? undefined}
                    loading={loading}
                    error={error ? 'エラーが発生しました' : data?.length === 0 ? 'データがありません' : null}
                    height={chartHeight ?? '100%'}
                    chartOption={chartOption}
                />
            </div>
        );
    }, [chartHeight, chartOption, data, error, loading, showType]);

    if (wrapCard === false) {
        return content;
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height,
                background: '#fff',
                border: '1px solid #f0f0f0'
            }}
        >
            <div
                style={{
                    padding: '8px 12px',
                    borderBottom: '1px solid #f0f0f0',
                    display: 'flex'
                }}
            >
                <span style={{ flex: 1, overflow: 'hidden' }}>{title}</span>
                {extra}
                <span className={styles.extra} style={{ color: textColorSecondary }}>
                    <BarChartOutlined
                        onClick={() => {
                            setShowType('chart');
                        }}
                        style={
                            showType === 'chart'
                                ? {
                                      color: primaryColor
                                  }
                                : undefined
                        }
                    />
                    <FileTextOutlined
                        onClick={() => {
                            setShowType('text');
                        }}
                        style={
                            showType === 'text'
                                ? {
                                      color: primaryColor
                                  }
                                : undefined
                        }
                    />
                    <DownloadOutlined
                        onClick={async () => {
                            try {
                                const image = await chart.current?.exportDataUrl('png');
                                if (image) {
                                    const a = document.createElement('a');
                                    a.href = image;
                                    a.setAttribute('download', `image.png`);
                                    a.dispatchEvent(new MouseEvent('click'));
                                }
                            } catch (e) {
                                console.log(e);
                            }
                        }}
                    />
                    <CopyOutlined
                        onClick={async () => {
                            try {
                                const dataUrl = await chart.current?.exportDataUrl('png');
                                if (dataUrl) {
                                    const base64 = dataUrl.split(',')[1] ?? '';
                                    const buf = Buffer.from(base64, 'base64');
                                    const blob = new Blob([buf], {
                                        type: 'image/png'
                                    });

                                    if (navigator.clipboard.write && window.ClipboardItem) {
                                        navigator.clipboard.write([
                                            new window.ClipboardItem({
                                                'image/png': blob as any
                                            })
                                        ]);

                                        message.success('クリップボードにコピーしました。');
                                    }
                                }
                            } catch (e) {
                                console.log(e);
                            }
                        }}
                    />
                </span>
            </div>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    overflow: 'hidden',
                    padding: 12
                }}
            >
                {warn}
                {/* {error && <Alert type="error" message="エラーが発生しました。" />} */}
                {content}
            </div>
        </div>
    );
};
