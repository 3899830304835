import { ExpandRowAttributes } from '@/components/tableComponents/Attributes';
import { ExpandRowLandingPages } from '@/components/tableComponents/LandingPages';
import { ExpandRowTrend } from '@/components/tableComponents/Trend';
import { ExpandRowWords } from '@/components/tableComponents/Words';
import { ExtractWord, useWordRankingQuery } from '@/graphql/generated';
import { downloadFile } from '@/utils/downloadFile';
import { logger } from '@/utils/logger';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Table, Tabs } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';
import { useExtractParam } from '../Context';
import { Filter } from '../FilterModal';
import styles from './index.module.less';

const { TabPane } = Tabs;

export const WordRankingPage: React.FunctionComponent<{}> = () => {
    const { extractid, filter, clusterid } = useExtractParam();
    const { data, loading, error } = useWordRankingQuery({
        variables: {
            extractid,
            filter,
            clusterid
        }
    });

    const [expandedKeys, setExpandedKeys] = React.useState<string[]>([]);

    const columns: ColumnProps<ExtractWord>[] = [
        {
            title: 'No.',
            dataIndex: 'index',
            width: 80,
            align: 'center',
            render(_, record, index) {
                return <span>{index + 1}.</span>;
            }
        },
        {
            title: 'ワード',
            dataIndex: 'word',
            render(_, record) {
                return <span>{record.word}</span>;
            }
        },
        {
            title: 'リーチ率',
            dataIndex: 'usersRate',
            align: 'right',
            width: 120,
            render(_, record) {
                return <span>{record.usersRate.toFixed(2)} %</span>;
            }
        },
        {
            title: '特徴値',
            dataIndex: 'score',
            align: 'right',
            width: 120,
            render(_, record) {
                return <span>{record.score.toFixed(2)} pt</span>;
            }
        }
    ];

    return (
        <div className="slim-scroll" style={{ height: '100%' }}>
            <div style={{ padding: 12 }}>
                <div style={{ marginBottom: 12 }}>
                    <Filter />
                    <Button
                        style={{ marginLeft: 12 }}
                        onClick={() => {
                            logger({
                                url: window.location.pathname,
                                action: 'DOWNLOAD',
                                extractid,
                                actionParam: {
                                    filter,
                                    clusterid,
                                    type: 'word'
                                }
                            });

                            downloadFile({
                                fileName: `ワードランキング - ${extractid}`,
                                headers: ['ワード', 'リーチ率', '特徴値'],
                                data:
                                    data?.extract?.words.map(({ word, usersRate, score }) => {
                                        return [word, usersRate.toFixed(2), score.toFixed(2)];
                                    }) || []
                            });
                        }}
                    >
                        <DownloadOutlined />
                        ダウンロード
                    </Button>
                </div>
                <Card size="small" loading={loading}>
                    <Table
                        className={styles.table}
                        bordered
                        tableLayout="fixed"
                        rowKey="word"
                        size="small"
                        dataSource={data?.extract?.words || []}
                        columns={columns}
                        pagination={false}
                        expandable={{
                            expandedRowKeys: expandedKeys,
                            onExpandedRowsChange: (keys) => {
                                setExpandedKeys([keys[keys.length - 1] as string]);
                            },
                            expandedRowRender: (record) => (
                                <div>
                                    <Tabs type="card">
                                        <TabPane tab="推移" key="trend">
                                            <ExpandRowTrend word={record.word} />
                                        </TabPane>
                                        <TabPane tab="属性" key="attributes">
                                            <ExpandRowAttributes word={record.word} />
                                        </TabPane>
                                        <TabPane tab="ランディングページ" key="landingPage">
                                            <ExpandRowLandingPages word={record.word} />
                                        </TabPane>
                                        <TabPane tab="掛け合わせワード" key="words">
                                            <ExpandRowWords word={record.word} />
                                        </TabPane>
                                    </Tabs>
                                </div>
                            )
                        }}
                    />
                </Card>
            </div>
        </div>
    );
};
